import { stimulus } from './application'
import AttachmentManagerController from './controllers/attachment-manager'
import AttachmentManagerItemController from './controllers/attachment-manager-item'
import AutosizeController from './controllers/autosize'
import BulkDetailsController from './controllers/bulk-details'
import BulkEditController from './controllers/bulk-edit'
import BulkEditResourceItemSubtopicsController from './controllers/bulk-edit-resource-item-subtopics'
import ClipboardController from './controllers/clipboard'
import ColorPaletteController from './controllers/color-palette'
import ColorPickerController from './controllers/color-picker'
import ComboBoxController from './controllers/combo-box'
import DispatchController from './controllers/dispatch'
import DropdownController from './controllers/dropdown'
import DropzoneController from './controllers/dropzone'
import FetchController from './controllers/fetch'
import FilterViewController from './controllers/filter-view'
import FocusTrapController from './controllers/focus-trap'
import FormChangeController from './controllers/form-change'
import FxController from './controllers/fx'
import IdentitySwitcherController from './controllers/identity-switcher'
import ImageFieldController from './controllers/image-field'
import PageNavOutlineController from './controllers/page-nav-outline'
import ProgressBarController from './controllers/progress-bar'
import ScrollbarController from './controllers/scrollbar'
import SnackBarController from './controllers/snack-bar'
import TabGroupController from './controllers/tab-group'
import TimestampController from './controllers/timestamp'
import ToggleController from './controllers/toggle'
import TooltipController from './controllers/tooltip'

stimulus.register('attachment-manager', AttachmentManagerController)
stimulus.register('attachment-manager-item', AttachmentManagerItemController)
stimulus.register('autosize', AutosizeController)
stimulus.register('bulk-details', BulkDetailsController)
stimulus.register('bulk-edit', BulkEditController)
stimulus.register('bulk-edit-resource-item-subtopics', BulkEditResourceItemSubtopicsController)
stimulus.register('clipboard', ClipboardController)
stimulus.register('color-palette', ColorPaletteController)
stimulus.register('color-picker', ColorPickerController)
stimulus.register('combo-box', ComboBoxController)
stimulus.register('dispatch', DispatchController)
stimulus.register('dropdown', DropdownController)
stimulus.register('dropzone', DropzoneController)
stimulus.register('fetch', FetchController)
stimulus.register('filter-view', FilterViewController)
stimulus.register('focus-trap', FocusTrapController)
stimulus.register('form-change', FormChangeController)
stimulus.register('fx', FxController)
stimulus.register('identity-switcher', IdentitySwitcherController)
stimulus.register('image-field', ImageFieldController)
stimulus.register('page-nav-outline', PageNavOutlineController)
stimulus.register('progress-bar', ProgressBarController)
stimulus.register('scrollbar', ScrollbarController)
stimulus.register('snack-bar', SnackBarController)
stimulus.register('tab-group', TabGroupController)
stimulus.register('timestamp', TimestampController)
stimulus.register('toggle', ToggleController)
stimulus.register('tooltip', TooltipController)
