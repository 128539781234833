'use strict'

import helpTransition from './transition-helper'
import util from './util'

class Modal {
  constructor () {
    this.$html = document.documentElement
    this.$modal = document.getElementById('modal')
    this.$overlay = document.getElementById('modal-overlay')
    this.$panel = document.getElementById('modal-panel')
    this.isOpen = false
    this.overlayAction = null
    this.overlayCanDismiss = true

    this.events = util.overlayEventsHandler()
  }

  init () {
    // Listen for elements that attempt to open or close the modal
    document.addEventListener('click', (event) => {
      if ('modalTriggerOpen' in event.target.dataset) {
        this.open()
        event.preventDefault()
      }

      if ('modalTriggerClose' in event.target.dataset) {
        event.preventDefault()

        if (
          event.target.dataset.modalTriggerClose === 'overlay' &&
          !this.overlayCanDismiss
        ) { return }

        this.overlayAction ? this.overlayAction() : this.close()
      }
    })

    this.$modal.addEventListener('scroll', (event) => {
      this.$modal.dispatchEvent(new CustomEvent('modal:scroll', {
        bubbles: true,
        detail: { originalEvent: event },
      }))
    })
  }

  open (content = null) {
    if (this.isOpen) return this.populate(content || this.__getLoadingMarkup())

    this.isOpen = true
    this.$modal.dispatchEvent(this.events.open)

    this.populate(content || this.__getLoadingMarkup())

    this.$modal.hidden = false
    this.$html.classList.add('modal-open')

    // TODO: convert `helpTransition` into a Promise,
    // then use `Promise.all()` here to dispatch the modal event
    helpTransition(this.$overlay, 'ModalOpen', 'show')
    helpTransition(this.$panel, 'ModalOpen', 'show')

    setTimeout(() => {
      this.$modal.dispatchEvent(this.events.opened)
    }, 300)
  }

  populate (content = null) {
    var forms = document.getElementsByClassName('styled-form')
    var modalPanelOverlay = document.getElementById(`modal-panel-overlay-${forms.length}`)

    if (modalPanelOverlay) {
      modalPanelOverlay.innerHTML = content
      this.$modal.dispatchEvent(this.events.populated)
    } else {
      this.$panel.innerHTML = content
      this.$modal.dispatchEvent(this.events.populated)
    }
  }

  overlayModal (event) {
    if (event.currentTarget.querySelectorAll('b')[1]) {
      var type = event.currentTarget.querySelectorAll('b')[1].innerHTML
    }

    var overlayPanel = this.buildOverlayPanel(type)

    overlayPanel.innerHTML = this.__getLoadingMarkup()
  }

  generateSizeArray (forms) {
    var sizeArray = []

    Array.from(forms).forEach(($form, $index) => {
      const increment = 4

      var newIncrement = increment * ($index + 1)

      sizeArray.push(newIncrement)
    })

    return sizeArray.reverse()
  }

  buildOverlayPanel (type) {
    // Get the existing elements on the page
    var forms = document.getElementsByClassName('styled-form')

    Array.from(forms).forEach(($form, $index, forms) => {
      // Add classes to existing elements
      if ($form.parentElement.children.length === 1) {
        var overlay = document.createElement('div')

        $form.parentElement.insertBefore(overlay, $form)
        overlay.classList.add(...this.formClasses())
      }

      var sizeArray = this.generateSizeArray(forms)
      var size = sizeArray[$index]

      $form.parentElement.classList.add(`-translate-x-${size}`)
      $form.parentElement.classList.add(`translate-y-${size}`)
    })

    // Create the necessary elements to generate the overlay modal
    var overlayContainer = document.createElement('div')
    var overlayParent = document.createElement('div')
    var overlayPanel = document.createElement('div')

    overlayContainer.appendChild(overlayParent)
    overlayParent.appendChild(overlayPanel)

    overlayContainer.setAttribute('id', `overlay-container-${forms.length}`)
    overlayPanel.setAttribute('id', `modal-panel-overlay-${forms.length}`)

    overlayContainer.classList.add(...this.overlayContainerClasses())
    overlayParent.classList.add(...this.overlayParentClasses())
    overlayPanel.classList.add(...this.overlayPanelClasses(type))

    document.body.appendChild(overlayContainer)

    return overlayPanel
  }

  close () {
    var forms = document.getElementsByClassName('styled-form')
    var overlayContainer = document.getElementById(`overlay-container-${forms.length - 1}`)

    if (overlayContainer) {
      overlayContainer.remove()

      // Remove the overlay modal from the dom and all its children
      forms[forms.length - 1].parentElement.children[0].remove()

      Array.from(forms).forEach(($form, $index, forms) => {
        var sizeArray = this.generateSizeArray(forms)
        var size = sizeArray[$index]

        if ($form.parentElement) {
          $form.parentElement.classList.remove(`-translate-x-${size}`)
          $form.parentElement.classList.remove(`translate-y-${size}`)
        }
      })
    } else {
      this.clearModalContents()
    }
  }

  clearModalContents () {
    // Clear out the modal contents
    this.populate(null)

    this.$modal.dispatchEvent(this.events.close)

    // TODO: convert `helpTransition` into a Promise,
    // then use `Promise.all()` here to dispatch the modal event
    helpTransition(this.$overlay, 'ModalClose', 'hide')
    helpTransition(this.$panel, 'ModalClose', 'hide')

    setTimeout(() => {
      this.$html.classList.remove('modal-open')
      this.$modal.hidden = true
      this.$modal.dispatchEvent(this.events.closed)
      this.setSize() // Set modal size to default (`md`)
      this.overlayAction = null // Remove overlay action
      this.setOverlayDismissable(true)
      this.isOpen = false
    }, 200)
  }

  setOverlayDismissable (state) {
    this.overlayCanDismiss = state

    this.$overlay.classList.toggle('cursor-pointer', state)
    this.$overlay.classList.toggle('pointer-events-none', !state)
  }

  setSize (size = 'md') {
    const cssTransitionSpeed = 200
    const sizes = {
      xxs: 'max-w-sm',
      xs: 'max-w-md',
      sm: 'max-w-lg',
      sm_md: 'max-w-xl',
      md: 'max-w-2xl',
      lg: 'max-w-4xl',
      xl: 'max-w-6xl',
      xxl: 'max-w-7xl',
      xxxl: 'max-w-8xl',
    }

    const promise = new Promise((resolve, reject) => {
      // Add the class for the target size, but also make sure
      // no other size classes are stored on the modal panel
      for (const [key, classes] of Object.entries(sizes)) {
        const action = size === key ? 'add' : 'remove'

        this.$panel.classList[action](classes)
      }

      // Resolve the promise after the css transition is over
      setTimeout(() => { resolve(size) }, cssTransitionSpeed)
    })

    return promise
  }

  __getLoadingMarkup () {
    return `
      <div class="h-24 md:h-32 lg:h-48 flex items-center justify-center">
        <svg
          width="64px" height="64px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <circle
            class="stroke-current text-whitelabel-500"
            cx="50" cy="50" r="32" fill="none"
            stroke-dasharray="150 50" stroke-width="2"
          >
            <animateTransform
              attributeName="transform" type="rotate" repeatCount="indefinite"
              dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" />
          </circle>
        </svg>
      </div>
    `
  }

  __transition (type, elem, startClasses, duringClasses, endClasses, duration) {
    elem.classList.add(...startClasses.split(' '))

    // We use `requestAnimationFrame` here to guarantee that our rapid series
    // of manipulations to the element's `classList` happen at least one frame
    // apart. Otherwise, we can't guarantee that they'll happen in the right
    // order, which results in our transition class effects breaking.
    requestAnimationFrame(() => {
      elem.classList.add(...duringClasses.split(' '))

      requestAnimationFrame(() => {
        if (type === 'open') elem.classList.remove('hidden')

        requestAnimationFrame(function (timestamp) {
          elem.classList.remove(...startClasses.split(' '))
          elem.classList.add(...endClasses.split(' '))
        })
      })
    })

    window.setTimeout(() => {
      elem.classList.remove(...duringClasses.split(' '))
      elem.classList.remove(...endClasses.split(' '))

      if (type === 'close') elem.classList.add('hidden')
    }, duration)
  }

  formClasses () {
    return [
      'h-full',
      'w-full',
      'absolute',
      'z-50',
      'bg-gray-500',
      'opacity-70',
      'sibling-container',
    ]
  }

  overlayContainerClasses () {
    return [
      'fixed',
      'z-50',
      'inset-0',
      'overflow-y-auto',
    ]
  }

  overlayParentClasses () {
    return [
      'flex',
      'items-end',
      'justify-center',
      'min-h-screen',
      'pt-sm',
      'px-sm',
      'pb-2xl',
      'text-center',
      'sm:block',
      'sm:pb-sm',
    ]
  }

  overlayPanelClasses (type) {
    var classes = [
      'align-bottom',
      'bg-white',
      'text-left',
      'inline-block',
      'rounded-lg',
      'transform',
      'transition-all',
      'overflow-hidden',
      'w-full',
      'sm:my-8',
      'sm:align-middle',
      'shadow',
    ]

    if (type === 'New Business') {
      classes.push('max-w-8xl')
    } else {
      classes.push('max-w-4xl')
    }

    return classes
  }
}

export default Modal
