class CategorySelector {
  constructor () {
    this.container = document.querySelector('.form-type-container')
  }

  init (event = null) {
    if (event) {
      if (event.currentTarget.title !== 'Filter by Category') { return }

      this.checkCompliance()
    }

    if (!this.container) { return }

    this.container.addEventListener('change', () => {
      var checkboxes = this.container.querySelectorAll('input[type="checkbox"]:checked')
      var subtopicContainer = document.querySelector('.category-container')
      var checkedValues = []
      Array.from(checkboxes).forEach($box => {
        checkedValues.push($box.value)
      })

      if (checkedValues.includes('ComplianceSubmission')) {
        subtopicContainer.classList.remove('hidden')
      } else {
        subtopicContainer.querySelector('select').value = ''
        subtopicContainer.classList.add('hidden')
      }
    })
  }

  checkCompliance () {
    var checkboxes = this.container.querySelectorAll('input[type="checkbox"]:checked')
    var subtopicContainer = document.querySelector('.category-container')
    var checkedValues = []
    Array.from(checkboxes).forEach($box => {
      checkedValues.push($box.value)
    })

    if (checkedValues.includes('ComplianceSubmission')) {
      subtopicContainer.classList.remove('hidden')
    }
  }
}

export default CategorySelector
