import axios from 'axios'
import util from './util'
import 'trix'
import EditContent from './edit-content'

class FinfolioNotes {
  constructor () {
    this.newNoteContainer = document.querySelector('#finfolio-notes')
    this.textArea = document.querySelector('#ff-text')
    this.submitButton = document.querySelector('#submit-button')
    this.cancelButton = document.querySelector('#cancel-button')
    this.container = document.querySelector('#ff-appended-notes')
    this.notesTemplate = document.querySelector('#finfolio-notes-template')
    this.selectDropdown = this.newNoteContainer.querySelector('.ff-select')
    this.entityId = document.querySelector('#entity_id')
    this.statusMessage = document.querySelector('#api-status-message')
    this.editButtons = Array.from(document.querySelectorAll('.edit-button'))
    this.messages = Array.from(document.querySelectorAll('#finfolio-notes-template'))
    this.note = document.querySelector('.ff-notes')
    this.num = 1
  }

  init () {
    this.cancelButton.addEventListener('click', this.clearTextArea.bind(this))
    this.textArea.addEventListener('keydown', this.handleTextareaInput.bind(this))
    this.selectDropdown.addEventListener('change', this.updateDropdownStyling.bind(this))
    this.closeErrorMessage()
  }

  handleTextareaInput (event) {
    // "Assertion failed" console error occurs when you press enter in the text area
    // Stop propagation on the event to eliminate this console error
    event.stopPropagation()

    const key = event.key

    if (key !== 'Backspace') {
      this.num++
    }

    if (key === 'Backspace') {
      if (this.num > 1) {
        this.num--
      }
    }

    if (this.textArea.value.length === 0 && event.which === 32) {
      event.preventDefault()
      this.num--
    } else if (this.textArea.value.length === 0 && event.which === 9) {
      this.num--
    } else if (this.num === 1) {
      this.buttonGroupHandler()
    } else {
      this.buttonGroupHandler('remove', 'add', 'remove')
    }
  }

  handleSuccess (response) {
    if (this.selectDropdown.value === '') {
      this.selectDropdown.style.borderColor = 'rgba(254, 83, 83, 1)'

      return
    }

    if (this.textArea.value === '') { return }

    const node = this.notesTemplate.cloneNode(true)

    this.populateClone(node, this.formatDate(), response.data.id)
    this.clearTextArea()
    if (this.statusMessage) { this.statusMessage.classList.add('hidden') }
    this.textArea.style.borderColor = 'rgb(218, 216, 216)'

    new EditContent().init()
  }

  handleError () {
    this.textArea.style.borderColor = 'red'

    if (!this.statusMessage) { return }

    this.statusMessage.classList.remove('hidden')
  }

  populateClone (node, date, id) {
    const para = document.createElement('p')
    para.id = 'note-id'
    para.classList.add('hidden')
    para.innerHTML = id

    const container = node.querySelector('#edit-buttons-container')
    const typeText = node.querySelector('.ff-type')
    const noteText = node.querySelector('.ff-notes')
    const dateText = node.querySelector('.ff-date')
    const selectOptions = node.querySelector('#ff-notes-select')
    const selectedOption = this.selectDropdown.selectedOptions[0].text

    container.appendChild(para)

    node.classList.remove('hidden')
    node.classList.add('list-item')

    dateText.innerHTML = date
    noteText.innerHTML = this.textArea.value
    typeText.innerHTML = selectedOption.toUpperCase()
    selectOptions.value = this.selectDropdown.value

    this.container.appendChild(node)
  }

  clearTextArea () {
    this.num = 1

    this.textArea.value = ''
    this.selectDropdown.value = ''

    this.buttonGroupHandler()
    this.updateDropdownStyling()
  }

  buttonGroupHandler (val1 = 'add', val2 = 'remove', val3 = 'add') {
    // style submit button
    this.submitButton.classList[val1]('note-button')
    this.submitButton.classList[val2]('note-button-update')

    // style close button
    this.cancelButton.classList[val3]('hidden')
  }

  updateDropdownStyling () {
    this.selectDropdown.style.borderColor = 'rgba(218, 216, 216)'
  }

  formatDate () {
    const date = new Date()
    const isoDate = date.toISOString()

    return window.app.util.formatDateFull(isoDate)
  }

  submitNote () {
    const description = this.textArea.value
    const categoryId = this.selectDropdown.value
    const entityId = this.entityId.value

    if (description === '') return
    if (categoryId === '') {
      this.selectDropdown.style.borderColor = 'rgba(254, 83, 83, 1)'

      return
    }

    util.setCSRFToken()

    axios.post('/admin/notes', {
      note: {
        description: description,
        categoryId: categoryId,
        entityId: entityId,
      },
    }).then((response) => { this.handleSuccess(response) })
      .catch(() => { this.handleError() })
  }

  closeErrorMessage () {
    const closeButton = this.statusMessage?.querySelector('.alert-close')

    if (!closeButton) return

    closeButton.addEventListener('click', () => {
      this.statusMessage.classList.add('hidden')
    })
  }
}

export default FinfolioNotes
