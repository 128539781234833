import util from './util'
/** * Record Stream Management - A class to assist with javascript sprinkles
 * 1) Responsible for updating the record header in the record stream
 * For example, when a record is created or updated, the record header is updated with the supervised person name
 *
 * 2) Responsible for keeping track of the specific changes to the record stream
 * For example, when a record is created or updated, the record stream will keep track of the specific changes
*/
class RecordStreamManagement {
  stream (render, streamElement, fragment, event) {
    // Update the fragment header
    // this.updateRecordHeader(fragment)

    this.trackChanges(fragment)

    render(streamElement)

    event.preventDefault()
  }

  updateRecordHeader (fragment) {
    const recordID = util.findUUIDs(fragment.id)[0]

    // Locate the supervised person name from the document
    const supervisedPersonName = this.locateSupervisedPersonName(recordID)

    // Set the supervised person name in the the record header fragment
    this.setSupervisedPersonName(supervisedPersonName, fragment, recordID)
  }

  locateSupervisedPersonName (recordID) {
    const record = document.querySelector(`#supervised-person-name-${recordID}`)

    if (!record) { return }

    const supervisedPersonName = record.dataset.supervisedPersonName

    return supervisedPersonName
  }

  setSupervisedPersonName (supervisedPersonName, fragment, recordID) {
    const record = fragment.querySelector(`#supervised-person-name-${recordID}`)

    if (record) {
      // Set inner text
      record.innerText = supervisedPersonName

      // Set data-supervised-person-name attribute
      record.dataset.supervisedPersonName = supervisedPersonName
    }
  }

  trackChanges (fragment) {
    const changedElements = Array.from(fragment.querySelectorAll('.broadcasted-field'))

    changedElements.forEach((element) => {
      const record = element.closest('.new-business-record')

      record.classList.add('animate-record')
    })
  }
}

export default RecordStreamManagement
