/** * Turbo Management - A class to assist with javascript sprinkles
 * This class will keep of turbo callbacks and javascript updates for the turbo streams
 */
class TurboManagement {
  init () {
    this.setBeforeTurboStreamCallback()
    this.setSubmitEndCallback()
  }

  setSubmitEndCallback () {
    document.addEventListener('turbo:submit-end', (event) => {
      if (event.detail.success) {
        window.app.drawer.close()

        setTimeout(() => {
          if (window.app.snackBar) {
            window.app.snackBar.removeAll()
          }
        }, 100)
      }
    })
  }

  setBeforeTurboStreamCallback () {
    document.addEventListener('turbo:before-stream-render', (event) => {
      const render = event.detail.render

      if (event.target.action === 'remove') {
        return window.app.removeStreamManagement.stream(event)
      }

      event.detail.render = function (streamElement) {
        const fragment = event.detail.newStream.children[0].content.children[0]
        const streamName = fragment.attributes['data-stream-name']?.value

        if (streamName) {
          switch (streamName) {
            case 'submission-fields':
              return render(streamElement)
            case 'activity':
              return window.app.activityStreamManagement.stream(render, streamElement, fragment)
            case 'attachment':
              return window.app.attachmentStreamManagement.stream(render, streamElement)
            case 'record':
              return window.app.recordStreamManagement.stream(render, streamElement, fragment, event)
            case 'priority':
              return window.app.priorityStreamManagement.stream(render, streamElement, fragment)
            case 'submissions-table':
              return window.app.submissionsTableStreamManagement.stream(
                render,
                streamElement,
                fragment,
                event,
              )
            default:
              render(streamElement)
          }
        }

        render(streamElement)

        if (fragment.dataset.streamName === 'delete-dialog') {
          window.app.deleteDialogManagement.stream()
        }
      }
    })
  }
}

export default TurboManagement
