class StickyTableHeader {
  constructor () {
    this.selectors = {
      root: '[data-page-content]',
    }

    this.tableHeader = document.querySelector('#tableHeader')
    this.filterBar = document.querySelector('#filterBar')
    this.navBar = document.querySelector('#nav-bar')
  }

  init () {
    this.$root = document.querySelector(this.selectors.root)

    this.$root.addEventListener('scroll', this.calcHeaderOffset.bind(this))
  }

  calcHeaderOffset (e) {
    const { scrollTop } = e.target
    const noFilterOffset = this.navBar.offsetHeight - this.tableHeader.offsetHeight

    if (scrollTop >= noFilterOffset) {
      this.beyondThreshold()
    } else {
      this.beforeThreshold()
    }
  }

  beyondThreshold () {
    this.tableHeader.classList.add('sticky-header')

    if (this.filterBar) {
      this.tableHeader.style.top = this.convertToRem(this.filterBar.offsetHeight)
      this.filterBar.classList.remove('rounded-t-lg')
    } else {
      // set offset to -1px to account for a small gap between the table header and navbar on bigger screen sizes
      this.tableHeader.style.top = '-1px'
    }
  }

  beforeThreshold () {
    this.tableHeader.classList.remove('sticky-header')

    if (this.filterBar) { this.filterBar.classList.add('rounded-t-lg') }
  }

  convertToRem (value) {
    // Select the HTML base font and convert to a number
    const baseFont = parseInt(window.getComputedStyle(document.querySelector('html')).fontSize)
    const toRem = value / baseFont

    return `${toRem}rem`
  }
}

export default StickyTableHeader
