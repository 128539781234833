import { Controller } from '@hotwired/stimulus'
import { getTarget } from '../utils'

interface EventWithParams extends Event {
  params: EffectTiming & {
    removeWhenDone?: boolean
  }
}

const DURATION = 200
const REMOVE_WHEN_DONE = false

export default class FxController extends Controller {
  fadeOut(event: EventWithParams) {
    const target = getTarget(event)
    const duration = this.#getDuration(event)
    const removeWhenDone = this.#getRemoveWhenDone(event)

    target
      .animate([{ opacity: 1 }, { opacity: 0 }], { duration })
      .finished
      .then(() => {
        if (removeWhenDone) target.remove()
        this.dispatch('fx:fade-out', { target })
      })
  }

  // Private methods

  #getDuration(event: EventWithParams) {
    return event.params.duration || DURATION
  }

  #getRemoveWhenDone(event: EventWithParams) {
    return event.params.removeWhenDone || REMOVE_WHEN_DONE
  }
}
