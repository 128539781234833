import Cleave from 'cleave.js'

class NumberInput {
  constructor ($elem) {
    this.$elem = $elem

    this.cleaveOptions = {
      numeral: true,
      numeralDecimalScale: 3,
      numeralPositiveOnly: true,
      onValueChanged: this.handleValueChange.bind(this),
    }
  }

  handleValueChange (event) {
    this.$elem.previousElementSibling.value = event.target.rawValue

    this.$elem.dispatchEvent(new CustomEvent('number-input:changed', {
      bubbles: true,
      detail: event.target,
    }))
  }

  init () {
    const cleave = new Cleave(this.$elem, this.cleaveOptions)

    this.$elem.cleave = cleave
  }
}

export default NumberInput
