import flatpickr from 'flatpickr'

// Tell flatpickr to use shorter weekday initials
const weekdayInitials = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
flatpickr.l10ns.default.weekdays.shorthand = weekdayInitials

class Datepicker {
  constructor ($elem) {
    this.$elem = $elem
    this.defaults = {
      dateFormat: 'Y-m-d',
      nextArrow: `
        <svg width="14" height="14" viewBox="0 0 14 14" class="icon fill-current">
          <path d="M4.18 3.109L7.973 7 4.18 10.891a1 1 0 000 1.397l.007.007a.962.962 0 001.377 0L10.735 7l-5.17-5.295a.962.962 0 00-1.377 0l-.007.007a1 1 0 000 1.397z"/>
        </svg>
      `,
      prevArrow: `
        <svg width="14" height="14" viewBox="0 0 14 14" class="icon fill-current">
          <path d="M9.054 10.891L5.263 7l3.791-3.891a1 1 0 000-1.397l-.008-.007a.962.962 0 00-1.376 0L2.5 7l5.17 5.295a.962.962 0 001.376 0l.007-.007a1 1 0 00.001-1.397z"/>
        </svg>
      `,
    }

    this.parseConfig()
  }

  handleValueUpdate (selectedDates, dateStr, instance) {
    this.$elem.dispatchEvent(new CustomEvent('datepicker:update', {
      detail: { selectedDates, dateStr, instance },
    }))
  }

  init () {
    const { $elem } = this

    this.options = {
      ...this.defaults,
      onValueUpdate: this.handleValueUpdate.bind(this),
      ...this.config,
    }

    this.options.defaultDate = this.getDefaultDate()

    $elem.datepicker = flatpickr($elem, this.options)

    $elem.dispatchEvent(new CustomEvent('datepicker:init', {
      bubbles: true,
      detail: { $elem },
    }))
  }

  getDefaultDate () {
    let date = null

    if ('mode' in this.options && this.options.mode === 'range') {
      const arr = this.$elem.value.split(' to ')
      date = arr.length === 1 ? arr[0] : arr
    } else {
      date = this.$elem.value || null
    }

    return date
  }

  parseConfig() {
    let config = JSON.parse(this.$elem.dataset.datepickerConfig || '{}')

    // These config values expect a DOM element, but we can't pass those into
    // the config data attribute. Instead, we'll look for CSS selctors in these
    // values and then traverse up the DOM tree from the class `$elem` until we
    // find the element we're looking for.
    const elementValues = ['appendTo', 'positionElement']

    elementValues.forEach((key) => {
      if (key in config) config[key] = this.$elem.closest(config[key])
    })

    // These config values expect an integer, but they are passed as strings
    // through data attributes. We'll convert them to integers here.
    const integerValues = [
      'defaultHour',
      'defaultMinute',
      'hourIncrement',
      'minuteIncrement',
      'showMonths',
    ]

    integerValues.forEach((key) => {
      if (key in config) config[key] = parseInt(config[key], 10)
    })

    this.config = config
  }
}

export default Datepicker
