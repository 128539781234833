class StaticElements {
  submissionMarkup (option) {
    if (option.viewable) {
      return `
        <div class="flex justify-between">
          <div
            class="w-94-percent related-submission"
            id="related-submission-${option.id}"
            x-data="{}"
            x-on:click.prevent="
              app.drawer.open()
            "
          >
            <a id="${option.id}" href="/submission_containers/${option.container_id}" data-remote="true" class="submission-selection-container flex items-center justify-between mt-2 p-2 removal-container type-body-2 border-t-1 border-r-1 border-l-1 border-gray-200 hover:bg-gray-50 rounded-t rounded-b border-b-1">
              <span id="${option.id}-viewable" class="sr-only">${option.viewable}</span>
              <span id="${option.id}-container-id" class="sr-only">${option.container_id}</span>
              <span id="${option.id}-submission-id" class="sr-only">${option.submission_id}</span>
              <div class="flex">
                <div class="flex items-center justify-center h-9 w-9 rounded-full bg-white text-gray-200 submission-svg-background">
                  <div class="text-whitelabel-400">
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25802 15.751C1.01594 15.7555 0.00562535 14.753 0 13.5121V7.87549C0 7.75511 0.0191262 7.63473 0.0562535 7.51997L2.13088 1.29833C2.40765 0.528783 3.1322 0.0112507 3.949 0H14.0521C14.8701 0.0112507 15.5935 0.528783 15.8702 1.29946L17.9437 7.5211C17.9809 7.6336 18 7.75511 18 7.87549V13.5121C17.9944 14.753 16.9841 15.7555 15.742 15.751H2.25802ZM15.7431 13.5008C15.751 13.5008 15.751 9.00056 15.751 9.00056H12.8899C12.0889 10.3923 10.606 11.2507 9.00056 11.2507C7.39509 11.2507 5.91224 10.3923 5.11119 9.00056H2.25014V13.5121C2.25014 13.5008 15.7431 13.5008 15.7431 13.5008ZM13.8159 2.25014H4.18526L2.68667 6.75042H5.81661C5.95387 6.75267 6.08663 6.77967 6.21376 6.82918C6.25202 6.84605 6.29027 6.86518 6.32627 6.88543C6.40728 6.92706 6.48378 6.97881 6.55128 7.03844C6.58504 7.06657 6.61879 7.09807 6.64692 7.1307C6.70879 7.20157 6.76167 7.27921 6.80443 7.36359C6.82468 7.39734 6.84268 7.43109 6.86068 7.46709C6.86068 7.47834 6.87418 7.48959 6.87868 7.50084C7.19595 8.4009 8.0465 9.00056 9.00056 9.00056C9.95462 9.00056 10.8052 8.4009 11.1224 7.50084C11.1224 7.48959 11.1359 7.47834 11.1416 7.46709C11.1584 7.43334 11.1753 7.39734 11.1978 7.36359C11.2395 7.27921 11.2923 7.20157 11.3553 7.1307C11.3835 7.09694 11.4161 7.06657 11.4498 7.03844C11.5173 6.97881 11.5938 6.92593 11.6749 6.88656L11.7874 6.8303C11.9134 6.7808 12.0461 6.7538 12.1834 6.75155H15.3145L13.8159 2.25014Z" fill="currentColor"/>
                    </svg>
                  </div>
                </div>
                <div class="ml-2">
                  <div>
                    <span id="${option.id}-description">${option.description}</span> by <span id="${option.id}-user-name" class="text-xs text-gray-400 italic">${option.user_name || option.userName}</span>
                  </div>
                  <div>
                    <p class="text-xs">Submitted <b class="text-whitelabel-400"><span id="${option.id}-created-at">${option.created_at}</span></b> in <b id="${option.id}-category" class="text-whitelabel-400">${option.category}</b></p>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div 
            class="text-gray-300 hover:text-gray-400 cursor-pointer flex mt-2 removal-container w-10 border-t-1 border-r-1 border-l-1 border-gray-200 hover:bg-gray-50 border-b-1 items-center rounded"
            x-on:click="
              new window.app.generateField().removeSubmission($event)
            "
          >
            <div class="w-full" name=${option.id}>
              <svg width="16" height="16" class="m-auto" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z" stroke="currentColor" stroke-width="1.25" stroke-miterlimit="10"/>
                <path d="M4.19971 8H11.7997" stroke="currentColor" stroke-width="1.25" stroke-miterlimit="10"/>
              </svg>
            </div>
          </div>
        </div>
      `
    } else {
      return `
        <div 
          class="related-submission flex items-center justify-between mt-2 p-2 removal-container type-body-2 border-t-1 border-r-1 border-l-1 border-gray-200 hover:bg-gray-50 rounded-t rounded-b border-b-1"
          id="related-submission-${option.id}"
        >
          <div class="flex">
            <div class="flex items-center justify-center h-9 w-9 rounded-full bg-white text-gray-200 submission-svg-background">
              <div class="text-whitelabel-400">
                <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25802 15.751C1.01594 15.7555 0.00562535 14.753 0 13.5121V7.87549C0 7.75511 0.0191262 7.63473 0.0562535 7.51997L2.13088 1.29833C2.40765 0.528783 3.1322 0.0112507 3.949 0H14.0521C14.8701 0.0112507 15.5935 0.528783 15.8702 1.29946L17.9437 7.5211C17.9809 7.6336 18 7.75511 18 7.87549V13.5121C17.9944 14.753 16.9841 15.7555 15.742 15.751H2.25802ZM15.7431 13.5008C15.751 13.5008 15.751 9.00056 15.751 9.00056H12.8899C12.0889 10.3923 10.606 11.2507 9.00056 11.2507C7.39509 11.2507 5.91224 10.3923 5.11119 9.00056H2.25014V13.5121C2.25014 13.5008 15.7431 13.5008 15.7431 13.5008ZM13.8159 2.25014H4.18526L2.68667 6.75042H5.81661C5.95387 6.75267 6.08663 6.77967 6.21376 6.82918C6.25202 6.84605 6.29027 6.86518 6.32627 6.88543C6.40728 6.92706 6.48378 6.97881 6.55128 7.03844C6.58504 7.06657 6.61879 7.09807 6.64692 7.1307C6.70879 7.20157 6.76167 7.27921 6.80443 7.36359C6.82468 7.39734 6.84268 7.43109 6.86068 7.46709C6.86068 7.47834 6.87418 7.48959 6.87868 7.50084C7.19595 8.4009 8.0465 9.00056 9.00056 9.00056C9.95462 9.00056 10.8052 8.4009 11.1224 7.50084C11.1224 7.48959 11.1359 7.47834 11.1416 7.46709C11.1584 7.43334 11.1753 7.39734 11.1978 7.36359C11.2395 7.27921 11.2923 7.20157 11.3553 7.1307C11.3835 7.09694 11.4161 7.06657 11.4498 7.03844C11.5173 6.97881 11.5938 6.92593 11.6749 6.88656L11.7874 6.8303C11.9134 6.7808 12.0461 6.7538 12.1834 6.75155H15.3145L13.8159 2.25014Z" fill="currentColor"/>
                </svg>
              </div>
            </div>
            <div class="ml-2">
              <div>
                <i>You are not authorized to view this submission</i> by <span id="${option.id}-user-name" class="text-xs text-gray-400 italic">${option.user_name || option.userName}</span>
              </div>
              <div>
                <p class="text-xs">Submitted <b class="text-whitelabel-400"><span id="${option.id}-created-at">${option.created_at}</span></b> in <b id="${option.id}-category" class="text-whitelabel-400">${option.category}</b></p>
              </div>
            </div>
          </div>
        </div>
      `
    }
  }

  toUrl (option) {
    return option.form_type + 's'
  }
}

export default StaticElements
