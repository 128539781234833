import { Controller } from '@hotwired/stimulus'
import Pickr from '@simonwep/pickr'
import '@simonwep/pickr/dist/themes/monolith.min.css'

export default class ColorPickerController extends Controller<HTMLInputElement> {
  declare pickr: Pickr

  connect() {
    this.pickr = Pickr.create(this.#getOptions())
      .on('init', (pickr: Pickr) => {
        this.#updateInput(pickr.getSelectedColor())
      })

      .on('save', (color: Pickr.HSVaColor) => {
        this.#updateInput(color)
        this.pickr.hide()
      })
  }

  disconnect() {
    this.pickr.destroy()
  }

  // Private methods

  /** @link https://github.com/Simonwep/pickr#options */
  #getOptions() {
    return {
      closeOnScroll: true,
      components: {
        hue: true,
        interaction: { input: true, save: true },
        preview: true,
      },
      default: this.element.value || '#888888',
      el: this.element,
      position: 'top-end',
      theme: 'monolith',
      useAsButton: true,
    } satisfies Pickr.Options
  }

  #updateInput(color: Pickr.HSVaColor) {
    if (color === null) return

    this.element.value = color.toHEXA().toString()
    this.element.dispatchEvent(new Event('input'))
  }
}