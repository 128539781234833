class Notes {
  constructor () {
    this.$notes = document.querySelector('[data-target="notes"]').children
    this.$notesContainer = document.querySelector('.notes-container')
  }

  append () {
    this.appendNotes()
  }

  appendNotes () {
    if (this.$notesContainer) {
      Array.from(this.$notes).forEach($note => {
        this.$notesContainer.appendChild($note)
      })
    }
  }

  notesHandler (id, val) {
    this.$notesContainer.classList[val]('hidden')
    var container = document.querySelector(`#${id}`)

    container.children[0].classList[val]('hidden')
  }

  displayApplicableNotes () {
    var activeNames = this.collectActiveItems()

    this.applicableNotes(activeNames)
  }

  displayApplicableModelChangeNotesInit (event) {
    var container = document.querySelector('.model-change-notes')

    container.classList.toggle('hidden', event !== 'attachment')
  }

  displayApplicableModelChangeNotes (event) {
    const container = document.querySelector('.model-change-notes')
    let text = ''

    if (event.target.nodeName === 'LABEL') {
      text = event.target.children[1].innerHTML
    } else {
      text = event.target.innerHTML
    }

    if (text !== '') {
      container.classList.toggle('hidden', text !== 'Attach Client Suitability')
    }
  }

  collectActiveItems () {
    var container = document.querySelector('.check-box-container')
    var checkBoxes = container.querySelectorAll('input[type=checkbox]')
    var activeCheckBoxes = Array.from(checkBoxes).filter($box => $box.checked)
    var activeNames = activeCheckBoxes.map($box => $box.dataset.portfolioName)

    return activeNames
  }

  applicableNotes (activeNames) {
    var notes = document.querySelectorAll('[data-associations]')
    var intersectionCount = 0

    Array.from(notes).forEach($note => {
      // Remove hidden if any of the associations are checked
      var associations = $note.dataset.associations.split(', ')

      var intersection = associations.filter(association => activeNames.includes(association))

      intersectionCount += intersection.length > 0 ? 1 : 0
      $note.classList.toggle('hidden', intersection.length === 0)

      return intersectionCount
    })

    if (intersectionCount > 0) {
      this.$notesContainer.classList.remove('hidden')
    }
  }
}

export default Notes
