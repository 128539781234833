class IframeLoader {
  constructor () {
    this.modalSize = 'xxl'
  }

  populate (event, showInModal, resourceTitle, resourceType, resourceUpdatedAt) {
    if (!showInModal) { return }
    event.preventDefault()

    const src = event.currentTarget.href

    window.app.modal.setSize(this.modalSize).then(() => {
      window.app.modal.open(this.getMarkup(src, resourceTitle, resourceType, resourceUpdatedAt))
    })
  }

  getMarkup (src, resourceTitle, resourceType, resourceUpdatedAt) {
    return `
      <div>
        <iframe
          class="bg-gray-800 xl:w-full w-full h-400 lg:h-716 lg:w-1280"
          src="${src}"
          allow="autoplay; fullscreen"
          allowfullscreen
        >
        </iframe>
        <p class="bg-gray-400 text-white type-label text-sm pl-lg pt-1 pb-1 h-7">${resourceType}</p>
        <div class="border-t border-gray-100">
          <div class="flex flex-row justify-between p-md sm:px-lg space-x-reverse space-x-md">
            <div>
              <p class="text-xl pb-1">${resourceTitle}</p>
              <p class="bg-white text-gray-400 type-caption text-xs">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" class="icon -mt-px">
                  <path d="M7 14A7 7 0 117 0a7 7 0 010 14zM7 1.75a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5zm3.5 6.125H7a.874.874 0 01-.875-.866V3.49c0-.481.392-.875.875-.875s.875.394.875.875v2.634H10.5a.875.875 0 010 1.75z" fill-rule="evenodd"></path>
                </svg>
                Updated on ${resourceUpdatedAt}
              </p>
            </div>
            <button class="button button-alternate" data-modal-trigger-close="button" type="button">Close</button>
          </div>
        </div>
      </div>
    `
  }
}

export default IframeLoader
