import { Controller } from '@hotwired/stimulus'
import 'overlayscrollbars/overlayscrollbars.css'
import {
  ClickScrollPlugin,
  OverlayScrollbars,
  SizeObserverPlugin,
} from 'overlayscrollbars'

interface Scrollable extends HTMLElement {
  dataset: {
    scrollbarInitOn?: string
    scrollbarDestroyOn?: string
  }
}

OverlayScrollbars.plugin([SizeObserverPlugin, ClickScrollPlugin])

export default class ScrollbarController extends Controller<Scrollable> {
  declare scrollbar: OverlayScrollbars | undefined

  connect() {
    if (!this.#isWindows) return

    const { scrollbarInitOn, scrollbarDestroyOn } = this.element.dataset

    if (scrollbarInitOn) {
      this.element.addEventListener(scrollbarInitOn, this.#init.bind(this))
    } else {
      this.#init()
    }

    if (scrollbarDestroyOn) {
      this.element.addEventListener(scrollbarDestroyOn, this.#destroy.bind(this))
    }
  }

  disconnect() {
    const { scrollbarInitOn, scrollbarDestroyOn } = this.element.dataset

    if (scrollbarInitOn) {
      this.element.removeEventListener(scrollbarInitOn, this.#init.bind(this))
    }

    if (scrollbarDestroyOn) {
      this.element.removeEventListener(scrollbarDestroyOn, this.#destroy.bind(this))
    }

    this.#destroy()
  }

  #destroy() {
    this.scrollbar?.destroy()
  }

  #init() {
    this.scrollbar = OverlayScrollbars(this.element, {
      scrollbars: {
        autoHide: 'leave',
        autoHideSuspend: true,
        clickScroll: true,
      }
    })
  }

  #isWindows = navigator.userAgent.includes('Win')
}