/** * Remove Stream Management - A class to assist with javascript sprinkles
*/
class RemoveStreamManagement {
  stream (event) {
    event.preventDefault()

    const target = document.getElementById(event.target.target)

    if (target?.dataset?.streamName === 'attachment') {
      this.manageClasses(target)
      this.managerAttachmentUrl(target)
    }

    this.removeRow(target)
  }

  blankStatePositioning (target) {
    if (target?.dataset?.streamName === 'submissions-table') { return }

    const attachmentsBin = document.querySelector('.attachments-bin')
    const attachments = Array.from(document.querySelectorAll('.file-container'))
    const classes = ['h-full', 'flex', 'items-center', 'justify-center']

    if (!attachmentsBin) return

    if (attachments.length < 2) {
      attachmentsBin.classList.add(...classes)
    } else {
      attachmentsBin.classList.remove(...classes)
    }
  }

  manageClasses (target) {
    target.classList.add('remove-attachment')
    target.classList.remove('animate-row')
    target.classList.remove('animate-new-attachment')

    target.addEventListener('animationend', () => { this.blankStatePositioning(target) })
  }

  removeRow (target) {
    if (!target) { return }

    target.remove()

    const containers = document.querySelectorAll('.attachments-container')
    const attachmentsBlankState = document.querySelector('.attachments-empty-state')

    containers.forEach((container) => {
      const attachments = Array.from(container.querySelectorAll('.file-container'))
      const actionBar = container.parentElement.querySelector('#action-bar')

      if (attachments.length > 0) {
        container.classList.remove('hidden')
        actionBar.classList.remove('hidden')
      } else {
        container.classList.add('hidden')
        actionBar.classList.add('hidden')
        attachmentsBlankState.classList.remove('hidden')
      }
    })
  }

  managerAttachmentUrl (target) {
    if (target?.dataset?.streamName === 'submissions-table') { return }

    const attachmentContainer = document.querySelector('.multi-select-attachment-container')

    new window.app.multiAttachment(attachmentContainer).urlManager('remove', target.id.replace('attachment-', ''), target)
  }
}

export default RemoveStreamManagement
