import axios from 'axios'
import util from './util'

class EditContent {
  constructor () {
    this.messages = Array.from(document.querySelectorAll('#finfolio-notes-template'))
    this.notesParagraphs = Array.from(document.querySelectorAll('.ff-notes'))
    this.editMode = false
  }

  init () {
    this.findEditIconOnHover()
    this.handleParagraphClick()

    if (this.editMode) return this.showTypeEdit()
  }

  findEditIconOnHover () {
    let icon

    this.messages.forEach((message) => {
      message.addEventListener('mouseenter', (e) => {
        const template = e.target.closest('#finfolio-notes-template')
        icon = template.querySelector('.edit-button')

        this.editIconHandler(icon, template)
      })
    })
  }

  editIconHandler (icon, template) {
    icon.addEventListener('click', (e) => {
      const notes = template.querySelector('.ff-notes')
      const editActions = template.querySelector('#edit-actions')
      const typeContainer = template.querySelector('.ff-type-container')
      const range = document.createRange()
      const sel = window.getSelection()

      this.showTypeEdit(typeContainer, true)

      editActions.classList.remove('hidden')
      icon.classList.add('hidden')

      range.setStart(notes.childNodes[0], 0)
      range.collapse(true)

      sel.removeAllRanges()
      sel.addRange(range)
    })
  }

  showTypeEdit (el, value) {
    const staticType = el.querySelector('.ff-type')
    const selectBox = el.querySelector('.ff-select')

    if (value) {
      staticType.classList.add('hidden')
      selectBox.classList.remove('hidden')
      selectBox.classList.add('ff-type-edit')
      this.typeEditHandler(selectBox, staticType)
    } else {
      staticType.classList.remove('hidden')
      selectBox.classList.add('hidden')
    }
  }

  typeEditHandler (selectBox, staticType) {
    selectBox.addEventListener('change', (e) => {
      const selectedOption = e.target.options[e.target.selectedIndex].innerHTML.toUpperCase()

      staticType.innerHTML = selectedOption
    })
  }

  handleParagraphClick () {
    this.notesParagraphs.forEach((paragraph) => {
      paragraph.addEventListener('click', (e) => {
        const template = e.target.closest('#finfolio-notes-template')
        const typeContainer = template.querySelector('.ff-type-container')

        this.showTypeEdit(typeContainer, true)
        this.hideEditActions(template, 'remove', 'add')
      })
    })
  }

  getElements (e) {
    const template = e.closest('#finfolio-notes-template')
    const selectBox = template.querySelector('.ff-select')
    const hiddenNote = template.querySelector('.hidden-note')
    const hiddenValue = template.querySelector('.hidden-value')
    const description = template.querySelector('.ff-notes')

    return {
      template,
      selectBox,
      hiddenNote,
      hiddenValue,
      description,
    }
  }

  // Call this method when you want to save the edit
  saveEdit (e) {
    const { template, selectBox, hiddenNote, hiddenValue, description } = this.getElements(e)
    const noteId = template.querySelector('#note-id').innerHTML
    const categoryID = template.querySelector('#ff-notes-select').value
    const selectedValue = selectBox.options[selectBox.selectedIndex]

    // Check if any changes occured, if not, cancel edit
    if (!this.anyChanges(selectedValue, template, hiddenNote, hiddenValue, description, e)) {
      return
    }

    util.setCSRFToken()

    axios
      .put(`/admin/notes/${noteId}`, {
        note: {
          description: description.innerHTML,
          categoryID,
        },
      })
      .then((response) => {
        this.handleSuccess(response, template, description.innerHTML, hiddenValue, selectedValue, hiddenNote)
      })
      .catch(() => {
        this.handleError(e)
      })
  }

  anyChanges (selectedValue, template, hiddenNote, hiddenValue, description, e) {
    if (selectedValue.value === '') {
      const selectBox = template.querySelector('#ff-notes-select')
      selectBox.classList.add('ff-edit-error')

      return false
    }

    if (description.innerHTML === hiddenNote.innerHTML && hiddenValue.innerHTML === selectedValue.value) {
      this.hideEditActions(template)
      this.cancelEdit(e)

      return false
    }

    return true
  }

  cancelEdit (e) {
    const { template, selectBox, hiddenNote, hiddenValue, description } = this.getElements(e)
    const typeContainer = template.querySelector('.ff-type-container')
    const typeParagraph = typeContainer.querySelector('.ff-type')

    selectBox.classList.remove('ff-edit-error')

    selectBox.value = hiddenValue.innerHTML
    typeParagraph.innerHTML = selectBox.selectedOptions[0].innerHTML.toUpperCase()

    if (hiddenValue.innerHTML !== '') {
      typeParagraph.classList.add('px-sm', 'py-2xs')
      typeParagraph.classList.remove('hidden')
    }

    description.innerHTML = hiddenNote.innerHTML
    template.classList.remove('ff-edit-error')
    selectBox.classList.add('hidden')

    this.hideEditActions(template)
  }

  handleSuccess (response, template, description, hiddenValue, selectedValue, hiddenNote) {
    const typeContainer = template.querySelector('.ff-type-container')
    const typeParagraph = typeContainer.querySelector('.ff-type')
    const selectBox = template.querySelector('.ff-select')

    this.removeBlankOption(selectBox)

    typeParagraph.classList.add('px-sm', 'py-2xs')

    this.showTypeEdit(typeContainer, false)
    template.classList.remove('ff-edit-error')
    this.hideEditActions(template)

    hiddenValue.innerHTML = selectedValue.value

    if (response) {
      window.app.snackBar.add(response.data.message)

      hiddenNote.innerHTML = description
    }
  }

  hideEditActions (template, arg1 = 'add', arg2 = 'remove') {
    const editActions = template.querySelector('#edit-actions')
    const editButton = template.querySelector('.edit-button')

    editActions.classList[arg1]('hidden')
    editButton.classList[arg2]('hidden')
  }

  removeBlankOption (selectBox) {
    const options = Array.from(selectBox.querySelectorAll('option'))

    options.forEach(option => {
      if (!option.hasAttribute('value') || option.getAttribute('value') === '') {
        selectBox.removeChild(option)
      }
    })
  }

  handleError (e) {
    const template = e.closest('#finfolio-notes-template')

    template.classList.add('ff-edit-error')
  }
}

export default EditContent
