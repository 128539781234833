/**
 * helpTransition() - A mini-framework that assists in implementing visual
 * transitions via data attributes. Elements are often hidden, shown, moved, or
 * transitioned other ways depending on the state of the application. Often, we
 * would rather animate these changed instead of seeing a sudden, jarring
 * update. Unfortunately, managing this sort of animation is kind of tricky.
 *
 * That's where this helper function comes in. For a given element
 * (e.g. `<div class="foo />"`), we can apply a set of three data attributes to
 * dictate how the element appears during a transition. The name of the
 * transition can be named anything. If we wanted to fade our example div into
 * view, we might call the transition 'fade in'. Here's what that might look
 * like:
 *
 * <div
 *   class="foo"
 *   data-transition-fade-in-duration="300"
 *   data-transition-fade-in-start="opacity-0"
 *   data-transition-fade-in-while="transition-opacity"
 *   data-transition-fade-in-end="opacity-100"
 * ></div>
 *
 * The data attribute syntax will always be:
 * `data-transition-[name-of-transition]-[start|while|end]`.
 * The three stages of transition are: `start`, `while`, and `end`.
 *
 * The script works by first applying the `start` classes, then applying the
 * `while` classes, and then swapping out the `start` classes for the `end`
 * classes. The script is smart enough to figure out how long the duration
 * takes, and it removes the `while` and `end` classes once the transition is
 * over.
 *
 * This script is heavily influenced by the Alpine.js transition feature. You
 * can read more about that here, if you're interested:
 * https://github.com/alpinejs/alpine/#x-transition
 *
 * -----------------------------------------------------------------------------
 * @param  {Object} elem        The DOM node we want to target.
 *
 * @param  {String} name        The PascalCase name of the transition.
 *                              For instance, if an element has the data
 *                              attribute `data-transition-foo-bar-start`, the
 *                              transition name would be `FooBar`.
 *
 * @param  {Null|String} type   The type of transition. If we are hiding
 *                              the node from view, use `hide`. If we're showing
 *                              a hidden element, use `show`. Otherwise, use
 *                              `null`.
 */
const helpTransition = (elem, name = '', type = null) => {
  const { startClasses, whileClasses, endClasses } = __findTransitionClasses(elem, name)
  const duration = __findTransitionDuration(elem, name)

  elem.classList.add(...startClasses.split(' '))

  // We use `requestAnimationFrame` here to guarantee that our rapid series
  // of manipulations to the element's `classList` happen at least one frame
  // apart. Otherwise, we can't guarantee that they'll happen in the right
  // order, which results in our transition class effects breaking.
  requestAnimationFrame(() => {
    elem.classList.add(...whileClasses.split(' '))

    requestAnimationFrame(() => {
      if (type === 'show') elem.classList.remove('hidden')

      requestAnimationFrame(function (timestamp) {
        elem.classList.remove(...startClasses.split(' '))
        elem.classList.add(...endClasses.split(' '))
      })
    })
  })

  window.setTimeout(() => {
    elem.classList.remove(...whileClasses.split(' '))
    elem.classList.remove(...endClasses.split(' '))

    if (type === 'hide') elem.classList.add('hidden')
  }, duration)
}

const __findTransitionDuration = (elem, name) => {
  return elem.dataset[`transition${name}Duration`]
}

const __findTransitionClasses = (elem, name) => {
  const startClasses = elem.dataset[`transition${name}Start`]
  const whileClasses = elem.dataset[`transition${name}While`]
  const endClasses = elem.dataset[`transition${name}End`]

  return { startClasses, whileClasses, endClasses }
}

export default helpTransition
