// Vendor imports
import '@rails/actiontext'
import 'alpine-turbo-drive-adapter'
import 'cocoon-js-vanilla'
import 'trix'

import { Turbo } from '@hotwired/turbo-rails'
import Alpine from 'alpinejs'
import axios from 'axios'
import Rails from '@rails/ujs'
import TomSelect from 'tom-select'

// App components
import BuildSettings from '../build-settings'
import CategorySelector from '../category-selector'
import ContentType from '../content-type'
import Croppable from '../croppable'
import CurrencyInput from '../currency-input'
import Datepicker from '../datepicker'
import DigitInput from '../digit-input'
import Doughnut from '../doughnut'
import Drawer from '../drawer'
import EditContent from '../edit-content'
import FileUpload from '../file-upload'
import FinfolioNotes from '../finfolio-notes'
import MultiAttachment from '../multi-attachment'
import MultiSubmission from '../multi-submission'
import NewBusiness from '../new-business'
import GenerateField from '../generate-field'
import IdentityTracker from '../identity-tracker'
import IdentityCheck from '../identity-check'
import IframeLoader from '../iframe-loader'
import Notes from '../notes'
import NumberInput from '../number-input'
import LoginFlow from '../login-flow'
import Modal from '../modal'
import OverflowArrow from '../overflow-arrow'
import PriorityToggle from '../priority-toggle'
import Locking from '../locking'
import ResourceItems from '../resource-items'
import RichTextEditor from '../rich-text-editor'
import StickyTableHeader from '../sticky-table-header'
import SubmissionFieldSelection from '../submission-field-selection'
import SubmissionViewManagement from '../submission-view-management'
import TableNesting from '../table_nesting'
import TimeZoneManager from '../time-zone-manager'
import TooltipManager from '../tooltip-manager'
import util from '../util'

// Hotwire Components
import ActivtyStreamManagement from '../activity-stream-management'
import AttachmentStreamManagement from '../attachment-stream-management'
import DeleteDialogManagement from '../delete-dialog-management'
import RecordStreamManagement from '../record-stream-management'
import RemoveStreamManagement from '../remove-stream-management'
import PriorityStreamManagement from '../priority-stream-management'
import StreamManagement from '../stream-management'
import SubmissionsTableStreamManagement from '../submissions-table-stream-management'
import TurboManagement from '../turbo-management'

// Stimulus Controllers
import { stimulus } from '../stimulus/application'
import '../stimulus'

// Initialize the app
document.addEventListener('DOMContentLoaded', () => {
  window.app = {
    axios: axios,
    buildSettings: BuildSettings,
    categorySelector: CategorySelector,
    croppable: Croppable,
    contentType: ContentType,
    currencyInput: CurrencyInput,
    currentXhr: null,
    datepicker: Datepicker,
    digitInput: new DigitInput(),
    doughnut: Doughnut,
    drawer: new Drawer(),
    editContent: EditContent,
    fileUpload: FileUpload,
    finfolioNotes: FinfolioNotes,
    newBusiness: NewBusiness,
    generateField: GenerateField,
    multiAttachment: MultiAttachment,
    multiSubmission: MultiSubmission,
    notes: Notes,
    numberInput: NumberInput,
    IdentityCheck: new IdentityCheck(),
    identityTracker: IdentityTracker,
    iframeLoader: new IframeLoader(),
    loginFlow: LoginFlow,
    locking: new Locking(),
    modal: new Modal(),
    overflowArrow: OverflowArrow,
    priorityToggle: PriorityToggle,
    resourceItems: new ResourceItems(),
    richTextEditor: new RichTextEditor(),
    snackBar: stimulus.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller="snack-bar"]')!,
      'snack-bar'
    ),
    stickyTableHeader: StickyTableHeader,
    submissionFieldSelection: SubmissionFieldSelection,
    submissionViewManagement: new SubmissionViewManagement(),
    tableNesting: TableNesting,
    timeZoneManger: new TimeZoneManager(),
    tomSelect: TomSelect,
    tooltipManager: new TooltipManager(),
    util,

    // Hotwire Components
    activityStreamManagement: new ActivtyStreamManagement(),
    attachmentStreamManagement: new AttachmentStreamManagement(),
    deleteDialogManagement: new DeleteDialogManagement(),
    recordStreamManagement: new RecordStreamManagement(),
    removeStreamManagement: new RemoveStreamManagement(),
    priorityStreamManagement: new PriorityStreamManagement(),
    streamManagement: new StreamManagement(),
    submissionsTableStreamManagement: new SubmissionsTableStreamManagement(),
    turboManagement: new TurboManagement(),
  }

  window.app.modal.init()
  window.app.drawer.init()
  window.app.locking.init()
  window.app.richTextEditor.init()
  window.app.IdentityCheck.init()
  window.app.turboManagement.init()

  // NOTE: This line is required by the Alpine Turbo Drive Adapter
  // and must be placed before the Alpine.start() call.
  window.Alpine = Alpine

  // Init vendor libraries
  Alpine.start()
  if (!('_rails_loaded' in window) || !window._rails_loaded) Rails.start()
  Turbo.session.drive = false
})

document.addEventListener('turbo:load', () => {
  window.app.tooltipManager.init()
  window.app.timeZoneManger.init()
})

document.addEventListener('turbo:before-render', () => {
  window.app.modal.close()
  window.app.drawer.close()
})

document.addEventListener('ajax:send', (event) => {
  // Abort the old AJAX request (if there is one)
  // Then, store the new AJAX request on the app level
  if (window.app.currentXhr) { window.app.currentXhr.abort() }
  window.app.currentXhr = event.detail[0]
})

// Safely forget the previous AJAX request once it completes
document.addEventListener('ajax:complete', (event) => {
  window.app.currentXhr = null
})
