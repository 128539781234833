import { Controller } from '@hotwired/stimulus'

export default class FilterViewController extends Controller<HTMLElement> {
  static targets = [
    'accordion', // Each filter exists within an accordion component
    'topbar', // The top bar form that displays currently applied filters
    'sidebar', // The sidebar form is a collection of filter accordions
    'sidebarToggle', // The button that toggles the sidebar
  ]

  declare sidebarOpen: boolean
  declare readonly accordionTargets: HTMLDetailsElement[]
  declare readonly topbarTarget: HTMLFormElement
  declare readonly sidebarTarget: HTMLFormElement
  declare readonly sidebarToggleTarget: HTMLButtonElement

  SIDEBAR_WIDTH = '16rem'
  SIDEBAR_GAP = '1.5rem'
  SIDEBAR_OFFSET = [
    '-',
    (parseFloat(this.SIDEBAR_WIDTH) + parseFloat(this.SIDEBAR_GAP)),
    'rem'
  ].join('')

  connect() {
    const sidebarOpenState = this.#getSidebarOpenState()

    this.#setSidebarOpenState(sidebarOpenState)
    this.#determineWhichAccordionsToOpen()
    this.#setSidebarToggleAttrs(sidebarOpenState)

    if (sidebarOpenState === false) this.#hideSidebar()
  }

  // Public methods

  toggleSidebar() {
    this.#setSidebarToggleAttrs(!this.sidebarOpen)

    this.sidebarTarget
      .animate(
        [
          { marginRight: '0rem', opacity: 1 },
          { marginRight: this.SIDEBAR_OFFSET, opacity: 0 },
        ],
        {
          direction: this.sidebarOpen ? 'normal' : 'reverse',
          duration: 100,
          easing: 'ease-out',
          fill: 'both',
        }
      )
      .finished
      .then(() => {
        this.sidebarTarget.classList.toggle('pointer-events-none')
        this.#setSidebarOpenState(!this.sidebarOpen)
      })
  }

  // 'Private' methods

  #determineWhichAccordionsToOpen() {
    if (this.#elemHasActiveFilters(this.sidebarTarget)) {
      this.#openPopulatedAccordions()
    } else {
      this.#openAllAccordions()
    }
  }

  #elemHasActiveFilters(element: HTMLElement) {
    const hasCheckedBoxes = Boolean(element.querySelector('input[type=checkbox]:checked'))
    const hasFilledSearches = Boolean(element.querySelector('input[type=search][value]'))

    return Boolean(hasCheckedBoxes || hasFilledSearches)
  }

  #getSidebarOpenState() {
    const storedValue = localStorage.getItem('filterViewSidebarOpen')

    return storedValue === null ? true : storedValue === 'true'
  }

  #hideSidebar() {
    this.sidebarTarget.style.marginRight = this.SIDEBAR_OFFSET
    this.sidebarTarget.style.opacity = '0'
    this.sidebarTarget.style.pointerEvents = 'none'
  }

  #openAllAccordions() {
    this.accordionTargets.forEach((accordion) => accordion.open = true)
  }

  #openPopulatedAccordions() {
    this.accordionTargets.forEach((accordion) => {
      if (this.#elemHasActiveFilters(accordion)) accordion.open = true
    })
  }

  #setSidebarOpenState(value: boolean) {
    this.sidebarOpen = value
    localStorage.setItem('filterViewSidebarOpen', String(value))
  }

  #setSidebarToggleAttrs(value: boolean) {
    this.sidebarToggleTarget.setAttribute('aria-expanded', String(value))
  }
}
