import { Chart } from 'chart.js'

class Doughnut {
  constructor ($elem) {
    this.$elem = $elem

    this.styles = {
      primaryFontColor: '#423D3D',
      // secondaryFontColor: '#918A8A',
      fontFamily: 'proxima-nova, sans-serif',
      fontSize: 14,
    }

    this.__createCanvas__()
  }

  init () {
    const context = this.$canvas.getContext('2d')
    const data = this.__parseData__()

    this.$elem.doughnut = new Chart(context, {
      type: 'doughnut',
      data,
      options: {
        legend: { position: 'right' },
        maintainAspectRatio: false,
        onResize (chart, size) {
          chart.options.legend.position = size.width < 500 ? 'bottom' : 'right'
        },
      },
    })
  }

  __createCanvas__ () {
    this.$canvas = document.createElement('canvas')

    this.$elem.innerHTML = ''
    this.$elem.append(this.$canvas)
  }

  __getBackgroundColors__ () {
    const style = getComputedStyle(this.$elem)

    // Get five different shades of the whitelabel color
    const colors = [
      style.getPropertyValue('--whitelabel-700'),
      style.getPropertyValue('--whitelabel-600'),
      style.getPropertyValue('--whitelabel-500'),
      style.getPropertyValue('--whitelabel-400'),
      style.getPropertyValue('--whitelabel-300'),
    ]

    // Repeat colors and return a flattened array
    // NOTE: This will only support charts with fewer than 20 data points
    return [colors, colors, colors, colors].flat()
  }

  __parseData__ () {
    const data = JSON.parse(this.$elem.dataset.doughnut)
    const backgroundColor = this.__getBackgroundColors__()

    return {
      datasets: [{
        data: Object.values(data),
        backgroundColor,
        hoverBackgroundColor: backgroundColor,
      }],
      labels: Object.keys(data),
    }
  }
}

export default Doughnut
