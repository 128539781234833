import MultiSelect from './multi-select'

class MultiSubmission extends MultiSelect {
  init (e, identity) {
    if (identity === 'admin') {
      this.determineCheckboxStatus('submission')
      this.multiSelectIconHandler('submission')
    }

    if (identity === 'advisor') {
      this.handleInputChanges()

      this.compareAllValues(e)

      this.nigoCheck()
    }
  }

  nigoCheck () {
    const chipContainers = Array.from(document.querySelectorAll('.chip-container'))

    chipContainers.forEach((container) => {
      const chip = container.querySelector('span')

      if (chip.classList.contains('nigo-chip')) {
        const chip = this.buildChip()
        const arrow = this.buildArrow()

        chip.classList.add('bg-yellow-500', 'bg-opacity-10', 'border', 'border-yellow-500', 'flex', 'items-center', 'justify-center', 'chip')
        chip.innerHTML = 'Pending'

        container.appendChild(arrow)
        container.appendChild(chip)
      }
    })
  }

  handleInputChanges () {
    const inputs = document.querySelectorAll('.record-field')

    inputs.forEach((input) => {
      if (input.type === 'select-one') {
        input.addEventListener('change', (e) => {
          this.compareValues(e)
        })
      } else {
        input.addEventListener('input', (e) => {
          this.compareValues(e)
        })
      }
    })
  }

  compareAllValues (e) {
    const records = Array.from(e.querySelectorAll('.new-business-record'))

    records.forEach((record) => {
      const inputs = Array.from(record.querySelectorAll('.record-item'))

      this.highlightChangedLabels(record, inputs)
    })
  }

  compareValues (e) {
    const inputs = Array.from(e.target.closest('.record-body').querySelectorAll('.record-item'))

    this.highlightChangedLabels(e.target, inputs)
  }

  highlightChangedLabels (arg, values) {
    const nonmatchingRecords = []

    values.forEach((input) => {
      const initialValue = input.querySelector('.stored-record-data').innerHTML
      const label = input.querySelector('label')
      const inputField = input.querySelector('.record-field')

      if (!inputField) return

      const currentValue = inputField.value.replaceAll(',', '')

      if (initialValue !== currentValue) {
        nonmatchingRecords.push(currentValue)

        label.classList.add('has-updates')
      } else {
        label.classList.remove('has-updates')
      }
    })

    nonmatchingRecords.length === 0 ? this.removePending(arg) : this.createPending(arg)
  }

  createPending (target) {
    const arrow = this.buildArrow()
    const chip = this.buildChip()
    const chipContainer = target.closest('.new-business-record')?.querySelector('.chip-container')

    if (!chipContainer) return

    const existingArrow = chipContainer.querySelector('.arrow')
    const existingChip = chipContainer.querySelector('.chip')

    chip.classList.add('bg-yellow-500', 'bg-opacity-10', 'border', 'border-yellow-500', 'flex', 'items-center', 'justify-center', 'chip')
    chip.innerHTML = 'Pending'

    if (!existingArrow) chipContainer.appendChild(arrow)
    if (!existingChip) chipContainer.appendChild(chip)
  }

  removePending (target) {
    const chipContainer = target.closest('.new-business-record')?.querySelector('.chip-container')

    if (!chipContainer) return

    const existingArrow = chipContainer.querySelector('.arrow')
    const existingChip = chipContainer.querySelector('.chip')

    existingChip?.remove()
    existingArrow?.remove()
  }

  observeRecordDataChanges () {
    const records = Array.from(document.querySelectorAll('.new-business-record'))

    records.forEach((record) => {
      record.addEventListener('change', (e) => {
        this.compareValues(e)
      })
    })
  }

  storeInitialStatus () {
    const records = Array.from(document.querySelectorAll('.new-business-record'))

    records.forEach((record) => {
      const hiddenStatus = record.querySelector('.hidden-status')?.value

      // If the hidden-status input doesn't exist, we don't need to store anything
      if (!hiddenStatus) return

      const text = document.createElement('p')

      text.classList.add('stored-status', 'hidden')
      text.innerHTML = hiddenStatus

      record.appendChild(text)
    })
  }

  handleSelectedStatus (e) {
    e.addEventListener('click', (e) => {
      const container = e.target.closest('.multi-select-container')
      const checkedCheckboxes = Array.from(container.querySelectorAll('input[type="checkbox"]:checked'))
      const status = e.target.closest('.icon-hover').dataset.status

      checkedCheckboxes.forEach((box) => {
        const item = box.closest('.multi-select-item')
        const storedStatus = box.closest('.new-business-record').querySelector('.stored-status').innerHTML
        const hiddenStatus = item.querySelector('.hidden-status')
        const chip = item.querySelector('.chip')
        const arrow = item.querySelector('.arrow')

        if (status === 'undo') {
          hiddenStatus.value = storedStatus

          this.actionBarHelper(item, chip, arrow)
          return
        }

        if (hiddenStatus.value === status) {
          return
        }

        if (storedStatus === 'nigo' || storedStatus === 'approved' || storedStatus === 'declined') {
          if (storedStatus === status) {
            hiddenStatus.value = storedStatus

            this.actionBarHelper(item, chip, arrow)

            return
          }
          if (status === 'pending') {
            this.buildPendingChip(item, status, hiddenStatus)
            return
          } else return
        }

        hiddenStatus.value = status
        this.buildNewStatusChip(item, status, hiddenStatus)
      })
    })
  }

  actionBarHelper (item, chip, arrow) {
    const hoverUndoIcon = item.querySelector('.status-undo-icon')
    const iconContainer = item.querySelector('.icon-container')

    chip?.remove()
    arrow?.remove()

    hoverUndoIcon.classList.add('hidden')
    iconContainer.classList.remove('hidden')
  }

  buildArrow () {
    const arrow = document.createElement('div')

    arrow.classList.add('arrow', 'text-xl', 'mx-[0.4rem]')
    arrow.innerHTML = this.arrowSVG()

    return arrow
  }

  buildChip () {
    const chip = document.createElement('span')
    const classes = ['text-gray-700', 'px-3', 'py-2xs', 'text-2xs', 'rounded-md', 'font-semibold', 'chip', 'bg-opacity-10', 'border']

    chip.classList.add(...classes)

    return chip
  }

  buildPendingChip (item, status, hiddenStatus, changed = false) {
    const storedStatus = item.closest('.new-business-record').querySelector('.stored-status')
    const iconContainer = item.querySelector('.icon-container')
    const undoIcon = item.querySelector('.status-undo-icon')
    const chipContainer = item.querySelector('.chip-container')
    const capitalizeStatus = this.capitalizeFirstLetter(status)
    const existingChip = chipContainer.querySelector('.chip')
    const existingArrow = chipContainer.querySelector('.arrow')

    if (changed) {
      const arrow = this.buildArrow()
      const chip = this.buildChip()

      chip.classList.add('bg-yellow-500', 'bg-opacity-10', 'border', 'border-yellow-500')
      if (!existingArrow) chipContainer.appendChild(arrow)
      iconContainer.classList.add('hidden')
      undoIcon.classList.remove('hidden')
      chipContainer.appendChild(chip)
    }

    if (existingChip) return

    hiddenStatus.value = status

    const arrow = this.buildArrow()
    const chip = this.buildChip()

    chip.classList.add('bg-yellow-500', 'bg-opacity-10', 'border', 'border-yellow-500')

    iconContainer.classList.add('hidden')
    undoIcon.classList.remove('hidden')

    if (storedStatus.innerHTML === status) {
      chip.classList.add('hidden')
      arrow.classList.add('hidden')
    }

    chip.innerHTML = capitalizeStatus

    if (!existingArrow) chipContainer.appendChild(arrow)

    chipContainer.appendChild(chip)
  }

  buildNewStatusChip (item, status) {
    const storedStatus = item.closest('.new-business-record').querySelector('.stored-status')
    const iconContainer = item.querySelector('.icon-container')
    const undoIcon = item.querySelector('.status-undo-icon')
    const chipContainer = item.querySelector('.chip-container')
    const capitalizeStatus = this.capitalizeFirstLetter(status)
    const existingArrow = chipContainer.querySelector('.arrow')
    const existingChip = chipContainer.querySelector('.chip')

    const arrow = this.buildArrow()
    const chip = this.buildChip()

    iconContainer.classList.add('hidden')
    undoIcon.classList.remove('hidden')

    if (storedStatus.innerHTML === status) {
      chip.classList.add('hidden')
      existingArrow.classList.add('hidden')
      undoIcon.classList.add('hidden')
      iconContainer.classList.remove('hidden')
    }

    const classMap = {
      approved: { removeClass: 'hidden', addClasses: ['bg-accent-green', 'border-accent-green'] },
      nigo: { removeClass: 'hidden', addClasses: ['bg-accent-red', 'border-accent-red'] },
      declined: { removeClass: 'hidden', addClasses: ['bg-gray-400', 'border-gray-400'] },
      pending: { addClasses: ['bg-yellow-500', 'border-yellow-500'] },
    }

    const classInfo = classMap[status]

    if (classInfo) {
      if (classInfo.removeClass) {
        existingArrow?.classList.remove(classInfo.removeClass)
      }
      if (classInfo.addClasses) {
        chip.classList.add(...classInfo.addClasses)
      }
    }

    chip.innerHTML = capitalizeStatus

    if (!existingArrow) chipContainer.appendChild(arrow)

    existingChip ? chipContainer.replaceChild(chip, existingChip) : chipContainer.appendChild(chip)

    if (status === 'undo') {
      iconContainer.classList.remove('hidden')
      undoIcon.classList.add('hidden')
      chip?.remove()
      arrow?.remove()
    }
  }

  handleHoverIcons (el) {
    const icons = Array.from(el.querySelector('.icon-container').querySelectorAll('.icon-hover'))

    icons.forEach((icon) => {
      icon.addEventListener('click', (e) => {
        const status = e.target.closest('.icon-hover').dataset.status
        const item = e.target.closest('.primary-row-footer')
        const hiddenStatus = e.target.closest('.new-business-record').querySelector('.hidden-status')

        hiddenStatus.value = status
        this.buildNewStatusChip(item, status)
      })
    })
  }

  handleUndoAction (el) {
    const storedStatus = el.closest('.new-business-record').querySelector('.stored-status').innerHTML
    const hiddenStatus = el.closest('.new-business-record').querySelector('.hidden-status')
    const iconContainer = el.closest('#hover-icons').querySelector('.icon-container')
    const chip = el.closest('.footer-list').querySelector('.chip')
    const arrow = el.closest('.footer-list').querySelector('.arrow')

    iconContainer.classList.remove('hidden')
    el.classList.add('hidden')
    chip.remove()
    arrow.remove()
    hiddenStatus.value = storedStatus
  }

  handleGlobalUndo (el) {
    el.addEventListener('click', (e) => {
      const container = e.target.closest('.multi-select-container')
      const checkedCheckboxes = Array.from(container.querySelectorAll('input[type="checkbox"]:checked'))

      checkedCheckboxes.forEach((box) => {
        const item = box.closest('.multi-select-item')
        const storedStatus = box.closest('.new-business-record').querySelector('.stored-status').innerHTML
        const hiddenStatus = item.querySelector('.hidden-status')

        hiddenStatus.value = storedStatus
      })
    })
  }

  arrowSVG () {
    return `
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_7082_3508)">
        <path d="M10.6732 7.68015H3.33317C2.9665 7.68015 2.6665 7.98015 2.6665 8.34681C2.6665 8.71348 2.9665 9.01348 3.33317 9.01348H10.6732V10.2068C10.6732 10.5068 11.0332 10.6535 11.2398 10.4401L13.0932 8.58015C13.2198 8.44681 13.2198 8.24015 13.0932 8.10681L11.2398 6.24681C11.0332 6.03348 10.6732 6.18681 10.6732 6.48015V7.68015Z" fill="#928B8A"/>
        </g>
        <defs>
        <clipPath id="clip0_7082_3508">
        <rect width="16" height="16" fill="white" transform="translate(0 0.346924)"/>
        </clipPath>
        </defs>
      </svg>
    `
  }

  capitalizeFirstLetter (str) {
    if (str === 'nigo') {
      return str.toUpperCase()
    }
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
}

export default MultiSubmission
