import { Controller } from '@hotwired/stimulus'

interface EventWithParams extends Event {
  params: {
    event: string
  }
}

export default class DispatchController extends Controller<HTMLElement> {
  event({ params: { event: eventName } }: EventWithParams) {
    this.dispatch(eventName)
  }
}
