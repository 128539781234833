import { Controller } from '@hotwired/stimulus'
import DropzoneController, { AttachEvent } from './dropzone'
import AttachmentManagerItemController from './attachment-manager-item'

export default class AttachmentManagerController extends Controller<HTMLElement> {
  declare allowMultipleValue: boolean
  declare visibleItemsValue: number
  declare readonly attachmentManagerItemOutlets: AttachmentManagerItemController[]
  declare readonly dropzoneOutlet: DropzoneController
  declare readonly hasDropzoneOutlet: boolean
  declare readonly hasItemOutlet: boolean
  declare readonly itemTemplateTarget: HTMLTemplateElement
  declare readonly listTarget: HTMLUListElement

  static outlets = ['attachment-manager-item', 'dropzone']
  static targets = ['itemTemplate', 'list']
  static values = {
    allowMultiple: { type: Boolean, default: false },
    visibleItems: { type: Number, default: 0 },
  }

  connect() {
    this.visibleItemsValue = this.attachmentManagerItemOutlets.length
    this.element.addEventListener(
      'attachment-manager-item:detach',
      this.#handleDetachment.bind(this)
    )
    this.element.addEventListener(
      'dropzone:attach',
      this.#handleAttachments.bind(this)
    )
  }

  disconnect() {
    this.element.removeEventListener(
      'attachment-manager-item:detach',
      this.#handleDetachment.bind(this)
    )
    this.element.removeEventListener(
      'dropzone:attach',
      this.#handleAttachments.bind(this)
    )
  }

  // Outlet callbacks

  attachmentManagerItemOutletConnected() {
    this.#rerender()
  }

  attachmentManagerItemOutletDisconnected() {
    this.#rerender()
  }

  // Value change callbacks

  visibleItemsValueChanged() {
    this.#handleVisibleItemsChange()
  }

  // Private methods

  #countVisibleItems() {
    this.visibleItemsValue = this.attachmentManagerItemOutlets.filter(
      item => item.detachedValue !== true
    ).length
  }

  #createItem(file: File) {
    const fragment = this.itemTemplateTarget.content.cloneNode(true) as DocumentFragment
    const item = fragment.querySelector('li')

    // NOTE: Because we're working with `File` objects—and Stimulus can't
    // really express `File` objects via its "Value" feature, we need to
    // do two things:
    //
    // 1. Create a new item and insert it into the DOM
    // 2. Find the newly-created item's outlet, and then
    //    pass the file into its `attach` method.
    //
    // If we were working with less complex data, we could just pass
    // it in via controller values and skip the second step.
    this.listTarget.appendChild(fragment)
    this.attachmentManagerItemOutlets.find(o => o.element === item).attach(file)
  }

  #handleAttachments(event: AttachEvent) {
    for (const file of Array.from(event.detail.files)) {
      this.#createItem(file)
    }
  }

  #handleDetachment() {
    this.#rerender()
  }

  #handleVisibleItemsChange() {
    const actions = this.allowMultipleValue
      ? ['remove', 'remove'] // Remove '.hidden' from dropzone and list
      : this.visibleItemsValue > 0
        ? ['add', 'remove'] // Add '.hidden' to dropzone, remove from list
        : ['remove', 'add'] // Remove '.hidden' from dropzone, add to list

    this.dropzoneOutlet.element.classList[actions[0]]('hidden')
    this.listTarget.classList[actions[1]]('hidden')
  }

  #reindexItems() {
    this.attachmentManagerItemOutlets.forEach((item, index) => {
      item.indexValue = index
    })
  }

  #rerender() {
    this.#reindexItems()
    this.#countVisibleItems()
  }
}
