/** * Submission Table Stream Management - A class to assist with javascript sprinkles
*/
class SubmissionsTableStreamManagement {
  stream (render, streamElement, fragment, event) {
    if (streamElement.action === 'prepend') {
      this.prependAction(render, streamElement, fragment)
    }

    if (streamElement.action === 'replace') {
      this.replaceAction(render, streamElement, fragment)
    }

    if (streamElement.action === 'update') {
      render(streamElement)
    }

    if (streamElement.action === 'remove') {
      this.removeAction(event)
    }

    window.app.tooltipManager.init()
    window.app.tooltipManager.resetTooltip()
  }

  prependAction (render, streamElement, fragment) {
    const viewable = window.app.streamManagement.viewable(fragment)

    if (!viewable) { return }

    window.app.streamManagement.actionButton(fragment)

    render(streamElement)
  }

  replaceAction (render, streamElement, fragment) {
    window.app.streamManagement.actionButton(fragment)

    render(streamElement)
  }

  removeAction (event) {
    event.preventDefault()

    const target = document.getElementById(event.target.target)

    if (!target) { return }

    target.classList.remove('animate-row')
    target.classList.add('remove-row')
    target.addEventListener('animationend', () => {
      target.remove()
    })
  }
}

export default SubmissionsTableStreamManagement
