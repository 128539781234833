import {
  arrow,
  autoUpdate,
  computePosition,
  flip,
  offset,
  shift,
  type ComputePositionConfig,
  type OffsetOptions,
  type Placement,
} from '@floating-ui/dom'
import { Controller } from '@hotwired/stimulus'

export default class DropdownController extends Controller<HTMLElement> {
  static targets = ['arrow', 'button', 'dialog']

  declare cleanup: () => void | null
  declare expanded: boolean
  declare hasArrow: boolean
  declare readonly arrowTarget: HTMLDivElement | null
  declare readonly buttonTarget: HTMLButtonElement
  declare readonly dialogTarget: HTMLDivElement
  declare readonly hasArrowTarget: boolean

  connect() {
    this.cleanup = null
    this.expanded = this.buttonTarget.getAttribute('aria-expanded') === 'true'
    this.hasArrow = this.element.dataset.dropdownArrow === 'true'

    if (this.hasArrow) this.#attachArrow()
    if (this.expanded) this.#autoUpdate()

    document.addEventListener('click', this.#handleClick.bind(this))
    document.addEventListener('keyup', this.#handleKeyup.bind(this))
  }

  disconnect() {
    document.removeEventListener('click', this.#handleClick.bind(this))
    document.removeEventListener('keyup', this.#handleKeyup.bind(this))
    if (this.cleanup !== null) this.cleanup()
  }

  // Public methods

  collapse() {
    this.buttonTarget.setAttribute('aria-expanded', 'false')
    this.dialogTarget.classList.add('hidden')
    this.expanded = false
    if (this.cleanup !== null) { this.cleanup() }
    this.#dispatchDatasetEvent('dropdownCollapseEvent')
    this.dispatch('collapse')
  }

  expand() {
    this.buttonTarget.setAttribute('aria-expanded', 'true')
    this.dialogTarget.classList.remove('hidden')
    this.#autoUpdate()
    this.expanded = true
    this.#dispatchDatasetEvent('dropdownExpandEvent')
    this.dispatch('expand')
  }

  toggle() {
    this.expanded ? this.collapse() : this.expand()
    this.#dispatchDatasetEvent('dropdownToggleEvent')
    this.dispatch('toggle')
  }

  // Private methods

  #attachArrow() {
    const arrow = document.createElement('div')
    arrow.setAttribute('class', `
      absolute rotate-45 size-4 group-[.default-theme]:bg-white
    `)
    arrow.id = `${this.dialogTarget.id}-arrow`
    arrow.dataset.dropdownTarget = 'arrow'

    this.dialogTarget.append(arrow)
  }

  #autoUpdate() {
    this.cleanup = autoUpdate(
      this.buttonTarget,
      this.dialogTarget,
      this.#computePosition.bind(this),
    )
  }

  #clickedWithin(referenceElement: HTMLElement, clickedElement: HTMLElement,) {
    return (
      referenceElement === clickedElement ||
      referenceElement.contains(clickedElement)
    )
  }

  #computePosition() {
    const config = {
      middleware: this.#getMiddleware(),
      placement: this.#getPlacement(),
    } satisfies ComputePositionConfig

    computePosition(this.buttonTarget, this.dialogTarget, config)
      .then(this.#handleComputedPosition.bind(this))
  }

  #dispatchDatasetEvent(eventName: string) {
    const value = this.element.dataset[eventName]
    if (value) this.dispatch(value, { prefix: '' })
  }

  #getMiddleware() {
    const { dropdownOffset } = this.element.dataset
    let offsetOptions: OffsetOptions = dropdownOffset
      ? JSON.parse(dropdownOffset)
      : 0

    if (this.hasArrow && typeof offsetOptions === 'number') {
      offsetOptions += 8 // Add arrow size to offset
    }

    const middleware: ComputePositionConfig['middleware'] = [
      offset(offsetOptions),
      flip(),
      shift({ padding: 8 }),
    ]

    if (this.hasArrow && this.hasArrowTarget) {
      middleware.push(arrow({ element: this.arrowTarget }))
    }

    return middleware
  }

  #getPlacement() {
    const { dropdownPlacement } = this.element.dataset
    return dropdownPlacement as Placement || 'bottom'
  }

  #handleClick(event: Event) {
    const target = event.target as HTMLElement
    const clickedWithinButton = this.#clickedWithin(this.buttonTarget, target)
    const clickedWithinDialog = this.#clickedWithin(this.dialogTarget, target)

    if (clickedWithinButton) {
      this.toggle()
    } else if (clickedWithinDialog) {
      // Intentionally do nothing
    } else if (this.expanded) {
      this.collapse()
    }
  }

  #handleComputedPosition({middlewareData, placement, x, y}) {
    Object.assign(this.dialogTarget.style, {
      left: `${x}px`,
      top: `${y}px`,
    })

    if (!this.hasArrow || !this.hasArrowTarget) return

    const {x: arrowX, y: arrowY} = middlewareData.arrow

    const staticSide = {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[placement.split('-')[0]]

    Object.assign(this.arrowTarget.style, {
      left: arrowX != null ? `${arrowX}px` : '',
      top: arrowY != null ? `${arrowY}px` : '',
      right: '',
      bottom: '',
      [staticSide]: '-8px',
    })
  }

  #handleKeyup(event: KeyboardEvent) {
    const collapseKeys = ['Escape']

    if (collapseKeys.includes(event.key)) this.collapse()
  }
}
