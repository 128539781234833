import { Controller } from '@hotwired/stimulus'

interface EventWithParams extends Event {
  params: {
    value: number | object | string
  }
}

export default class ClipboardController extends Controller<HTMLElement> {
  declare readonly announcementTarget: HTMLElement

  static targets = ['announcement']

  connect() {
    this.element.ariaLive = 'polite'
    this.announcementTarget.classList.add('hidden')
  }

  async copy({ params: { value }}: EventWithParams) {
    if (!value) {
      return console.error('ClipboardController#copy requires a value to copy')
    }

    try {
      await navigator.clipboard.writeText(value.toString())
      this.#startAnnouncement()
      setTimeout(() => this.#endAnnouncement(), 1000)
    }

    catch (error) {
      console.error(error.message)
    }
  }

  // Private methods

  #endAnnouncement() {
    this.announcementTarget.classList.add('opacity-0')

    setTimeout(() => {
      this.announcementTarget.classList.add('hidden')
      this.announcementTarget.classList.remove('opacity-0')
    }, 500)
  }

  #startAnnouncement() {
    this.announcementTarget.classList.remove('hidden')
    this.announcementTarget.classList.add(
      'duration-500',
      'ease-out',
      'transition-opacity'
    )
  }
}
