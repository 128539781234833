import util from './util'

class NewBusiness {
  constructor (elem) {
    this.$fieldset = elem
    this.$comboBoxNext = elem.querySelector('.ts-control')
    this.$comboBox = elem.querySelector('[data-folios-select]')
    this.$container = elem.querySelector('[data-nested-records="submissions"]')
    this.$addLink = elem.querySelector('[data-nested-records-control="add"]')
    this.$records = Array.from(elem.querySelectorAll('.new-business-record'))
  }

  init () {
    if (!this.$comboBox) return

    this.hideAddLink()

    if (this.$comboBox) {
      this.$comboBox.addEventListener('change', this.handleSelection.bind(this))
    }

    this.disablePopulatedRows()
    this.setStageForDeletionListener()
    this.disableDOLInputs()
    this.hoverMenuHandler()
  }

  disableDOLInputs () {
    const disabledDOLFields = this.$container.querySelectorAll('.taxable')

    disabledDOLFields.forEach($field => {
      var taxableValue = $field.closest('.nested-record').querySelector('[data-field="isTaxable"]').value

      if ((taxableValue === 'false' || taxableValue !== undefined) && taxableValue !== 'true') { return }

      $field.disabled = 'true'
    })
  }

  setStageForDeletionListener () {
    this.$container.addEventListener(
      'cocoon:before-remove',
      this.toggleDeletion.bind(this),
    )
  }

  toggleDeletion (cocoonEvent) {
    if (!cocoonEvent.detail[1].target.closest('.existing')) { this.deleteSubmission(cocoonEvent) }

    cocoonEvent.preventDefault()

    const target = cocoonEvent.detail[1].target
    const targetText = target.parentElement.querySelector('.delete-text').innerHTML

    setTimeout(() => {
      if (targetText === 'Delete') {
        target.parentElement.querySelector('.delete-text').innerHTML = 'Undo Delete'
        target.parentElement.querySelector('.undo').classList.remove('hidden')
        target.parentElement.querySelector('.trash').classList.add('hidden')
      } else {
        target.parentElement.querySelector('.delete-text').innerHTML = 'Delete'
        target.parentElement.querySelector('.undo').classList.add('hidden')
        target.parentElement.querySelector('.trash').classList.remove('hidden')
      }
    }, 250)

    const hiddenInputs = cocoonEvent
      .detail[0]
      .querySelectorAll('input[type="hidden"]')

    hiddenInputs.forEach(input => {
      if (input.name.includes('_destroy')) {
        if (input?.value === '1') {
          this.undoDeletion(input)
        } else {
          this.stageForDeletion(input)
        }
      }
    })
  }

  undoDeletion (input) {
    input.value = 'false'

    this.handleDeletedChip(input, 'delete')
    this.handleCardOverlay(input, 'delete')
    this.updateIcon(input, 'undo')
  }

  stageForDeletion (input) {
    input.value = '1'

    this.handleDeletedChip(input)
    this.handleCardOverlay(input)
    this.updateIcon(input)
  }

  updateIcon (input, value = 'stage') {
    const trashIcon = input.closest('.new-business-record')?.querySelector('.record-trash-icon')
    const undoIcon = input.closest('.new-business-record')?.querySelector('.record-undo-icon')
    const anchor = input.closest('li')?.querySelector('a')
    const anchorTooltip = anchor.dataset.tooltip
    const hoverMenu = input.closest('.new-business-record').querySelector('.trash-icon-container')

    if (!trashIcon || !undoIcon) { return }

    if (anchorTooltip === 'Delete Submission') {
      hoverMenu.classList.toggle('hover-menu')
      window.app.tooltipManager.create(anchor, null, null, 'Undo Deletion')
      anchor.dataset.tooltip = 'Undo Deletion'
    } else {
      hoverMenu.classList.toggle('hover-menu')
      window.app.tooltipManager.create(anchor, null, null, 'Delete Submission')
      anchor.dataset.tooltip = 'Delete Submission'
    }

    undoIcon.classList.toggle('hidden')
    trashIcon.classList.toggle('hidden')
  }

  handleCardOverlay (input, value = 'add') {
    const listItems = Array.from(input.closest('.new-business-record').querySelector('.record-header-container').querySelectorAll('.record-header-list-item'))
    const recordBody = input.closest('.new-business-record').querySelector('.record-body')

    if (value === 'delete') {
      this.handleRecordClasses('remove', listItems, recordBody)
      return
    }

    this.handleRecordClasses('add', listItems, recordBody)
  }

  handleRecordClasses (val, listItems, recordBody) {
    recordBody.classList[val]('bg-gray-50', 'opacity-50')

    listItems.forEach(item => {
      item.classList[val]('bg-gray-50', 'opacity-50')
    })
  }

  handleDeletedChip (input, value = 'add') {
    if (value === 'delete') {
      const existingChip = input.closest('.new-business-record')?.querySelector('.deleted-chip')
      const existingArrow = input.closest('.new-business-record')?.querySelector('.arrow')

      existingChip.remove()
      existingArrow.remove()
      return
    }

    const arrow = this.buildArrow()
    const chip = this.buildChip()
    const chipContainer = input.closest('.new-business-record')?.querySelector('.chip-container')
    const chipClasses = ['bg-gray-400', 'bg-opacity-10', 'border', 'border-gray-400', 'flex', 'items-center', 'fade-in', 'justify-center', 'deleted-chip']

    if (!chipContainer) return

    arrow.classList.add('fade-in', 'arrow')
    chip.classList.add(...chipClasses)
    chip.innerHTML = 'Deleted'

    chipContainer.appendChild(arrow)
    chipContainer.appendChild(chip)
  }

  buildArrow () {
    const arrow = document.createElement('div')

    arrow.classList.add('arrow', 'text-xl', 'mx-[0.4rem]')
    arrow.innerHTML = this.arrowSVG()

    return arrow
  }

  buildChip () {
    const chip = document.createElement('span')
    const classes = ['text-gray-700', 'px-3', 'py-2xs', 'text-2xs', 'rounded-md', 'font-semibold', 'chip', 'bg-opacity-10', 'border']

    chip.classList.add(...classes)

    return chip
  }

  hoverMenuHandler () {
    this.$records.forEach(record => {
      const hoverMenu = record.querySelector('.hover-menu')

      record.addEventListener('mouseenter', () => {
        hoverMenu?.classList.remove('hidden')
      })

      record.addEventListener('mouseleave', () => {
        hoverMenu?.classList.add('hidden')
      })
    })
  }

  arrowSVG () {
    return `
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_7082_3508)">
        <path d="M10.6732 7.68015H3.33317C2.9665 7.68015 2.6665 7.98015 2.6665 8.34681C2.6665 8.71348 2.9665 9.01348 3.33317 9.01348H10.6732V10.2068C10.6732 10.5068 11.0332 10.6535 11.2398 10.4401L13.0932 8.58015C13.2198 8.44681 13.2198 8.24015 13.0932 8.10681L11.2398 6.24681C11.0332 6.03348 10.6732 6.18681 10.6732 6.48015V7.68015Z" fill="#928B8A"/>
        </g>
        <defs>
        <clipPath id="clip0_7082_3508">
        <rect width="16" height="16" fill="white" transform="translate(0 0.346924)"/>
        </clipPath>
        </defs>
      </svg>
    `
  }

  hideAddLink () {
    if (!this.$addLink) { return }

    this.$addLink.style.left = '-9999px'
    this.$addLink.style.position = 'absolute'
  }

  disablePopulatedRows () {
    // find the table rows
    const $records = this.$container.querySelectorAll('.new-business-record')

    // loop through each row
    $records.forEach($record => {
      // find the account number for the row
      const accountNumber = $record.querySelector('[data-folios-field="accountNumber"]')?.value

      // if no account number, bail
      if (!accountNumber) { return }

      // find the associated option in the select
      if (!this.$comboBox) { return }

      const newRecord = $record.querySelector('[data-field="hiddenNewAccount"]').value

      if (newRecord !== 'true') {
        this.disableExistingAccountOptions($record)
      }

      const $remove = $record.querySelector('[data-nested-records-control="remove"]')

      if ($remove) {
        $remove.addEventListener('click', this.reviveAccount.bind(this))
      }

      // look for the account number within the select options
      const accountOption = this.$comboBox.querySelector(`[data-account-number="${accountNumber}"]`)

      // if found, disable the option
      if (accountOption) accountOption.disabled = true
    })
  }

  disableExistingAccountOptions (record) {
    const fieldNames = ['accountName', 'accountNumber', 'repCode']

    fieldNames.forEach(fieldName => {
      const $field = record.querySelector(`[data-folios-field="${fieldName}"]`)

      $field.closest('li').classList.add('pointer-events-none', 'select-none')
      $field.tabIndex = -1

      // We also mark the field as "readonly," but this is only technically
      // effective for text-based inputs (not selects, radio buttons, etc.).
      // Ultimately, its real purpose is to allow us to find the first
      // "interactive" input within the `focusFirstFieldInRow` method.
      $field.setAttribute('readonly', 'readonly')
    })
  }

  handleSelection (tomSelectSelectEvent) {
    const $option = tomSelectSelectEvent.target.selectedOptions[0]
    // const selectedOption = tomSelectSelectEvent.currentTarget

    this.addNewRecord($option) // Add a row to the account table
    this.createSumission($option)
    this.clearComboBox()        // Reset the select to be empty

    event.preventDefault()
  }

  // Make ajax request to submission endpoint and create submission
  createSumission ($option) {
    if (!$option) { return }

    const url = '/submissions'
    const submissionContainerId = document.querySelector('#submission-container-id').value
    const body = JSON.stringify({
      form_type: 'NewBusinessSubmission',
      submission_container_id: submissionContainerId,
      account_name: $option.dataset.accountName,
      account_number: $option.dataset.accountNumber,
      manager_rep_code: $option.dataset.repCode,
    })

    fetch(url, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
    })
      .then(response => response.json())
      .then(data => {
        this.addIDtoRecord(data)
      })
      .catch()
  }

  deleteSubmission (event) {
    const submissionId = util.findUUIDs(event.detail[0].id)[0]
    const url = `/submissions/${submissionId}`

    fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
    })
      .then(response => response.json())
      .then(data => {
        this.removeRow(data)
      })
      .catch(() => {})
  }

  addIDtoRecord (data) {
    const submission = data.submission
    const reviewSession = data.review_session

    const record = document.querySelector('#record-submission_')
    const reviewSessionInput = record.querySelector('#review_session_id')

    // get the name from the review session
    const reviewSessionName = reviewSessionInput.name
    reviewSessionInput.value = reviewSession.id

    // get the digits from the name string
    const reviewSessionIdDigits = reviewSessionName.match(/\d+/g).map(Number)[0]

    // Build a hidden inputs
    const submissionIdInput = document.createElement('input')

    // set the name and value
    submissionIdInput.name = `submission_container[submissions_attributes][${reviewSessionIdDigits}][id]`
    submissionIdInput.value = submission.id

    // set the type to hidden
    submissionIdInput.type = 'hidden'

    // put the input on the page
    record.appendChild(submissionIdInput)

    record.id = `record-submission_${submission.id}`
  }

  addNewRecord ($option) {
    if ($option) {
      // Get data from selected option's hidden data attributes
      const data = this.getAccountDataFromOption($option)

      // Determine if the new row is for a new or existing account
      const newAccount = data.accountNumber === undefined

      // Trigger a click on the Cocoon "Add row" link
      this.$addLink.click()

      const $record = Array.from(this.$container.querySelectorAll('.new-business-record')).at(-1)

      const $remove = $record.querySelector('[data-nested-records-control="remove"]')

      if ($remove) {
        $remove.addEventListener('click', this.reviveAccount.bind(this))
      }

      // Set values for hidden inputs on the form
      this.setSubType(data, $record)
      this.setIsTaxable(data, $record)

      // Disable or Enable DOL field based on 'isTaxable' data
      this.handleDOLField(data, $record)
      this.handle401kField(data, $record)

      // If this row is for a new account, focus the first input
      // Otherwise, populate the row with the account data
      if (newAccount) {
        // this.focusFirstFieldInRow($record)
        // this.removeRepCodeSelectionRestriction($record)
        this.setNewAccountHiddenField($record)
      } else {
        this.populateRecord($record, data)
        // this.focusFirstFieldInRow($record)
      }

      // Disable the $option element UNLESS it's the "New Account" option
      if ($option.value !== 'New 401(k) Account') {
        this.$comboBox.tomselect.options[$option.value].disabled = true
        this.$comboBox.tomselect.options[$option.value].$div.ariaDisabled = true
        this.$comboBox.tomselect.options[$option.value].$div.removeAttribute('data-selectable')
      }
    }
  }

  getAccountDataFromOption ($option) {
    // Grab data attributes from the `<option>` associated with this
    // Select2 event. NOTE: We set these data attributes within the
    // `options_for_folio_select` submission helper method.
    if (!$option) { return }

    const {
      accountName,
      accountNumber,
      advisorName,
      repCode,
      subType,
      isTaxable,
    } = $option?.dataset

    return { accountName, accountNumber, advisorName, repCode, subType, isTaxable }
  }

  setSubType (data, row) {
    const subTypeField = row.querySelector('[data-field="subType"]')

    subTypeField.value = data.subType
  }

  setIsTaxable (data, row) {
    const isTaxableField = row.querySelector('[data-field="isTaxable"]')

    isTaxableField.value = data.isTaxable
  }

  handleDOLField (data, row) {
    var dolField = row.querySelector('[data-folios-field="dol"]')

    if (data.isTaxable === 'true') {
      dolField.classList.add('bg-gray-100', 'cursor-not-allowed')
      dolField.disabled = 'true'
    }
  }

  populateRecord ($row, data) {
    // Set the fields to populate.
    //
    // NOTE: These array items are derived from the values that we manually set
    // for each field within the `business_submissions/_table_row.html.erb`
    // partial. (Look for instances of `"data-folios-field"` within that file.)
    //
    // IMPORTANT: These values **MUST** match the data attributes within the
    // `options_for_folio_select` submission helper method.
    const fieldNames = Object.keys(data)

    fieldNames.forEach(fieldName => {
      const $field = $row.querySelector(`[data-folios-field="${fieldName}"]`)

      if ($field === null) return

      // Set the field's value
      $field.value = data[fieldName]

      // Make the field readonly by applying classes to the containing `<td>`
      // element; these classes prevent mouse interaction and text selection.
      // We also disable tabbing into the field. These two actions effectively
      // remove mouse/keyboard interaction while keeping the fields enabled.
      // This is important because we want to "disable" interaction, but
      // setting the `disabled` attribute on the field would prevent it from
      // being included in the form submission.

      $field.closest('li').classList.add('pointer-events-none', 'select-none')
      $field.tabIndex = -1

      // We also mark the field as "readonly," but this is only technically
      // effective for text-based inputs (not selects, radio buttons, etc.).
      // Ultimately, its real purpose is to allow us to find the first
      // "interactive" input within the `focusFirstFieldInRow` method.
      $field.setAttribute('readonly', 'readonly')
    })
  }

  reviveAccount (value) {
    const accountNumber = value.currentTarget.parentElement.parentElement.children[1].children[0].querySelector('input').value

    if (accountNumber === '') {
      return
    }

    this.$comboBox.tomselect.options[accountNumber].disabled = false

    if (this.$comboBox.tomselect.options[accountNumber].$div) {
      this.$comboBox.tomselect.options[accountNumber].$div.ariaDisabled = false
      this.$comboBox.tomselect.options[accountNumber].$div.setAttribute('data-selectable', true)
    }
  }

  clearComboBox () {
    var value = this.$comboBox.value
    if (value === '') { return }

    this.$comboBox.tomselect.clear()
  }

  handle401kField (data, row) {
    if (data.accountName) { return }

    const modelSelectInput = row.querySelector('[data-folios-field="model"]')

    modelSelectInput.add(new Option('401(k)'))
    modelSelectInput.value = '401(k)'
    modelSelectInput.addEventListener('mousedown', (event) => { event.preventDefault() })
    modelSelectInput.setAttribute('tabindex', '-1')
    modelSelectInput.classList.add('bg-gray-50', 'cursor-not-allowed')
  }

  setNewAccountHiddenField (row) {
    row.querySelector('[data-field="hiddenNewAccount"]').value = 'true'
  }
}

export default NewBusiness
