import util from './util'
/** * Priority Stream Management - A class to assist with javascript sprinkles
*/
class PriorityStreamManagement {
  stream (render, streamElement, fragment) {
    const priorityID = util.findUUIDs(fragment.id)[0]
    const header = document.querySelector(`#header-${priorityID}`)

    if (!header) { return }

    const newBusinessHeader = header.closest('.new-business-record')

    if (fragment.dataset.priorityState === 'true') {
      newBusinessHeader._x_dataStack[0].priority = true
    } else {
      newBusinessHeader._x_dataStack[0].priority = false
    }

    render(streamElement)
  }
}

export default PriorityStreamManagement
