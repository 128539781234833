import MultiSelect from './multi-select'

class MultiAttachment extends MultiSelect {
  constructor (el) {
    super(el)

    this.containers = document.querySelectorAll('.attachments-container')
    this.hiddenPrimaryField = el.querySelector('.hidden-primary-field')
    this.bulkDeleteButton = el.querySelector('#resource-delete')
    this.indivdualResourceRemovalButton = Array.from(el.querySelectorAll('.icon-container'))
    this.attachedFiles = Array.from(document.querySelectorAll('.file-container'))
  }

  init () {
    this.containerVisibility()

    this.determineCheckboxStatus('attachment')
    this.hoverMenuHandler()
    this.initEventListeners()
    this.changeBackgroundColor()
  }

  containerVisibility () {
    this.containers.forEach((container) => {
      const attachments = Array.from(container.querySelectorAll('.file-container'))
      const actionBar = container.parentElement.querySelector('#action-bar')

      if (attachments.length > 0) {
        container.classList.remove('hidden')
        actionBar.classList.remove('hidden')
      } else {
        container.classList.add('hidden')
        actionBar.classList.add('hidden')
      }
    })
  }

  removeTopBorder () {
    this.containers.forEach((container) => {
      const attachments = Array.from(container.querySelectorAll('.file-container'))
      const lastAttachment = attachments[attachments.length - 1]

      if (attachments.length > 0) {
        lastAttachment.classList.add('border-t-0')
      }
    })
  }

  initEventListeners () {
    this.multiSelectIconHandler('attachment')

    const bulkDeleteButton = this.el.querySelector('#resource-delete')

    if (!bulkDeleteButton) return

    bulkDeleteButton.addEventListener('click', () => {
      this.softDeleteResources()
    })

    const indivdualResourceRemovalButton = Array.from(this.el.querySelectorAll('.icon-container'))

    indivdualResourceRemovalButton.forEach((icon) => {
      icon.addEventListener('click', (e) => {
        this.softDeleteResource(e)
      })
    })
  }

  urlManager (type = 'remove', removedId = null, element) {
    const container = element.closest('.multi-select-attachment-container')
    const checkboxesArr = Array.from(container.querySelectorAll('input[type="checkbox"]'))
    const attachmentIds = []

    checkboxesArr.forEach((checkbox) => {
      const attachmentId = checkbox.closest('.file-container').dataset.attachmentId

      if (type === 'remove') {
        if (attachmentId === removedId) return

        attachmentIds.push(attachmentId)
      } else {
        attachmentIds.push(attachmentId)
      }
    })

    const ids = encodeURIComponent(JSON.stringify(attachmentIds))
    const myLink = container.querySelector('#resource-download')

    myLink.setAttribute('href', '/attachments?ids=' + ids)
  }

  hoverMenuHandler () {
    this.attachedFiles.forEach(attachment => {
      const hoverMenu = attachment.querySelector('.hover-menu')

      attachment.addEventListener('mouseenter', () => {
        hoverMenu.classList.remove('hidden')
      })

      attachment.addEventListener('mouseleave', () => {
        hoverMenu.classList.add('hidden')
      })
    })
  }

  handleAttachment (row, val, icon, arr) {
    const trashIcon = row.querySelector('.trash-icon')

    arr.forEach((item) => {
      item.classList[val]('line-through', 'text-gray-400')
    })

    row.classList[val]('removed')
    trashIcon.innerHTML = icon
  }

  softDeleteResources () {
    const checkedCheckboxes = this.checkboxes.filter(box => box.checked)

    checkedCheckboxes.forEach((checkbox) => {
      this.softDeleteResource(checkbox)
    })
  }

  softDeleteResource (element) {
    const row = this.getResourceRow(element)

    const hiddenPrimaryField = row.querySelector('.hidden-primary-field')
    const arr = [
      row.querySelector('.file-name'),
      row.querySelector('.file-size'),
      row.querySelector('.uploaded-at'),
    ]

    if (!row.classList.contains('removed')) {
      this.handleAttachment(row, 'add', this.__getUndoSVG(), arr)
      hiddenPrimaryField.value = 'false'
    } else {
      this.handleAttachment(row, 'remove', this.__getCloseSVG(), arr)
      hiddenPrimaryField.value = 'true'
    }
  }

  getResourceRow (element) {
    if (element.target) { element = element.target }

    return element.closest('.file-container')
  }

  changeBackgroundColor () {
    const checkboxes = Array.from(document.querySelectorAll('#attachment-checkbox'))

    checkboxes.forEach(checkbox => {
      checkbox.addEventListener('click', (e) => {
        const attachment = e.target.closest('.file-container')

        attachment.classList.toggle('animate-attachment')
      })
    })
  }

  __getCloseSVG () {
    return `
      <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.6668 14.0168C4.31107 14.0168 3.99987 13.8861 3.7332 13.6248C3.46654 13.3637 3.3332 13.0498 3.3332 12.6832V3.71675H3.0832C2.92774 3.71675 2.7972 3.66115 2.6916 3.54995C2.586 3.43875 2.5332 3.30542 2.5332 3.14995C2.5332 2.99449 2.586 2.86115 2.6916 2.74995C2.7972 2.63875 2.92774 2.58315 3.0832 2.58315H5.8832C5.8832 2.41649 5.9472 2.27489 6.0752 2.15835C6.20294 2.04155 6.3556 1.98315 6.5332 1.98315H9.4832C9.66107 1.98315 9.81667 2.03875 9.95 2.14995C10.0833 2.26115 10.15 2.40555 10.15 2.58315H12.9332C13.0889 2.58315 13.2223 2.63875 13.3332 2.74995C13.4444 2.86115 13.5 2.99449 13.5 3.14995C13.5 3.30542 13.4444 3.43875 13.3332 3.54995C13.2223 3.66115 13.0889 3.71675 12.9332 3.71675H12.6832V12.6832C12.6832 13.0498 12.5527 13.3637 12.2916 13.6248C12.0305 13.8861 11.7167 14.0168 11.35 14.0168H4.6668ZM4.4668 3.71675V12.6832C4.4668 12.7498 4.48614 12.8026 4.5248 12.8416C4.56374 12.8805 4.61107 12.9 4.6668 12.9H11.35C11.4055 12.9 11.4527 12.8805 11.4916 12.8416C11.5305 12.8026 11.55 12.7498 11.55 12.6832V3.71675H4.4668ZM6.1 10.9332C6.1 11.0889 6.1556 11.2222 6.2668 11.3332C6.37774 11.4444 6.50547 11.5 6.65 11.5C6.80547 11.5 6.9388 11.4444 7.05 11.3332C7.1612 11.2222 7.2168 11.0889 7.2168 10.9332V5.64995C7.2168 5.50542 7.1612 5.37489 7.05 5.25835C6.9388 5.14155 6.80547 5.08315 6.65 5.08315C6.49454 5.08315 6.364 5.14155 6.2584 5.25835C6.1528 5.37489 6.1 5.50542 6.1 5.64995V10.9332ZM8.8168 10.9332C8.8168 11.0889 8.8696 11.2222 8.9752 11.3332C9.08054 11.4444 9.21107 11.5 9.3668 11.5C9.52227 11.5 9.6556 11.4444 9.7668 11.3332C9.87774 11.2222 9.9332 11.0889 9.9332 10.9332V5.64995C9.9332 5.50542 9.87774 5.37489 9.7668 5.25835C9.6556 5.14155 9.52227 5.08315 9.3668 5.08315C9.21107 5.08315 9.08054 5.14155 8.9752 5.25835C8.8696 5.37489 8.8168 5.50542 8.8168 5.64995V10.9332ZM4.4668 3.71675V12.6832C4.4668 12.7498 4.48614 12.8026 4.5248 12.8416C4.56374 12.8805 4.61107 12.9 4.6668 12.9H4.4668V3.71675Z" />
      </svg>
    `
  }

  __getUndoSVG () {
    return `
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.00018 3.33333V1.47333C8.00018 1.17333 7.64018 1.02667 7.43351 1.24L4.90018 3.76667C4.76685 3.9 4.76685 4.10667 4.90018 4.24L7.42685 6.76667C7.64018 6.97333 8.00018 6.82667 8.00018 6.52667V4.66667C10.4868 4.66667 12.4535 6.94667 11.9068 9.52667C11.5935 11.04 10.3668 12.26 8.86018 12.5733C6.48018 13.0733 4.36018 11.44 4.04018 9.23333C3.99351 8.91333 3.71351 8.66667 3.38685 8.66667C2.98685 8.66667 2.66685 9.02 2.72018 9.42C3.13351 12.3467 5.92018 14.5133 9.07351 13.9C11.1535 13.4933 12.8268 11.82 13.2335 9.74C13.8935 6.32 11.2935 3.33333 8.00018 3.33333Z" fill="#5F5858"/>
      </svg>
    `
  }
}

export default MultiAttachment
