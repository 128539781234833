import { Controller } from '@hotwired/stimulus'

export default class ColorPaletteController extends Controller<HTMLElement> {
  static targets = ['input']

  declare readonly inputTargets: HTMLInputElement[]

  connect() {
    this.inputTargets.forEach((input) => {
      input.addEventListener('input', this.#updateColor.bind(this))
    })
  }

  disconnect() {
    this.inputTargets.forEach((input) => {
      input.removeEventListener('input', this.#updateColor.bind(this))
    })
  }

  // Private methods

  #updateColor(event: Event) {
    const input = event.target as HTMLInputElement
    const color = input.value
    const type = input.name.match('primary') ? 'primary' : 'secondary'

    this.element.style.setProperty(`--${type}-color`, color)
  }
}
