import Cropper from 'cropperjs'

class Croppable {
  constructor ($img) {
    this.$img = $img
  }

  init () {
    const { $img } = this

    $img.classList.add('block', 'max-w-full')
    $img.cropper = new Cropper($img, {
      aspectRatio: 1,
      autoCropArea: 1,
      initialAspectRatio: 1,
      rotatable: false,
      scalable: false,
      zoomable: false,
    })
  }
}

export default Croppable
