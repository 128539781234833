import { Controller } from '@hotwired/stimulus'

interface EventWithParams extends Event {
  params: {
    text: string
  }
}

export default class SnackBarController extends Controller<HTMLElement> {
  SHOW_DURATION = 3000
  EXIT_DURATION = 300 // Duration of SnackComponent's CSS transitions

  static targets = [
    'bar', // The container for snack items
    'snack',  // A snack item to be displayed
    'template' // The template for creating new snack items
  ]

  declare readonly barTarget: HTMLElement
  declare readonly snackTargets: HTMLElement[]
  declare readonly templateTarget: HTMLTemplateElement

  /**
   * There are two ways to trigger this action:
   * 1. Via a Stimulus action, which can be achieved by adding the following to an element:
   *     ```
   *     data-action="snack-bar#add"
   *     data-snack-bar-text-value="Hello, world!"
   *     ```
   *    If this is the case, `eventOrString` will be an Event object provided by Stimulus.
   * 2. Via the legacy JS app, which can be achieved by calling this method with a string:
   *    ```
   *    window.app.snackbar.add('Hello, world!')
   *    ```
   *   If this is the case, `eventOrString` will be a string provided by, well..., you.
   */
  add(eventOrString: EventWithParams | string) {
    const listItem = this.templateTarget.content.cloneNode(true).childNodes[0] as HTMLElement
    const innerText = typeof eventOrString === 'object'
      ? eventOrString.params.text
      : eventOrString

    listItem.innerText = innerText
    this.barTarget.appendChild(listItem)
  }

  remove(element: HTMLElement) {
    element.classList.add('opacity-0', 'scale-75')
    setTimeout(() => { element.remove() }, this.EXIT_DURATION)
  }

  removeAll() {
    this.snackTargets.forEach(element => this.remove(element))
  }

  snackTargetConnected(element: HTMLElement) {
    setTimeout(() => { this.remove(element) }, this.SHOW_DURATION)
  }
}
