import { Controller } from '@hotwired/stimulus'
import { throttle } from 'throttle-debounce'

/** @extends {Controller<HTMLElement>} */
export default class PageNavOutlineController extends Controller {
  static targets = ['anchor']

  declare contentWrapper: HTMLElement
  declare readonly anchorTargets: HTMLAnchorElement[]
  declare sectionPositions: number[]

  connect() {
    const contentWrapper = document.getElementById('content-wrapper')
    const overflowContainer = contentWrapper.querySelector(
      '& > [data-overlayscrollbars-viewport]'
    )

    // The `overlayscrollbars` library injects a scrollable div inside of the
    // content wrapper on Windows machines. If that div exists, use it as the
    // content wrapper instead of the content wrapper itself.
    if (overflowContainer) {
      overflowContainer.classList.add('scroll-smooth')
      this.contentWrapper = overflowContainer as HTMLElement
    } else {
      this.contentWrapper = contentWrapper
    }

    this.#setSectionPositions()

    window.addEventListener('resize', this.#setSectionPositions.bind(this))
    document.addEventListener('turbo:load', this.#setSectionPositions.bind(this))
    this.contentWrapper?.addEventListener('scroll', this.#handleWrapperScroll)
  }

  disconnect() {
    window.removeEventListener('resize', this.#setSectionPositions.bind(this))
    document.removeEventListener('turbo:load', this.#setSectionPositions.bind(this))
    this.contentWrapper?.removeEventListener('scroll', this.#handleWrapperScroll)
  }

  // Private methods

  /** Returns the HTML element that is linked to from a given anchor element */
  #getReferencedElem(anchor: HTMLAnchorElement) {
    const selector = anchor.getAttribute('href')
    const elem = document.querySelector(selector) satisfies HTMLElement

    if (!elem) {
      throw new Error(`PageNavOutlineController: no element matches selector: "${selector}"`)
    }

    return elem
  }

  #handleWrapperScroll = throttle(100, function(event: Event) {
    const { scrollTop } = event.target as HTMLElement

    this.sectionPositions.forEach((sectionPosition: number, index: number) => {
      const nextSectionPosition = this.sectionPositions[index + 1] || Infinity

      if (scrollTop >= sectionPosition && scrollTop < nextSectionPosition) {
        this.anchorTargets[index].setAttribute('aria-current', 'true')
      } else {
        this.anchorTargets[index].removeAttribute('aria-current')
      }
    })
  }.bind(this))

  /**
   * Loop through each of the page nav links, find the elements
   * that they link to, and store their offsets in an array. ("Offset"
   * means "the number of pixels from the top of the page" here.)
   */
  #setSectionPositions() {
    const sectionPositions: number[] = []

    this.anchorTargets.map((anchor) => {
      const elem = this.#getReferencedElem(anchor)

      if (!elem) return

      // Determine if the element has a scroll margin set and, if so,
      // subtract it from the element's offsetTop value.
      const elemStyle = getComputedStyle(elem)
      let position = elem.offsetTop

      if (elemStyle.scrollMarginTop) {
        position -= parseInt(elemStyle.scrollMarginTop)
      }

      sectionPositions.push(position)
    })

    this.sectionPositions = sectionPositions
  }
}
