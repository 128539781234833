import { Controller } from '@hotwired/stimulus'

export interface AttachEvent extends Event {
  detail: {
    files: FileList
  }
}

export default class DropzoneController extends Controller<HTMLElement> {
  declare inputTarget: HTMLInputElement
  declare modeValue: 'ghost' | 'normal'
  declare multipleValue: boolean

  static targets = ['input']

  static values = {
    mode: String,
    multiple: Boolean,
  }

  connect() {
    this.#prepareDocument()
    this.element.addEventListener('dragenter', this.#handleDragEnter.bind(this))
    this.element.addEventListener('dragleave', this.#handleDragLeave.bind(this))
    this.element.addEventListener('dragover', this.#handleDragOver.bind(this))
    this.element.addEventListener('drop', this.#handleDrop.bind(this))
    this.inputTarget.addEventListener('change', this.#handleInput.bind(this))
  }

  disconnect() {
    this.#restoreDocument()
    this.element.removeEventListener('dragenter', this.#handleDragEnter.bind(this))
    this.element.removeEventListener('dragleave', this.#handleDragLeave.bind(this))
    this.element.removeEventListener('dragover', this.#handleDragOver.bind(this))
    this.element.removeEventListener('drop', this.#handleDrop.bind(this))
    this.inputTarget.removeEventListener('change', this.#handleInput.bind(this))
  }

  // Private methods

  #attachFiles(files: FileList) {
    this.dispatch('attach', { detail: { files } })
  }

  #handleDocumentDragEnter(event: DragEvent) {
    event.preventDefault() // Required to allow drop
    document.documentElement.classList.add('dragging')
  }

  #handleDocumentDragLeave(event: DragEvent) {
    if (event.relatedTarget) return

    this.element.classList.remove('dragover')
    document.documentElement.classList.remove('dragging')
  }

  #handleDragEnter(event: DragEvent) {
    const items = Array.from(event.dataTransfer.items)
    const files = items.filter((item) => item.kind === 'file')
    const noFiles = files.length === 0
    const tooManyFiles = !this.multipleValue && files.length > 1

    if (noFiles || tooManyFiles) {
      this.element.classList.add('dragover-invalid')
    } else {
      this.element.classList.add('dragover')
    }
  }

  #handleDragLeave(event: DragEvent) {
    const draggingOnZoneElem = event.relatedTarget === this.element
    const draggingInZoneElem = this.element.contains(event.relatedTarget as Node)

    if (draggingOnZoneElem || draggingInZoneElem) return

    this.element.classList.remove('dragover', 'dragover-invalid')
  }

  #handleDragOver(event: DragEvent) {
    event.preventDefault() // Required to allow drop
  }

  #handleDrop(event: DragEvent) {
    event.preventDefault() // Required to handle drop
    document.documentElement.classList.remove('dragging')
    this.element.classList.remove('dragover')

    const { files } = event.dataTransfer
    const hasFiles = files?.length > 0
    const hasTooManyFiles = !this.multipleValue && files?.length > 1

    if (hasFiles && !hasTooManyFiles) {
      this.#attachFiles(files)
    }
  }

  #handleInput(event: Event) {
    const files = (event.target as HTMLInputElement).files

    if (files && files.length > 0) {
      this.#attachFiles(files)
    }
  }

  // This method adds event listeners to the `<html>` element so that we can
  // add a `.dragging` class to it whenever the user is dragging a file into
  // the viewport. This allows us to provide hints to the user as to which areas
  // of the page are valid dropzones.
  #prepareDocument() {
    const $html = document.documentElement

    if (!('dropzone' in $html.dataset)) {
      $html.addEventListener('dragenter', this.#handleDocumentDragEnter.bind(this))
      $html.addEventListener('dragleave', this.#handleDocumentDragLeave.bind(this))
      $html.dataset.dropzone = 'connected'
    }
  }

  // Remove all of the event listeners that we added in `#prepareDocument`, but
  // only if there are no other dropzones on the page.
  #restoreDocument() {
    const $html = document.documentElement

    if (
      'dropzone' in $html.dataset &&
      document.querySelectorAll('[data-dropzone]').length === 1
    ) {
      $html.removeEventListener('dragenter', this.#handleDocumentDragEnter.bind(this))
      $html.removeEventListener('dragleave', this.#handleDocumentDragLeave.bind(this))
      delete $html.dataset.dropzone
    }
  }
}