import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'

type TomInput = TomSelect['input'] & HTMLSelectElement

export default class ComboBoxController extends Controller<TomInput> {
  connect() {
    // NOTE: We shouldn't have to check if the element already has TomSelect
    // initialized. See "FIXME" in the disconnect method for more info.
    if (this.element.disabled || this.element.tomselect) { return }

    if ('app' in window) {
      this.#initialize()
    } else {
      document.addEventListener('DOMContentLoaded', this.#initialize.bind(this))
    }
  }

  disconnect() {
    document.removeEventListener('DOMContentLoaded', this.#initialize.bind(this))

    // FIXME: We should be able to safely destroy the TomSelect instance here,
    // but we have a lot of JS class/Alpine code that relies on the TomSelect
    // instance being available after the element is disconnected, which means
    // we've got a memory leak. Not the best.
    // this.element.tomselect?.destroy()
  }

  // Private methods

  #initialize() {
    const settings = new window.app.buildSettings().run(this.element)
    new TomSelect(this.element, settings)
    this.#makeContextSpecificAdjustments()
  }

  #makeContextSpecificAdjustments() {
    const { dataset: { onSelectEvent }, name, tomselect } = this.element

    if (['select_account', 'select_compliance_form'].includes(name)) {
      tomselect.wrapper.classList.add('px-0')
      tomselect.control.classList.add('account-select', 'dropdown-type-input')
    }

    // Custom select events for using '$(watch)'-like operations
    // Currenty this is only for the resource item form.
    if (onSelectEvent) {
      tomselect.control.classList.add('dropdown-type-input')
      tomselect.on('item_add', function (value, item) {
        new window.app.contentType(item).init()
      })
    }
  }
}
