import { Controller } from '@hotwired/stimulus'
import { getCsrfToken } from '../../utils'
import { getTarget } from '../utils'

interface EventWithParams extends Event {
  params: {
    method: string
    url: string
    body?: string
  }
}

export default class FetchController extends Controller<HTMLElement> {
  req(event: EventWithParams) {
    const target = getTarget(event)

    fetch(event.params.url, this.#buildOptions(event))
      .then((response) => this.#handleSuccess(target, response))
      .catch((error) => this.#handleError(target, error))
  }

  // Private methods

  #buildOptions(event: EventWithParams) {
    const { body, method } = event.params
    const options = {
      body,
      headers: { 'X-CSRF-Token': getCsrfToken() },
      method,
    } satisfies RequestInit

    return options
  }

  #handleError(target: HTMLElement, error: Error) {
    this.dispatch('error', { detail: { error }, target })
  }

  #handleSuccess(target: HTMLElement, response: Response) {
    this.dispatch('success', { detail: { response }, target })
  }
}
