import ClipBoard from 'clipboard'
import axios from 'axios'

const util = {
  clipboard: ClipBoard,

  locale: navigator.language,

  formatBytes (bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  },

  getCookie (name) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
  },

  formatCurrency (amount) {
    const options = { style: 'currency', currency: 'USD' }

    return new Intl.NumberFormat('en-US', options).format(amount)
  },

  setCSRFToken () {
    const token = document.querySelector('[name=csrf-token]').content

    axios.defaults.headers.common['X-CSRF-TOKEN'] = token
  },

  findUUIDs (string) {
    // Regular expression for matching UUIDs
    const uuidPattern = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/ig

    // Use the `match` method to find all matches in the input string
    const uuids = string.match(uuidPattern)

    return uuids || []
  },

  // Display a given ISO 8601 DateTime string in the user's timezone
  formatDate (isoDate) {
    const date = new Date(isoDate)
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short',
    }

    return date.toLocaleString(this.locale, options)
  },

  formatDateFull (isoDate) {
    const date = new Date(isoDate)
    const options = { year: 'numeric', month: 'long', day: 'numeric' }

    return date.toLocaleDateString(this.locale, options)
  },

  // Allow a file
  previewImageField ($input) {
    const { files } = $input
    const imageMimeTypes = [
      'image/gif',
      'image/jpeg',
      'image/png',
      'image/svg',
      'image/webp',
    ]

    if (files && files[0] && imageMimeTypes.includes(files[0].type)) {
      const reader = new FileReader()

      reader.onload = (event) => {
        $input.dispatchEvent(new CustomEvent('util:preview-image-field', {
          bubbles: true,
          detail: { imageAsDataURL: event.target.result },
        }))
      }

      reader.readAsDataURL(files[0]) // convert to base64 string
    }
  },

  triggerEvent (el, type) {
    let event
    // IE9+ and other modern browsers
    if ('createEvent' in document) {
      event = document.createEvent('HTMLEvents')
      event.initEvent(type, false, true)
      el.dispatchEvent(event)
    } else {
      // IE8
      event = document.createEventObject()
      event.eventType = type
      el.fireEvent('on' + event.eventType, event)
    }
  },

  onVisible (element, callback, threshold = 0) {
    new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio >= threshold) {
          callback(element)
          observer.disconnect()
        }
      })
    }, { threshold: threshold }).observe(element)
  },

  /** Function that count occurrences of a substring in a string;
   * @param {String} string               The string
   * @param {String} subString            The sub string to search for
   * @param {Boolean} [allowOverlapping]  Optional. (Default:false)
   *
   * @author Vitim.us https://gist.github.com/victornpb/7736865
   * @see Unit Test https://jsfiddle.net/Victornpb/5axuh96u/
   * @see https://stackoverflow.com/a/7924240/938822
   */
  occurrences (string, subString, allowOverlapping) {
    string += ''
    subString += ''
    if (subString.length <= 0) return (string.length + 1)

    var n = 0
    var pos = 0
    var step = allowOverlapping ? 1 : subString.length

    while (true) {
      pos = string.indexOf(subString, pos)
      if (pos >= 0) {
        ++n
        pos += step
      } else break
    }
    return n
  },

  overlayEventsHandler () {
    return {
      populated: new CustomEvent('overlay:populated', { bubbles: true }),
      open: new CustomEvent('overlay:open', { bubbles: true }),
      opened: new CustomEvent('overlay:opened', { bubbles: true }),
      close: new CustomEvent('overlay:close', { bubbles: true }),
      closed: new CustomEvent('overlay:closed', { bubbles: true }),
    }
  },

  scrollToElement (element) {
    const el = element.closest('#activities-container').querySelector('#finfolio-container').querySelector('#trix-select')

    el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
  },
}

export default util
