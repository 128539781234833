import { Controller } from '@hotwired/stimulus'
import { capitalCase } from 'change-case'

interface Identity {
  id: string
  last_used_at?: string
  name: string
  role: string
}

const NAVBAR_IDENTITIES_ENDPOINT = '/identities/navbar_identities'

export default class IdentitySwitcherController extends Controller<HTMLFormElement> {
  static targets = [
    'fetchErrorTemplate',
    'loadingTemplate',
    'noResultsTemplate',
    'results',
    'resultTemplate',
    'searchInput',
    'submitButton'
  ]

  declare readonly fetchErrorTemplateTarget: HTMLTemplateElement
  declare readonly loadingTemplateTarget: HTMLTemplateElement
  declare readonly noResultsTemplateTarget: HTMLTemplateElement
  declare readonly resultsTarget: HTMLDivElement
  declare readonly resultTemplateTarget: HTMLTemplateElement
  declare readonly searchInputTarget: HTMLInputElement
  declare readonly submitButtonTarget: HTMLButtonElement

  declare allIdentities: Identity[]
  declare filteredIdentities: Identity[]

  connect() {
    this.allIdentities = []
    this.filteredIdentities = []
    this.submitButtonTarget.hidden = true

    this.#displayLoadingState()
    this.searchInputTarget.addEventListener('input', this.#handleSearch.bind(this))
    this.searchInputTarget.addEventListener('keyup', this.#handleSearchKeyup.bind(this))
  }

  disconnect() {
    this.searchInputTarget.removeEventListener('input', this.#handleSearch.bind(this))
    this.searchInputTarget.removeEventListener('keyup', this.#handleSearchKeyup.bind(this))
  }

  // Public methods

  fetchIdentities() {
    if (this.allIdentities.length > 0) return this.#enableUserInteraction()

    this.#displayLoadingState()

    fetch(NAVBAR_IDENTITIES_ENDPOINT)
      .then<Identity[]>(res => res.json())
      .then(this.#transformIdentities)
      .then(data => {
        this.allIdentities = data
        this.filteredIdentities = data
        this.#displayResults()
      })
      .catch(this.#displayFetchError.bind(this))
  }

  /**
   * The 'click' event fires when the user clicks via the mouse, but also when
   * they navigate the list of results using the keyboard. We only want to
   * submit the form when the user clicks with the mouse.
   */
  handleResultClick(event: Event) {
    const clickedViaMouse = 'screenX' in event && (event.screenX as number) > 0

    if (clickedViaMouse) this.element.submit()
  }

  // Private methods

  #disableUserInteraction() {
    this.searchInputTarget.disabled = true
    this.submitButtonTarget.disabled = true
  }

  #displayFetchError() {
    this.resultsTarget.innerHTML = this.fetchErrorTemplateTarget.innerHTML
  }

  #displayLoadingState() {
    this.#disableUserInteraction()
    this.resultsTarget.innerHTML = this.loadingTemplateTarget.innerHTML
  }

  #displayNoResults(term: string) {
    this.resultsTarget.innerHTML = this.noResultsTemplateTarget
      .innerHTML
      .replace(/{{term}}/g, term)

    this.#enableUserInteraction()
  }

  #displayResults() {
    let html = ''

    this.filteredIdentities.forEach(identity => {
      const resultHtml = this.resultTemplateTarget
        .innerHTML
        .replace(/{{id}}/g, identity.id)
        .replace(/{{name}}/g, identity.name)
        .replace(/{{role}}/g, identity.role)

      html += resultHtml
    })

    this.resultsTarget.innerHTML = html
    this.resultsTarget.scrollTop = 0

    const firstRadioInput = this.resultsTarget.querySelector<HTMLInputElement>(
      'input[type="radio"]'
    )

    if (firstRadioInput) firstRadioInput.checked = true

    this.#enableUserInteraction()
  }

  #enableUserInteraction() {
    this.searchInputTarget.disabled = false
    this.submitButtonTarget.disabled = false
    this.searchInputTarget.focus()
  }

  #handleSearch(event: Event) {
    const term = (event.target as HTMLInputElement).value.toLowerCase()
    const words = term.split(/\s+/).filter(term => !!term)

    this.filteredIdentities = words.length === 0
      ? this.allIdentities
      : this.allIdentities.filter(({ name, role }) => words.every(term =>
          name.toLowerCase().includes(term) || role.toLowerCase().includes(term)
        ))

    if (this.filteredIdentities.length === 0) {
      this.#displayNoResults(term)
    } else {
      this.#displayResults()
    }
  }

  #handleSearchKeyup(event: KeyboardEvent) {
    const navKeys = ['ArrowDown', 'ArrowRight']

    if (!navKeys.includes(event.key)) return

    const firstRadioInput = this.resultsTarget.querySelector<HTMLInputElement>(
      'input[type="radio"]'
    )

    if (firstRadioInput) firstRadioInput.focus()
  }

  /**
   * The data we get back from the server includes role values like
   * `"super_admin"`. We need to format these values for display to the user
   * (e.g. `"Super Admin"`). Also, we can discard the `last_used_at` field.
   */
  #transformIdentities(identities: Identity[]) {
    return identities.map(({ id, name, role }) => (
      { id, name, role: capitalCase(role) }
    ))
  }
}
