import { Controller } from '@hotwired/stimulus'
import { DirectUpload } from '@rails/activestorage'
import { formatBytes } from '../utils'

export interface AttachEvent extends Event {
  detail: {
    file: File
  }
}

export interface DirectUploadProgressEvent extends Event {
  loaded: number
  total: number
}

export default class AttachmentManagerItemController extends Controller<HTMLElement> {
  declare attachInputNameValue: string
  declare detachInputNameValue: string
  declare detachedValue: boolean
  declare fileNameValue: string
  declare fileSizeValue: number
  declare indexValue: number
  declare persistedValue: boolean
  declare progressValue: number
  declare signedIdValue: string
  declare readonly attachInputTarget: HTMLInputElement
  declare readonly detachInputTarget: HTMLInputElement
  declare readonly fileNameTarget: HTMLElement
  declare readonly fileSizeTarget: HTMLElement
  declare readonly progressBarTarget: HTMLProgressElement

  static targets = [
    'attachInput',
    'detachInput',
    'fileName',
    'fileSize',
    'progressBar'
  ]

  static values = {
    attachInputName: String,
    detached: { type: Boolean, default: false },
    detachInputName: String,
    fileName: { type: String, default: 'No file selected' },
    fileSize: {type: Number, default: 0 },
    index: { type: Number, default: 0 },
    persisted: { type: Boolean, default: true },
    progress: { type: Number, default: 0 },
    signedId: { type: String, default: '' },
  }

  UPLOAD_PATH = '/rails/active_storage/direct_uploads'

  connect() {
    this.#handleFileNameChange()
    this.#handleFileSizeChange()
    this.#handleProgressBarChange()
    this.#handleSignedIdChange()
  }

  // ActiveStorage DirectUpload handlers

  directUploadWillStoreFileWithXHR(request: XMLHttpRequest) {
    request.upload.addEventListener('progress', this.#setProgress.bind(this))
  }

  // Public methods

  attach(file: File) {
    this.dispatch('before-attach', {
      detail: { file } satisfies AttachEvent['detail']
    })

    this.fileNameValue = file.name
    this.fileSizeValue = file.size
    this.progressValue = 0
    this.element.classList.remove('hidden')

    new DirectUpload(file, this.UPLOAD_PATH, this).create((error, blob) => {
      if (error) return this.#handleError(error)

      this.signedIdValue = blob.signed_id
      this.dispatch('attach', {
        detail: { file } satisfies AttachEvent['detail']
      })
    })
  }

  detach() {
    this.detachedValue = true
  }

  // Value change callbacks

  detachedValueChanged() { this.#handleDetachedChange() }
  fileNameValueChanged() { this.#handleFileNameChange() }
  fileSizeValueChanged() { this.#handleFileSizeChange() }
  progressValueChanged() { this.#handleProgressBarChange() }
  signedIdValueChanged() { this.#handleSignedIdChange() }

  // Private methods

  #handleDetachedChange() {
    // Bail early if the item is not detached
    // NOTE: We might want to let the user 'undo' the detachment in the future
    if (!this.detachedValue) return

    const isPersisted = this.persistedValue

    if (isPersisted) {
      this.element.classList.add('hidden')
      this.attachInputTarget.disabled = true
      this.detachInputTarget.disabled = false
      this.dispatch('detach')
    }

    // If the item was never persisted, we can just remove it from the DOM
    // NOTE: Dispatch the `detach` event _before_ that, though.
    else {
      this.dispatch('detach')
      this.element.remove()
    }
  }

  #handleError(error: Error) {
    this.dispatch('error')
    this.element.remove()
    alert(`Upload failed: ${error}`)
  }

  #handleFileNameChange() {
    let content = this.fileNameValue

    if (!this.persistedValue) {
      content = `${this.#unpersistedIndicator()} ${content}`
    }

    this.fileNameTarget.innerHTML = content
  }

  #handleFileSizeChange() {
    const empty = this.fileSizeValue === 0

    this.fileSizeTarget.classList[empty ? 'add' : 'remove']('hidden')
    this.fileSizeTarget.innerText = formatBytes(this.fileSizeValue)
  }

  #handleProgressBarChange() {
    const unstarted = this.progressValue === 0
    const finished = this.progressValue === this.progressBarTarget.max
    const inactive = unstarted || finished
    const notPersisted = !this.persistedValue

    if (notPersisted && unstarted) this.dispatch('upload-started')

    this.progressBarTarget.classList[inactive ? 'add' : 'remove']('hidden')
    this.progressBarTarget.innerText = finished
      ? 'Upload Complete'
      : `${this.progressValue}% Uploaded`
    this.progressBarTarget.value = this.progressValue

    if (notPersisted && finished) this.dispatch('upload-finished')
  }

  #handleSignedIdChange() {
    const disabled = this.signedIdValue.length === 0

    this.attachInputTarget.disabled = disabled
    this.attachInputTarget.value = this.signedIdValue
  }

  #setProgress(event: DirectUploadProgressEvent) {
    this.progressValue = (event.loaded / event.total) * 100
  }

  #unpersistedIndicator() {
    return `<span
      aria-label="Newly Added"
      class="bg-whitelabel-500 inline-block mr-1 rounded-full size-2"
      data-controller="tooltip"
    ></span>`
  }
}
