import { Controller } from '@hotwired/stimulus'

interface DirectUploadProgressEvent extends Event {
  detail: {
    progress: number
  }
}

export default class ProgressBarController extends Controller<HTMLProgressElement> {
  update(event: DirectUploadProgressEvent) {
    this.element.value = event.detail.progress
  }
}