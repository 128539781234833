import { Application } from '@hotwired/stimulus'

declare global {
  interface Window {
    Stimulus: Application
  }
}

export const stimulus = Application.start()

// Configure Stimulus development experience
stimulus.debug = false
window.Stimulus = stimulus
