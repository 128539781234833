import StaticElements from './static-elements'

class GenerateField {
  constructor ($event, $emailValue, $otherValue, $submissionIds, $readOnly) {
    this.$event = $event
    this.$emailValue = $emailValue
    this.$otherValue = $otherValue
    this.$submissionIds = $submissionIds
    this.$readOnly = $readOnly

    if (this.$event) {
      this.$target = this.$event.target
    }
  }

  populate () {
    if (this.$target.type === 'radio' && !['yes', 'no'].includes(this.$target.value)) { return this.generateTextInput('radio') }
    if (!this.validValues().includes(this.$target.value)) { return }

    if (this.$target.type === 'radio') { return this.generateSelectInput() }

    return this.generateTextInput()
  }

  set (attribute) {
    if (this.$readOnly === 'true' && attribute === 'related_submission') { return }

    var container = document.getElementById(`${attribute}-container`)

    if (container) {
      var checkboxes = container.querySelectorAll('input[type="checkbox"]')

      Array.from(checkboxes).forEach($box => {
        if (($box.value === 'Email' && $box.checked) || ($box.value === 'Other' && $box.checked)) {
          this.generateInput($box, this.$emailValue, this.$otherValue, this.$submissionIds)
        }
      })

      var radios = container.querySelectorAll('input[type="radio"]')

      Array.from(radios).forEach($radio => {
        if (($radio.value === 'yes' || $radio.value === 'Other') && $radio.checked) {
          this.generateInput($radio, this.$emailValue, this.$otherValue, this.$submissionIds)
        }
      })
    }
  }

  generateInput (box, emailValue, otherValue, submissionIds) {
    var target = (box || this.$target)

    var type = this.getInputType(target)
    var input = this.createInput(target, emailValue, otherValue, type)

    if (input.type !== 'select-multiple') {
      if (target.type === 'checkbox') {
        target.parentElement.after(input)
      } else {
        target.parentElement.parentElement.after(input)
      }

      return
    }

    var values = JSON.parse(submissionIds)

    target.parentElement.parentElement.after(input)

    const settings = new window.app.buildSettings().run(input)
    var ts = new window.app.tomSelect(input, settings)

    input.addEventListener('change', this.handleSelection.bind(this))

    var url = '/api/submissions?onload=true'

    fetch(url)
      .then(res => res.json())
      .then(json => {
        json.forEach($value => {
          this.addOption($value, ts)
        })
      })
      .catch(() => {})

    if (!values) { return }

    values.forEach($value => {
      this.addOption($value, ts)

      ts.addItem($value.id)
    })
  }

  createInput (target, emailValue, otherValue, type) {
    if (type === 'radio' && target.value !== 'Other') { return this.createRadioInput(target) }

    return this.createChecklistInput(target, emailValue, otherValue)
  }

  generateSelectInput () {
    if (this.$target.value === 'yes') {
      this.generateInput(null, this.$emailValue, this.$otherValue, this.$submissionIds)
    } else {
      var containers = document.getElementsByClassName('removal-container')

      Array.from(containers).forEach($container => {
        var hiddenInput = document.getElementById(`${$container.id}-hidden-submission`)

        $container.remove()

        if (hiddenInput) {
          hiddenInput.remove()
        }
      })
    }
  }

  generateTextInput (type = null) {
    if (this.$target.type === 'checkbox' && !this.$target.checked) {
      var sibling = this.$target.parentElement.nextSibling
    } else {
      sibling = this.$target.parentElement.parentElement.nextSibling
    }

    if (type) {
      if (this.$target.value === 'Other' && this.$target.checked) {
        this.generateInput(null, this.$emailValue, this.$otherValue, this.$submissionIds)
      } else {
        if (!sibling) { return }

        sibling.remove()
      }
    } else {
      if (!sibling || (sibling.id !== 'Email' && sibling.id !== 'Other' && sibling.id !== 'yes')) {
        this.generateInput(null, this.$emailValue, this.$otherValue, this.$submissionIds)
      } else {
        sibling.remove()
      }
    }
  }

  createRadioInput (target) {
    var elem = document.createElement('select')

    elem.setAttribute('multiple', '')
    elem.setAttribute('id', 'submission-attachment-container')
    elem.classList.add('form-select', 'w-full', 'mt-2', 'removal-container')
    elem.setAttribute('name', target.name.slice(0, -1) + '_ids][]')

    return elem
  }

  createChecklistInput (target, emailValue, otherValue) {
    // Create label and label text
    var label = document.createElement('label')
    var paragraph = document.createElement('p')
    label.appendChild(paragraph)

    // Create input and append it to the label
    var input = document.createElement('input')
    input.setAttribute('type', 'text')
    label.appendChild(input)

    paragraph.innerText = `${target.value}:`
    label.setAttribute('id', target.value)
    label.setAttribute('for', target.value)

    if (target.type === 'checkbox') {
      input.setAttribute('name', target.name + `[${target.value}]`)
    } else {
      input.setAttribute('name', target.name.slice(0, -1) + '_other]')
    }

    input.value = (target.value === 'Email') ? emailValue : otherValue

    // Add specific styles to the input and label
    paragraph.classList.add('text-gray-400')
    input.classList.add('outline-none', 'w-full', 'pl-1')
    label.classList.add('flex', 'form-input', 'my-2', 'ml-2')

    if (this.$readOnly === 'true') {
      input.setAttribute('disabled', true)
      input.classList.add('bg-gray-50')
      label.classList.add('bg-gray-50', 'hover:border-gray-200')
    }

    return label
  }

  validValues () {
    return ['Email', 'Other', 'yes', 'no']
  }

  getInputType (target) {
    return target.type
  }

  // Handle selection area
  handleSelection (event) {
    this.addSubmissionRow(event) // Add a row to the account table
    this.removeOptionFromList(event) // Remove option
    this.clearComboBox(event)    // Reset the select to be empty

    event.preventDefault()
  }

  addSubmissionRow (event) {
    const $option = event.target.selectedOptions[0]

    if (!$option) { return }

    var correctOption = event.target.tomselect.options[$option.value]
    var name = event.target.name

    this.createSubmissionRow(correctOption, event, name)
  }

  removeOptionFromList (event) {
    var value = event.target.value

    event.target.tomselect.removeOption(value)
  }

  clearComboBox (event) {
    var value = event.target.value

    if (value === '') { return }

    event.target.tomselect.clear()
  }

  createSubmissionRow (option, event, name) {
    var container = document.createElement('div')
    var hiddenInput = this.createHiddenInput(name, option)

    event.target.closest('form').appendChild(hiddenInput)
    event.target.nextElementSibling.after(container)

    container.innerHTML = this.__getSubmissionMarkup(option)
  }

  createHiddenInput (name, option) {
    var hiddenInput = document.createElement('input')
    hiddenInput.setAttribute('type', 'hidden')
    hiddenInput.setAttribute('name', name)
    hiddenInput.setAttribute('class', 'hidden-submission')

    if (typeof option.id === 'string') {
      hiddenInput.setAttribute('value', option.id)
      hiddenInput.setAttribute('id', `${option.id}-hidden-submission`)
    } else {
      hiddenInput.setAttribute('value', option.submission_id)
      hiddenInput.setAttribute('id', `${option.submission_id}-hidden-submission`)
    }

    return hiddenInput
  }

  removeSubmission (event) {
    var containerEl = event.currentTarget.parentElement
    var linkContainerEl = containerEl.children[0].children[0]
    var id = linkContainerEl.id
    var hiddenInput = document.getElementById(`${id}-hidden-submission`)

    var ts = document.getElementById('submission-attachment-container').tomselect

    var description = document.getElementById(`${id}-description`).innerHTML
    var category = document.getElementById(`${id}-category`).innerHTML
    var userName = document.getElementById(`${id}-user-name`).innerHTML
    var createdAt = document.getElementById(`${id}-created-at`).innerHTML
    var viewable = document.getElementById(`${id}-viewable`).innerHTML
    var containerID = document.getElementById(`${id}-container-id`).innerHTML

    ts.addOption({
      id: id,
      category: category,
      description: description,
      user_name: userName,
      created_at: createdAt,
      viewable: viewable,
      container_id: containerID,
    })

    hiddenInput.remove()
    containerEl.remove()
  }

  __getSubmissionMarkup (option) {
    var staticElement = new StaticElements()

    return staticElement.submissionMarkup(option)
  }

  addOption (value, tomSelect) {
    tomSelect.addOption({
      id: value.id,
      category: value.category,
      submission_id: value.submission_id,
      description: value.description,
      user_name: value.user_name,
      created_at: value.created_at,
      form_type: value.form_type,
      viewable: value.viewable,
      container_id: value.container_id,
    })
  }
}

export default GenerateField
