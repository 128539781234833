import axios from 'axios'

class LoginFlow {
  constructor ($form) {
    this.$form = $form
    this.$email = $form.querySelector('[name="user[email]"]')
    this.$password = $form.querySelector('[name="user[password]"]')
    this.$modalTemplate = $form.querySelector('#modal-template')
    this.$otpAttempt = $form.querySelector('[name="user[otp_attempt]"]')
    this.$rememberOtp = $form.querySelector('[name="user[remember_otp]"]')
    this.$submit = $form.querySelector('[type="submit"]')

    this.rememberOtpToken = null
    this.otpAttempt = null
    this.otpRequired = null
    this.currentOtp = null
    this.rememberOtp = null
    this.validOtpToken = null
    this.validUserCredentials = null
  }

  init () {
    this.$form.addEventListener('submit', this.handleSubmit.bind(this))
    window.addEventListener('overlay:closed', this.handleModalClosed.bind(this))
    window.addEventListener('otp:attempt', this.handleOtpAttempt.bind(this))
  }

  handleModalClosed (event) {
    // Restore the form if the user has closed the OTP modal for some reason
    if (this.otpAttempt === null) this.restoreSubmit(event)
  }

  handleSubmit (event) {
    this.disableSubmit(event)

    // If we've checked for OTP and the user has submitted a code, submit
    if (this.otpRequired && this.otpAttempt) return

    // Also, if we checked for OTP and it's *not* required, submit the form
    if (this.otpRequired === false) return

    // Otherwise, prevent form submission so we can check OTP status
    event.preventDefault()

    // In order to check OTP status, we need the user's email.
    const email = this.$email.value
    const password = this.$password.value

    // If the email's blank, restore the from and focus the
    // email field. NOTE: We should never actually reach this
    // state if the browser is validating the form correctly.
    if (!email) {
      this.restoreSubmit(event)
      this.$email.focus()
      return
    }

    this.getOtpStatus(email, password)
      .then(otpRequired => {
        // If OTP isn't required, submit the form and log the user in normally
        if (this.validUserCredentials === false) {
          return this.$form.submit()
        }

        if (!otpRequired) { this.$form.submit(); return }

        // If OTP Remember token is present and valid skip user input
        if (this.skipOtpInput()) {
          this.signInWithOtp()

          return
        }

        this.showOtpModal()
      })
      .catch(error => error)
  }

  signInWithOtp () {
    this.$otpAttempt.disabled = false
    this.$otpAttempt.value = this.currentOtp

    this.$form.submit()
  }

  skipOtpInput () {
    const validCookie = app.util.getCookie('remember_otp_token') === this.rememberOtpToken
    const validTimestamp = this.validOtpToken

    return (validCookie && validTimestamp)
  }

  handleOtpAttempt (event) {
    this.otpAttempt = document.querySelector('input[name=otp_attempt]').defaultValue
    this.rememberOtp = event.detail.rememberOtp

    this.$otpAttempt.disabled = false
    this.$otpAttempt.value = document.querySelector('input[name=otp_attempt]').defaultValue

    this.$rememberOtp.disabled = false
    this.$rememberOtp.value = event.detail.rememberOtp

    window.app.modal.close()

    this.$form.submit()
  }

  getOtpStatus (email, password) {
    return axios.get('/users/require_otp', { params: { email, password } })
      .then(response => {
        this.validUserCredentials = response.data.valid_user_credentials
        this.otpRequired = response.data.otp_required_for_login
        this.currentOtp = response.data.current_otp
        this.rememberOtpToken = response.data.remember_otp_token
        this.validOtpToken = response.data.remember_otp_valid

        return response.data.otp_required_for_login
      })
      .catch(error => error)
  }

  disableSubmit (event) {
    // Prevent Rails UJS from hijacking the form
    event.stopPropagation()

    this.$submit.innerHTML = 'Signing In…'
    this.$submit.disabled = true
  }

  restoreSubmit (event) {
    // Prevent the form from submitting
    if (event.type === 'submit') event.preventDefault()

    this.$submit.innerHTML = 'Sign In'
    this.$submit.disabled = false
  }

  showOtpModal () {
    const html = this.$modalTemplate.innerHTML

    window.app.modal.setSize('xs').then(() => {
      window.app.modal.open(html)
    })
  }
}

export default LoginFlow
