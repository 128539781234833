/** * Submission View Management - A class to assist with javascript sprinkles
 * during submission view flows.
 *
 * This class will handle auto-selecting manager rep codes based on account number
 * selections from the user.
 *
 * Considerations for "removeOutdatedManager" and "managerRepCode" --
 * 1) accountNumber and clientName will auto-select the 'supervised person name' field based on rep code.
 * 2) Selection of rep code will remove the account number & client name selection if rep code does not match.
 */
class SubmissionViewManagement {
  removeOutdatedManager (repCode) {
    if (!repCode) { return }

    const dropdown = document.querySelector('[name="submission_container[submissions_attributes][0][manager_rep_code]')

    if (!dropdown) { return }

    if (dropdown.value !== 'Private') {
      if (repCode.split(' ').includes(dropdown.value)) { return }
    }

    const selectedDropdown = dropdown.tomselect.options[repCode.split(' ')[0]]
    dropdown.tomselect.setValue(selectedDropdown.value)
  }

  removeOutdatedData (value) {
    const fields = ['account_number', 'client_name']

    fields.forEach((field) => {
      const dropdown = document.querySelector(`[name="submission_container[submissions_attributes][0][form_fields][${field}]"]`)

      if (!dropdown) { return }

      if (dropdown.selectedOptions[0].dataset.repCode) {
        if (dropdown.selectedOptions[0].dataset.repCode.split(' ').includes(value)) { return }

        dropdown.tomselect.setValue('')
      }
    })
  }
}

export default SubmissionViewManagement
