import Sortable from 'sortablejs'

class SubmissionFieldSelection {
  constructor (elem) {
    this.$fieldset = elem
    this.$comboBoxNext = elem.querySelector('.ts-control')
    this.$comboBox = elem.querySelector('[data-form-field-select]')
    this.$draggableContainer = elem.querySelector('#draggable-container')
    this.$submissionFieldContainer = elem.querySelector('.mock-field')
  }

  init () {
    // Initialize the Sortable component
    this.createSortableContainer()

    // Handle the input from dropdown
    this.$comboBox.addEventListener('change', this.handleSelection.bind(this))

    // Rebuild the existing selections during edit
    this.configureExistingSelections()
  }

  createSortableContainer () {
    const _this = this

    Sortable.create(document.querySelector('#draggable-container'), {
      animation: 75,
      swapThreshold: 1,
      dragClass: 'cursor-move',
      filter: '.ignore-elements',
      onUpdate: function (event) {
        _this.updateOrder()
      },
    })
  }

  handleSelection (selectEvent) {
    // Find the selected option from the tom select input on change
    const $option = selectEvent.target.selectedOptions[0]

    // Start process of building the field selection on the form
    this.appendSelection($option)

    // Clear the tom select input to prepare for next selection
    this.clearInputArray()
  }

  clearInputArray () {
    const value = this.$comboBox.value

    if (value === '') { return }

    this.$comboBox.tomselect.clear()
  }

  appendSelection (option) {
    // Clone the node from the hidden mock submission field on the page
    const node = this.$submissionFieldContainer.cloneNode(true)
    node.classList.add('visible-submission-view-field')

    // Return if the option is undefined
    if (!option) return

    // Configure the node with active form fields
    this.revealClonedNode(node)
    this.setRowLabel(node, option)
    this.setRowName(node, option)
    this.addRemoveButton(node, option)
    this.addHiddenFields(node, option)
    this.setRequiredField(node, option)
    this.updateTextarea(node, option)
    this.toggleSelection(option, true)
    this.setFieldOrder(node, option)

    // Add the configured node to the draggable list
    this.$draggableContainer.append(node)
  }

  // Field Configuration beyond this point //
  toggleSelection (option, value) {
    if (typeof option === 'undefined') { return }

    const foundOption = this.$comboBox.tomselect.options[option.value]

    if (typeof foundOption.$div === 'undefined') { return }

    foundOption.disabled = value
    foundOption.$div.ariaDisabled = value

    value ? foundOption.$div.removeAttribute('data-selectable') : foundOption.$div.setAttribute('data-selectable', true)
  }

  removeSubmissionField (option, item) {
    this.toggleSelection(option, false)
    item.currentTarget.closest('#submission-field-container').remove()

    this.updateOrder()
  }

  configureHiddenFields (option) {
    const { inputType, inputMultiple, inputLabel } = option.dataset

    const createHiddenField = (name, value) => {
      const hiddenField = document.createElement('input')
      hiddenField.setAttribute('type', 'hidden')
      hiddenField.setAttribute('name', this.inputName(option, name))
      hiddenField.setAttribute('value', value)
      return hiddenField
    }

    const inputTypeField = createHiddenField('type', inputType)
    const inputMultipleField = createHiddenField('multiple', inputMultiple)
    const inputLabelField = createHiddenField('label', inputLabel)

    return [inputTypeField, inputMultipleField, inputLabelField]
  }

  appendHiddenFields (fields, node) {
    Array.from(fields).forEach($field => {
      if ($field.name !== '') { node.append($field) }
    })
  }

  addRemoveButton (node, option) {
    node.querySelector('#remove-button').addEventListener('click', (event) => {
      this.removeSubmissionField(option, event)
    })
  }

  addHiddenFields (node, option) {
    const hiddenFields = this.configureHiddenFields(option)

    this.appendHiddenFields(hiddenFields, node)
  }

  setRequiredField (node, option) {
    const checkBox = node.querySelector('#required-check-box')
    const hiddenCheckBox = checkBox.previousSibling

    checkBox.name = this.inputName(option, 'required')
    hiddenCheckBox.name = this.inputName(option, 'required')
  }

  updateTextarea (node, option) {
    const textarea = node.querySelector('textarea')

    textarea.name = this.inputName(option, 'subtitle')
  }

  setRowLabel (node, option) {
    node.querySelector('#title').innerHTML = option.innerHTML
  }

  setRowName (node, option) {
    node.querySelector('#field-name').innerHTML = option.value
  }

  revealClonedNode (node) {
    node.classList.remove('hidden')
  }

  inputName (option, value) {
    return `submission_view[payload][${option.value}][${value}]`
  }

  setFieldOrder (node, option) {
    const totalFieldCount = document.querySelectorAll('.visible-submission-view-field').length + 1
    const orderInput = document.createElement('input')

    orderInput.setAttribute('type', 'hidden')
    orderInput.setAttribute('name', this.inputName(option, 'order'))
    orderInput.setAttribute('value', totalFieldCount)

    node.append(orderInput)
  }

  updateOrder () {
    const fields = document.querySelectorAll('.visible-submission-view-field')

    fields.forEach((item, index) => {
      const name = item.querySelector('#field-name').innerHTML
      const hiddenField = item.querySelector(`[name='submission_view[payload][${name}][order]']`)

      hiddenField.value = index
    })
  }

  configureExistingSelections () {
    this.handleRemove()
  }

  findOption (event) {
    const parent = event.currentTarget.closest('#submission-field-container')
    const name = parent.querySelector('#field-name').innerHTML
    const option = this.$comboBox.tomselect.options[name]

    return option
  }

  handleRemove () {
    const self = this

    document.querySelectorAll('#remove-button').forEach(($button) => {
      $button.addEventListener('click', (event) => {
        const option = self.findOption(event)

        this.removeSubmissionField(option, event)
      })
    })
  }

  // On open of dropdown, disable all current selections
  static disableSelections (dropdown) {
    const options = []
    const fields = document.querySelectorAll('#submission-field-container')
    const select = document.querySelector('[data-form-field-select]').tomselect

    fields.forEach(($field) => {
      const name = $field.querySelector('#field-name').innerHTML
      const option = select.options[name]

      options.push(option)
    })

    options.forEach(($option) => {
      if (typeof $option === 'undefined') { return }

      $option.disabled = true
      $option.$div.ariaDisabled = true

      $option.$div.removeAttribute('data-selectable')
    })
  }
}

export default SubmissionFieldSelection
