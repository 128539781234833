import TooltipUtils from './tooltip-utils'
import tippy from 'tippy.js'

class TooltipManager extends TooltipUtils {
  constructor () {
    super('[data-tooltip]')
    this.replaceSelector = '[data-tooltip-replace]'
  }

  init () {
    this.handleModalEvents()
  }

  resetTooltip () {
    var tooltip = document.querySelector(this.replaceSelector)
    var currentUserId = document.querySelector('[data-user-id]').id

    if (!tooltip) { return }

    if (tooltip.dataset.reviewerId === currentUserId) {
      tooltip.dataset.tooltip = 'Submission currently in review by you'
    } else {
      tooltip.dataset.tooltip = tooltip.dataset.tooltipReplace
    }

    this.create(tooltip)
  }

  create ($elem, content = null, nodeList = null, message = null) {
    if (message) {
      $elem.tooltip.setContent(message)

      return
    }

    if ($elem.dataset.tooltip === '') { return }

    // Skip creation if tooltip already exists
    if ($elem.tooltip) { return }

    $elem.tooltip = tippy($elem, {
      content: $elem.dataset.tooltip,
      maxWidth: 1500,
    })
  }
}

export default TooltipManager
