import Honeycrisp from 'honeycrisp'

class DigitInput {
  init (name = 'code') {
    Honeycrisp.create('.honeycrisp', {
      wrapperClass: 'honeycrisp-wrapper',
      inputClass: 'honeycrisp-input',
      inputName: name,
    })
  }
}

export default DigitInput
