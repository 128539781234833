
/** * Delete Dialog Stream Management - A class to assist with javascript sprinkles
*/
class DeleteDialogManagement {
  stream () {
    setTimeout(() => {
      const dialog = document.querySelector('#deleted-dialog')

      if (document.documentElement.classList.contains('drawer-open')) {
        if (dialog) {
          // check if drawer is open
          dialog.showModal()

          const button = dialog.querySelector('.dialog-accept')
          button.addEventListener('click', () => {
            window.location.href = '/submissions/all'
          })
        }
      }
    }, 1000)
  }
}

export default DeleteDialogManagement
