class TableNesting {
  constructor (el) {
    this.$el = el
  }

  init () {
    this.$el.addEventListener('click', (event) => {
      const subTopicID = event.currentTarget.getAttribute('data-subtopic-id')

      const itemRows = document.querySelectorAll(`[data-subtopic-nested-id="${subTopicID}"]`)

      Array.from(itemRows).forEach($row => {
        $row.classList.toggle('hidden')
      })
    })
  }
}

export default TableNesting
