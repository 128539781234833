/** * Stream Management - A class to assist with javascript sprinkles
 * Before stream render, we want to check if the stream is viewable based on the current filters and sorts.
 *
 * Considerations for "viewable"
 */
class StreamManagement {
  constructor () {
    this.$RIAOfficeName = 'advisory-alpha'
  }

  actionButton (stream) {
    const isCoRIAOffice = stream.dataset.coRiaOffice

    if (isCoRIAOffice === 'true') {
      this.filterActionButton(stream)
    }
  }

  filterActionButton (stream) {
    const officeName = document.querySelector('[data-ria-office-name]').id

    officeName === this.$RIAOfficeName ? this.useReviewAction(stream) : this.useViewAction(stream)
  }

  useReviewAction (stream) {
    stream.querySelector('#view-action').remove()
  }

  useViewAction (stream) {
    stream.querySelector('#review-action').remove()
  }

  viewable (stream) {
    const filterList = document.querySelector('#filter-list')
    const filters = filterList?.children
    // If no filters exist, we can return assuming the user should see the stream data
    if (!filters) return true

    const filterResults = Array.from(filters).map((filter) => {
      const row = this.findFilterRow(stream, filter)
      const filterType = this.titleizeFilterType(filter)
      const attribute = row.dataset[`submission${filterType}`]

      return this.filterCheck(filter, attribute)
    })

    return this.filteredResults(filterResults).every(Boolean)
  }

  filterCheck (filter, attribute) {
    switch (filter.dataset.predicate) {
      case '_eq_any':
        return this.equalsCheck(filter, attribute)
      case '_in':
        return this.equalsCheck(filter, attribute)
      case '_cont':
        return this.containsCheck(filter, attribute)
      case '_between':
        return this.dateCheck(filter, attribute)
      default:
        return true
    }
  }

  equalsCheck (filter, attribute) {
    return filter.innerHTML.split(', ').includes(attribute)
  }

  containsCheck (filter, attribute) {
    return attribute.toLowerCase().search(filter.innerHTML.toLowerCase()) !== -1
  }

  dateCheck (filter, attribute) {
    const dates = filter.innerHTML.match(/\d{4}-\d{2}-\d{2}/g)

    if (dates.length < 2) {
      var from = new Date(dates[0])
      var to = new Date(dates[0])
    } else {
      from = new Date(dates[0])
      to = new Date(dates[1])
    }

    from.setUTCHours(0, 0 + from.getTimezoneOffset(), 0, 0)
    to.setUTCHours(24, to.getTimezoneOffset(), 0, 0)

    var check = new Date(attribute)
    check = check.setUTCHours(0, 0 + check.getTimezoneOffset(), 0, 0)

    return (check >= from && check < to)
  }

  findFilterRow (stream, filter) {
    return stream.querySelector(`[data-submission-${this.transformedFilter(filter)}]`)
  }

  titleizeFilterType (filter) {
    return this.transformedFilter(filter).replace(/-/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase()).replace(' ', '')
  }

  transformedFilter (filter) {
    return filter.id.replace('_', '-')
  }

  filteredResults (filterResults) {
    return filterResults.filter(function (filter) { return filter !== undefined })
  }
}

export default StreamManagement
