import { Controller } from '@hotwired/stimulus'
import AttachmentManagerController from './attachment-manager'
import { AttachEvent } from './attachment-manager-item'

export default class ImageFieldController extends Controller<HTMLElement> {
  declare readonly attachmentManagerOutlet: AttachmentManagerController
  declare readonly previewImageTarget: HTMLImageElement

  static outlets = ['attachment-manager']
  static targets = ['previewImage']

  // Class variables
  uploadingClasses = ['cursor-progress', 'opacity-50']

  connect() {
    this.element.addEventListener('attachment-manager-item:before-attach', this.#attach.bind(this))
    this.element.addEventListener('attachment-manager-item:upload-finished', this.#uploadFinished.bind(this))
    this.element.addEventListener('attachment-manager-item:upload-started', this.#uploadStarted.bind(this))
    this.element.addEventListener('attachment-manager-item:detach', this.#detach.bind(this))
  }

  disconnect() {
    this.element.removeEventListener('attachment-manager-item:before-attach', this.#attach.bind(this))
    this.element.removeEventListener('attachment-manager-item:upload-finished', this.#uploadFinished.bind(this))
    this.element.removeEventListener('attachment-manager-item:upload-started', this.#uploadStarted.bind(this))
    this.element.removeEventListener('attachment-manager-item:detach', this.#detach.bind(this))
  }

  // Private methods

  #attach(event: AttachEvent) {
    this.previewImageTarget.alt = event.detail.file.name
    this.previewImageTarget.src = URL.createObjectURL(event.detail.file)
  }

  #detach() {
    ['alt', 'src'].forEach(attr => this.previewImageTarget.removeAttribute(attr))
  }

  #uploadFinished() {
    this.previewImageTarget.parentElement.classList.remove(...this.uploadingClasses)
  }

  #uploadStarted() {
    this.previewImageTarget.parentElement.classList.add(...this.uploadingClasses)
  }
}
