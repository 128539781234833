import {Controller} from '@hotwired/stimulus'
import { createFocusTrap, type FocusTrap, type Options } from 'focus-trap'

export default class FocusTrapController extends Controller<HTMLElement> {
  declare trap: FocusTrap

  // Stimulus lifecycle methods

  connect() {
    this.trap = createFocusTrap(this.element, this.#getOptions())
  }

  disconnect() {
    this.trap.deactivate()
  }

  // `focus-trap` instance methods

  activate() {
    this.trap.activate()
  }

  deactivate() {
    this.trap.deactivate()
  }

  pause() {
    this.trap.pause()
  }

  unpause() {
    this.trap.unpause()
  }

  // Private methods

  #getOptions() {
    const { focusTrapOptions } = this.element.dataset
    const options: Options = focusTrapOptions
      ? JSON.parse(focusTrapOptions)
      : {}

    return options
  }
}
