class RichTextEditor {
  init () {
    document.addEventListener('trix-initialize', function (event) {
      const nestingLevel = document.querySelector('.trix-button--icon-increase-nesting-level')
      const link = document.querySelector('.trix-button--icon-link')
      const quote = document.querySelector('.trix-button--icon-quote')

      const heading1 = document.querySelector('.trix-button--icon-heading-1')
      const strike = document.querySelector('.trix-button--icon-strike')

      strike.insertAdjacentElement('afterend', heading1)

      nestingLevel.insertAdjacentHTML('afterend', link.outerHTML)
      nestingLevel.insertAdjacentHTML('afterend', quote.outerHTML)

      link.remove()
      quote.remove()
    })
  }
}

export default RichTextEditor
