import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'
import FocusTrapController from './focus-trap'

interface DatepickerInput extends HTMLInputElement {
  datepicker: flatpickr.Instance
}

export default class BulkEditController extends Controller<HTMLElement> {
  static outlets = ['focus-trap']

  static targets = [
    'actionForm',   // Each action requires its own form to submit the action.
                    // Each form will have one `actionInput` per checked item.
    'actionList',   // The container for actions that can be performed on the
                    // checked items. Hidden until some checkboxes are checked.
    'actionInput',  // The input that will be submitted within an individual
                    // action's form to indicate which items are selected.
    'bulkCheckbox', // The checkbox that toggles all individual checkbox items
    'checkbox',     // An individual checkbox
  ]

  static values = {
    checked: Boolean,
    ids: Array,
    indeterminate: Boolean,
  }

  declare checkedValue: Boolean
  declare idsValue: Array<string>
  declare indeterminateValue: Boolean
  declare readonly actionFormTargets: HTMLFormElement[]
  declare readonly actionListTarget?: HTMLElement
  declare readonly actionInputTargets: HTMLInputElement[]
  declare readonly bulkCheckboxTarget?: HTMLInputElement
  declare readonly checkboxTargets: HTMLInputElement[]
  declare readonly focusTrapOutlet: FocusTrapController | null
  declare readonly hasBulkCheckboxTarget: boolean
  declare readonly hasActionListTarget: boolean
  declare readonly hasFocusTrapOutlet: boolean

  connect() {
    // HACK: We need to check that this controller's targets exist before
    // proceeding, but I'm not sure why. In theory, all of the markup exists on
    // the page at the point of instantiation, so this should never be false.
    // However, Stimulus doesn't find the targets upon first connection.
    // This may be an issue caused by using Stimulus + Phlex, but I'm not sure.
    if (this.hasBulkCheckboxTarget) {
      this.#handleCheckboxChange()
    }
  }

  // HACK: This is a workaround for a bug in Stimulus that causes datepicker
  // inputs that live inside of the actionListTarget to have their position
  // calculated incorrectly. It's related to the issue described in the connect
  // method above.
  actionListTargetConnected(elem: HTMLElement) {
    const datepickers = elem.querySelectorAll<DatepickerInput>('[data-datepicker]')

    datepickers.forEach((elem) => elem.datepicker.redraw())
  }

  bulkCheckboxTargetConnected(elem: HTMLInputElement) {
    elem.addEventListener('input', this.#handleBulkCheckboxChange.bind(this))

    if (elem.form) {
      elem.form.addEventListener('reset', this.#handleCheckboxChange.bind(this))
    }
  }

  bulkCheckboxTargetDisconnected(elem: HTMLInputElement) {
    elem.removeEventListener('input', this.#handleBulkCheckboxChange.bind(this))

    if (elem.form) {
      elem.form.removeEventListener('reset', this.#handleCheckboxChange.bind(this))
    }
  }

  checkboxTargetConnected(elem: HTMLInputElement) {
    elem.addEventListener('input', this.#handleCheckboxChange.bind(this))
  }

  checkboxTargetDisconnected(elem: HTMLInputElement) {
    elem.removeEventListener('input', this.#handleCheckboxChange.bind(this))
  }

  idsValueChanged() {
    this.dispatch('idsValue:changed')
  }

  // Private methods

  #removeActionInputs() {
    this.actionInputTargets.forEach(elem => elem.remove())
  }

  #generateActionInput(form: HTMLFormElement, id: string) {
    const name = form.dataset.bulkEditActionInputName || "ids[]"
    const input = document.createElement('input')

    input.dataset.bulkEditTarget = 'actionInput'
    input.name = name
    input.type = 'hidden'
    input.value = id

    return input
  }

  #handleBulkCheckboxChange(event: Event) {
    const { checked } = (event.target as HTMLInputElement)
    this.checkboxTargets.forEach(checkbox => checkbox.checked = checked)
    this.#setValues()
    this.#setBulkActionVisibility()
    this.#handleFocusTrap(checked)
  }

  #handleCheckboxChange() {
    // NOTE: an immediately fired setTimeout is used to ensure that the
    // checkboxes are in the state that we expect them to be in.
    // This is only really needed in the case of the form `reset` event
    // (which may not even be present depending on where this bulk edit is used)
    setTimeout(() => {
      this.#setValues({ updateBulkCheckbox: true })
      this.#setBulkActionVisibility()
    }, 0)
  }

  #handleFocusTrap(checked: boolean) {
    if (!this.hasFocusTrapOutlet) return

    if (checked) {
      this.focusTrapOutlet.activate()
    } else {
      this.focusTrapOutlet.deactivate()
    }
  }

  #setBulkActionVisibility() {
    if (!this.hasActionListTarget) return

    if (this.checkedValue) {
      this.actionListTarget.removeAttribute('inert')
      this.actionListTarget.classList.remove('opacity-50')
    } else {
      this.actionListTarget.setAttribute('inert', '')
      this.actionListTarget.classList.add('opacity-50')
    }
  }

  #setValues({ updateBulkCheckbox = false } = {}) {
    const checkedBoxes = this.checkboxTargets.filter(elem => elem.checked)
    const checked = checkedBoxes.length > 0
    const indeterminate = checked && checkedBoxes.length < this.checkboxTargets.length

    this.checkedValue = checked
    this.indeterminateValue = indeterminate
    this.idsValue = checked ? checkedBoxes.map(elem => elem.value) : []

    if (updateBulkCheckbox && this.hasBulkCheckboxTarget) {
      this.bulkCheckboxTarget.checked = checked
      this.bulkCheckboxTarget.indeterminate = indeterminate
    }

    this.#updateForms()
  }

  #updateForms() {
    this.#removeActionInputs()

    this.actionFormTargets.forEach(form => {
      const newActionInputs = new DocumentFragment()

      this.idsValue.forEach(id => newActionInputs.append(
        this.#generateActionInput(form, id)
      ))

      form.append(newActionInputs)
    })
  }
}
