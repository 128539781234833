class OverflowArrow {
  constructor () {
    this.overflowArrow = document.querySelector('.overflow-arrow')
    this.leftDrawer = this.overflowArrow.closest('#left-drawer')
  }

  init () {
    this.arrowHandler()
    this.monitorDrawerResize()
    this.handleArrowClick()
  }

  arrowHandler () {
    if (this.leftDrawer.scrollHeight > this.leftDrawer.clientHeight) {
      return this.overflowArrow.classList.remove('hidden')
    } else {
      return this.overflowArrow.classList.add('hidden')
    }
  }

  monitorDrawerResize () {
    const resizeObserver = new ResizeObserver(this.throttle(() => { this.arrowHandler() }, 100))

    return resizeObserver.observe(this.leftDrawer)
  }

  throttle (f, delay) {
    let timer = 0
    return function (...args) {
      clearTimeout(timer)
      timer = setTimeout(() => f.apply(this, args), delay)
    }
  }

  hideArrowButton () {
    this.overflowArrow.classList.add('hidden')
  }

  handleArrowClick () {
    this.overflowArrow.addEventListener('click', () => {
      const offset = this.leftDrawer.clientHeight * 0.66

      this.leftDrawer.scrollTo({ top: offset, behavior: 'smooth' })
    })
  }
}

export default OverflowArrow
