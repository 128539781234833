import { Controller } from '@hotwired/stimulus'
import tippy, { Instance as TippyInstance, Props } from 'tippy.js'

export interface HTMLElementWithTooltip extends HTMLElement {
  tooltip: TippyInstance
}

export default class TooltipController extends Controller<HTMLElementWithTooltip> {
  static targets = ['trigger']

  declare isFetching: boolean
  declare observer: MutationObserver
  declare readonly triggerTargets: HTMLElement[]

  connect () {
    // @ts-ignore - Not sure why TS thinks this method isn't callable.
    this.element.tooltip = tippy(this.element, this.getProps())
    this.fetchContent = this.fetchContent.bind(this)

    if (this.element.dataset.tooltipObserve === 'true') { this.observe() }
    if (this.element.dataset.tooltipSrc) { this.addFetchListeners() }
    if (this.element.dataset.tooltipOverflow === 'true') { this.addOverflowListeners() }
  }

  disconnect () {
    if (this.element.dataset.tooltipSrc) { this.removeFetchListeners() }
    if (this.element.dataset.tooltipOverflow === 'true') { this.removeOverflowListeners() }
    this.element.tooltip.destroy()
  }

  // "private" methods

  addFetchListeners () {
    this.element.tooltip.props.trigger.split(' ').forEach(trigger => {
      this.element.addEventListener(trigger, this.fetchContent)
    })
  }

  addOverflowListeners () {
    window.addEventListener('resize', this.rerender.bind(this))
    this.element.addEventListener('mouseenter', this.showIfContentOverflows)
  }

  fetchContent () {
    if (!this.element.dataset.tooltipSrc || this.isFetching) { return }

    this.isFetching = true

    fetch(this.element.dataset.tooltipSrc)
      .then((response) => response.text())
      .then((content) => {
        this.element.tooltip.setContent(content)
        this.isFetching = false

        if (this.element.dataset.tooltipSrcCached === 'true') {
          this.removeFetchListeners()
        }
      })
  }

  getContent () {
    const { dataset, title } = this.element
    const { tooltipOverflow, tooltipSrc, tooltipTemplate, tooltipText } = dataset
    const ariaLabel = this.element.getAttribute('aria-label')

    const content = tooltipOverflow === 'true' && this.element.innerText
      ? this.element.innerText
      : tooltipSrc
        ? 'loading…'
        : tooltipTemplate
          ? document.getElementById(tooltipTemplate).innerHTML
          : tooltipText
            ? this.sanitized(tooltipText)
            : ariaLabel
              ? ariaLabel
              : title

    if (title && content === title) {
      this.element.removeAttribute('title')
    }

    if (!content) {
      console.warn('TooltipController: No tooltip content provided')
    }

    return content
  }

  getProps () {
    const content = this.getContent()
    const tippyProps = JSON.parse(this.element.dataset.tooltipOptions || '{}') as Props
    const { tooltipOverflow } = this.element.dataset

    const props: Props = {
      allowHTML: true,
      content,
      maxWidth: 1500,
      trigger: tooltipOverflow ? 'manual' : undefined,
      triggerTarget: this.triggerTargets.length ? this.triggerTargets : null,
      ...tippyProps,
    }

    return props
  }

  observe () {
    this.observer = new MutationObserver((mutations) =>
      mutations.filter(m => m.attributeName?.startsWith('data-tooltip')).length && this.rerender()
    )

    this.observer.observe(this.element, { attributes: true })
  }

  removeFetchListeners () {
    this.element.tooltip.props.trigger.split(' ').forEach((trigger) => {
      this.element.removeEventListener(trigger, this.fetchContent)
    })
  }

  removeOverflowListeners () {
    window.removeEventListener('resize', this.rerender.bind(this))
    this.element.removeEventListener('mouseenter', this.showIfContentOverflows)
  }

  rerender () {
    const tippyProps = this.getProps()

    // NOTE: Tippy may no longer exist at this point
    this.element.tooltip?.setProps(tippyProps)
  }

  /**
   * Takes a string of HTML and returns a sanitized version of it
   */
  sanitized (content: string) {
    const parser = new DOMParser()
    return parser.parseFromString(content, 'text/html').body.innerHTML
  }

  showIfContentOverflows(event: MouseEvent) {
    const target = event.target as HTMLElement
    const isOverflowing = target.scrollWidth > target.clientWidth

    if (!isOverflowing) return

    this.element.tooltip.show()

    target.addEventListener(
      'mouseleave',
      () => { this.element.tooltip.hide() },
      { once: true }
    )
  }
}
