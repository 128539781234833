class ContentType {
  constructor (selectedOption) {
    this.selectedOption = selectedOption.textContent
  }

  init () {
    if (this.selectedOption === 'Choose an Item') { return }

    const selectedItem = this.getSelectedItem()

    // Enable click options for all items
    this.enableAllOptions()

    // Click the relevant ITEM based on content type
    document.querySelector(selectedItem).click()

    // Disable all the other ITEMS
    this.disableInapplicableOptions(selectedItem)
  }

  getSelectedItem () {
    if (this.urlOption()) {
      return '#url'
    } else if (this.videoUrlOption()) {
      return '#video_url'
    } else if (this.documentBrandedOption()) {
      return '#document_branded'
    } else if (this.documentUploadOption()) {
      return '#attachment'
    }
  }

  iterateThroughItems (items, type, value) {
    items.map(item => {
      var element = document.querySelector(item)

      this.classHandler(element, type)

      element.children[0].disabled = value
    })
  }

  enableAllOptions () {
    const items = [
      '#url',
      '#video_url',
      '#document_branded',
      '#attachment',
    ]

    this.iterateThroughItems(items, 'remove', false)
  }

  disableInapplicableOptions (selectedItem) {
    const items = [
      '#url',
      '#video_url',
      '#document_branded',
      '#attachment',
    ]

    var itemList = items.filter(item => item !== selectedItem)

    this.iterateThroughItems(itemList, 'add', true)
  }

  classHandler (element, val) {
    const classes = ['bg-gray-50', 'hover:bg-gray-50', 'hover:border-gray-200', 'cursor-not-allowed']

    element.classList[val](...classes)
  }

  urlOption () {
    return false
  }

  videoUrlOption () {
    const options = [
      'Training Video',
      'Client Video',
    ]

    return options.includes(this.selectedOption)
  }

  documentBrandedOption () {
    const options = [
      'Brochure',
      'Data Collection',
      'Fact Sheet',
      'Stationery',
      'Training Guide',
      'Data Summary',
      'Commentary',
    ]

    return options.includes(this.selectedOption)
  }

  documentUploadOption () {
    const options = [
      'Presentation',
      'Template',
    ]

    return options.includes(this.selectedOption)
  }
}

export default ContentType
