import { Controller } from '@hotwired/stimulus'

interface EventWithDetail extends ToggleEvent {
  detail: { open: boolean }
}

export default class BulkDetailsController extends Controller<HTMLElement> {
  static targets = ['collection', 'toggle']

  declare autoOpen: boolean
  declare readonly collectionTarget: HTMLElement
  declare readonly toggleTarget: HTMLButtonElement

  connect() {
    this.autoOpen = true
    this.toggleTarget.classList.add('auto-open')
    this.toggleTarget.addEventListener('click', this.#handleToggleClick.bind(this))
  }

  disconnect() {
    this.toggleTarget.removeEventListener('click', this.#handleToggleClick.bind(this))
  }

  maybeDisableAutoOpen(event: EventWithDetail) {
    if (!event.detail.open && this.autoOpen) this.autoOpen = false
  }

  // Private methods

  #close(elem: HTMLElement) {
    elem.removeAttribute('open')
  }

  #closeAll() {
    this.autoOpen = false
    this.collectionTarget.querySelectorAll('details').forEach(this.#close.bind(this))
  }

  #handleToggleClick(event: Event) {
    this.#toggleAll()

    this.toggleTarget.classList.toggle('auto-open')
    this.toggleTarget.dataset.tooltipText = this.autoOpen ? 'Close All Rows' : 'Open All Rows'
  }

  #open(elem: HTMLElement) {
    elem.setAttribute('open', '')
  }

  #openAll() {
    this.autoOpen = true
    this.collectionTarget.querySelectorAll('details').forEach(this.#open.bind(this))
  }

  #toggleAll() {
    this.autoOpen ? this.#closeAll() : this.#openAll()
  }
}
