import { Controller } from '@hotwired/stimulus'

interface TurboFrameElement extends HTMLElement {
  reload: () => void
}

export default class TabsController extends Controller<HTMLElement> {
  static targets = ['tab', 'tabList', 'tabPanel']

  declare readonly tabTargets: HTMLElement[]
  declare readonly tabListTargets: HTMLElement[]
  declare readonly tabPanelTargets: HTMLElement[]

  get groupName() {
    return this.element.dataset.tabGroup || 'tab'
  }

  get initialTab() {
    return this.tabs.find(tab => tab.id === this.element.dataset.tabInitial)
  }

  get panels() {
    return this.tabPanelTargets
  }

  get selectedIndex() {
    return this.tabs.indexOf(this.selectedTab)
  }

  get selectedTab() {
    return this.tabs.find(tab => tab.getAttribute('aria-selected') === 'true')
  }

  get tabs() {
    return this.tabTargets
  }

  get urlTab() {
    const { tabGroup } = this.element.dataset
    const param = new URLSearchParams(window.location.search).get(tabGroup)

    return this.tabs.find(tab => tab.id === param)
  }

  connect() {
    const tabToShowOnFirstRender =
      this.urlTab || // Look for a tab in the URL
      this.initialTab || // Look for a tab that's manually selected in the HTML
      this.tabTargets.at(0) // Default to the first tab

    this.select(tabToShowOnFirstRender, true)
    this.dispatch('connect')
  }

  handleKeyboardNav(event: KeyboardEvent) {
    const prevKeys = ['ArrowLeft', 'ArrowUp']
    const nextKeys = ['ArrowRight', 'ArrowDown']

    if (![...prevKeys, ...nextKeys].includes(event.key)) return
    if (prevKeys.includes(event.key)) this.#selectPrevTab()
    if (nextKeys.includes(event.key)) this.#selectNextTab()
  }

  /**
   * @param elemOrEvent
   * This method can be triggered programmatically -OR- by a click event.
   * If triggered programmatically, `elemOrEvent` will be an HTMLElement object.
   * If triggered by a click event, `elemOrEvent` will be an Event object.
   *
   * @param selectQuietly
   * If `true`, the tab will be selected without updating the URL or
   * focusing the tab. We probably only want to perform a quiet selection on
   * the initial render.
   */
  select(elemOrEvent: HTMLElement | Event, selectQuietly = false) {
    const oldTab = this.selectedTab
    const newTab = (elemOrEvent instanceof Event
      ? elemOrEvent.currentTarget
      : elemOrEvent) as HTMLElement

    this.#updateTabs(oldTab, newTab)
    this.#updateTabPanels(oldTab, newTab)

    if (!selectQuietly) {
      newTab.focus()
      this.#updateUrl(newTab.id)
    }

    if (oldTab !== newTab) {
      this.dispatch('change', { detail: { oldTab, newTab }})
    }
  }

  // Private methods

  /**
   * Get the classes to add and remove from the tab or panel during selection
   */
  #getDataClasses(elem: HTMLElement) {
    const { tabDeselectedClasses, tabSelectedClasses } = elem.dataset
    const selectedClasses = this.#parseClassString(tabSelectedClasses)
    const deselectedClasses = this.#parseClassString(tabDeselectedClasses)

    return [deselectedClasses, selectedClasses]
  }

  /**
   * Takes a class string (e.g. `"foo bar-baz  "`) and returns an array of
   * classnames (e.g. `["foo", "bar-baz"]`). Note that incoming class strings
   * may have all sorts of extra whitespace characters, so we filter the
   * resulting array to only return non-empty strings.
   */
  #parseClassString(classString?: string) {
    if (!classString) return []

    return classString.split(' ').filter(c => !!c)
  }

  #selectNextTab() {
    const nextIndex = this.selectedIndex + 1
    const nextTab = this.tabTargets[nextIndex] || this.tabTargets.at(0)

    this.select(nextTab)
  }

  #selectPrevTab() {
    const prevIndex = this.selectedIndex - 1
    const prevTab = this.tabTargets[prevIndex] || this.tabTargets.at(-1)

    this.select(prevTab)
  }

  #updateTabs(oldTab: HTMLElement, newTab: HTMLElement) {
    if (oldTab === newTab) return

    this.tabTargets.forEach(elem => {
      const [deselectedClasses, selectedClasses] = this.#getDataClasses(elem)

      // If this tab is the previously-selected tab, deselect it.
      // But also deselect it if there was no previously-selected tab.
      // (This happens during first render.)
      if ((!oldTab && elem !== newTab) || elem === oldTab) {
        elem.classList.add(...deselectedClasses)
        elem.classList.remove(...selectedClasses)
        elem.setAttribute('aria-selected', 'false')
        elem.setAttribute('tabindex', '-1')
      }

      // If this tab is the target tab, select it.
      if (elem === newTab) {
        elem.classList.add(...selectedClasses)
        elem.classList.remove(...deselectedClasses)
        elem.setAttribute('aria-selected', 'true')
        elem.setAttribute('tabindex', '0')
      }
    })
  }

  #updateTabPanels(oldTab: HTMLElement, newTab: HTMLElement) {
    if (oldTab === newTab) return

    this.panels.forEach(elem => {
      const tabId = elem.getAttribute('aria-labelledby')
      const turboFrame = elem.querySelector<TurboFrameElement>('& > turbo-frame')
      const [deselectedClasses, selectedClasses] = this.#getDataClasses(elem)

      // If this panel belongs to the previously-selected tab, deselect it.
      // But also deselect it if there was no previously-selected tab.
      // (This happens during first render.)
      if ((!oldTab && tabId !== newTab.id) || tabId === oldTab?.id) {
        elem.classList.add(...deselectedClasses)
        elem.classList.remove(...selectedClasses)
        elem.setAttribute('tabindex', '-1')
      }

      // If this panel belongs to the target tab, select it.
      else if (tabId === newTab.id) {
        elem.classList.add(...selectedClasses)
        elem.classList.remove(...deselectedClasses)
        elem.setAttribute('tabindex', '0')

        if (!turboFrame) return

        if (turboFrame.hasAttribute('disabled')) {
          turboFrame.removeAttribute('disabled')
        }

        if (turboFrame.hasAttribute('complete')) {
          turboFrame.reload()
        }
      }
    })
  }

  #updateUrl(tabId: string) {
    const url = new URL(window.location.toString())
    url.searchParams.set(this.element.dataset.tabGroup, tabId)
    history.replaceState({}, '', url)
  }
}
