import { Controller } from '@hotwired/stimulus'

export default class TimestampController extends Controller<HTMLTimeElement> {
  connect() {
    this.element.setAttribute(
      'aria-label',
      this.getFormattedDate(this.element.dateTime)
    )
  }

  /**
   * This method formats an ISO 8601 date string to a human-readable format
   * (e.g. "Wed, May 26, 2021, 10:41:13 AM EDT")
   */
  getFormattedDate(isoDateString: string) {
    const date = new Date(isoDateString)
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      month: 'short',
      second: 'numeric',
      timeZoneName: 'short',
      weekday: "short",
      year: 'numeric',
    }).format(date)

    return formattedDate
  }
}
