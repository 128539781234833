class FileUpload {
  constructor ($input, $files, $inputType, $objectName) {
    this.$originalInput = $input
    this.$files = $files
    this.$inputType = $inputType
    this.$objectName = $objectName
    this.$originalParent = this.$originalInput.parentNode
    this.$filesTarget = document.querySelector('[data-target="files"]')
    this.$inputTypeTarget = document.querySelector('[data-target="input-types"]')
    this.$fileListTarget = document.querySelector(`[data-target="file-list-${this.$inputType}"]`)
  }

  init () {
    var generatedInputs = this.generateMultipleInputs()
    this.cloneInput(generatedInputs)
    this.setInputType(generatedInputs)
    this.setRemoveInputListener()
  }

  generateMultipleInputs () {
    var newInputs = this.$files.map($file => {
      var fileInput = document.createElement('input')
      fileInput.type = 'file'

      var list = new DataTransfer()
      list.items.add($file)

      var newFileList = list.files
      fileInput.files = newFileList

      return fileInput
    })

    return newInputs
  }

  cloneInput (generatedInputs) {
    // Hide the original input and store for future POST
    generatedInputs.map($input => {
      var updatedInput = this.updateInputsWithOriginalData($input)

      // Update the new attachment input
      updatedInput.name = this.updateInputAttr(updatedInput.name)
      updatedInput.id = this.updateInputAttr(updatedInput.id)

      // remove [] from end of name
      updatedInput.name = updatedInput.name.replace('[]', '')

      this.buildFileList(updatedInput)

      this.$filesTarget.append(updatedInput)
    })

    // Remove the hidden attribute if more than one
    // list element is present on the container
    this.checkItemLength()

    // Add border styles to the first and last element
    this.addBorderStyles()

    // Clear the old input and ensure that the old data
    // didn't transfer over to the new attachment input
    this.clearInput(this.$originalInput)

    // Add the new input to the orginal input location
    this.$originalParent.append(this.$originalInput)
  }

  setInputType (inputs) {
    inputs.map($input => {
      var typeInput = document.createElement('input')
      typeInput.type = 'text'
      typeInput.value = this.$inputType

      var inputName = $input.name.replace('[file]', '[input_type]')
      var inputID = $input.id.replace('_file', '_input_type')

      typeInput.name = inputName
      typeInput.id = inputID

      this.$inputTypeTarget.append(typeInput)
    })
  }

  setRemoveInputListener () {
    var removeButtons = document.querySelectorAll('.remove-input')

    removeButtons.forEach($button => {
      $button.addEventListener('click', this.removeInput.bind(this))
    })
  }

  updateInputsWithOriginalData (input) {
    input.name = this.$originalInput.name
    input.id = this.$originalInput.id

    return input
  }

  checkItemLength () {
    var itemContainer = this.$fileListTarget.children[0]
    var itemLength = itemContainer.children.length

    itemContainer.classList.toggle('hidden', itemLength <= 0)
  }

  addBorderStyles () {
    var itemList = Array.from(this.$fileListTarget.children[0].children)

    itemList.forEach($item => {
      $item.classList.remove('rounded-t', 'rounded-b', 'border-b-1')
    })

    var lastItem = itemList.at(-1)
    var firstItem = itemList.at(0)

    if (firstItem !== undefined) {
      firstItem.classList.add('rounded-t')
    }

    if (lastItem !== undefined) {
      lastItem.classList.add('rounded-b', 'border-b-1')
    }
  }

  removeInput ($event) {
    var button = $event.currentTarget
    var buttonId = button.getAttribute('name')

    var fieldTypeId = buttonId.replace('file', 'input_type')
    var foundFileInput = document.querySelector(`#${buttonId}`)
    var foundFieldTypeInput = document.querySelector(`#${fieldTypeId}`)

    this.removeAttachmentInformation(button)
    this.clearInput(foundFileInput)
    this.clearInput(foundFieldTypeInput)
    this.checkItemLength()
    this.addBorderStyles()
  }

  buildFileList (input) {
    // find the single file on the input
    var file = input.files[0]
    var container = this.$fileListTarget.querySelector(`.file-list_${this.$inputType}_container`)

    // Create elements of the new attachments
    var item = document.createElement('div')
    var iconSpan = document.createElement('span')
    var nameSpan = document.createElement('span')
    var sizeSpan = document.createElement('span')
    var deleteSpan = document.createElement('span')

    // File the items with applicable content
    nameSpan.textContent = file.name
    sizeSpan.textContent = window.app.util.formatBytes(file.size)
    deleteSpan.innerHTML = this.__getCloseSVG(input.id)
    iconSpan.innerHTML = this.__getAttachmentSVG()

    // Apply container classes
    container.classList.add(...this.containerClasses())

    // Apply classes to the span elements
    item.classList.add(...this.listItemClasses())
    nameSpan.classList.add(...this.nameSpanClasses())
    sizeSpan.classList.add(...this.sizeSpanClasses())

    // Append the elements to the list
    item.appendChild(iconSpan)
    item.appendChild(nameSpan)
    item.appendChild(sizeSpan)
    item.appendChild(deleteSpan)
    container.appendChild(item)
  }

  updateInputAttr (string) {
    var times = 0

    const numberID = Number(string.replace(/\D+/g, ''))
    const foundOccurrences = window.app.util.occurrences(string, numberID.toString())
    const regex = new RegExp(`${numberID}`, 'g')

    return string.replace(regex, match => ++times === foundOccurrences ? this.getRandomInt() : match)
  }

  clearInput (input) {
    input.value = ''
  }

  removeAttachmentInformation (button) {
    button.parentElement.parentElement.remove()
  }

  listItemClasses () {
    return [
      'flex',
      'items-center',
      'type-body-2',
      'p-xs',
      'border-t-1',
      'border-r-1',
      'border-l-1',
      'border-gray-200',
      'hover:bg-gray-50',
    ]
  }

  nameSpanClasses () {
    return [
      'ellipsis',
      'flex-auto',
      'overflow-hidden',
      'whitespace-nowrap',
    ]
  }

  sizeSpanClasses () {
    return [
      'flex-0',
      'text-right',
      'w-20',
    ]
  }

  containerClasses () {
    return [
      'flex',
      'flex-col',
      'mt-sm',
    ]
  }

  __containerClasses () {
    return [
      'border',
      'border-gray-200',
      'flex',
      'flex-col',
      'items-start',
      'justify-between',
      'p-xs',
      'type-body-2',
      'rounded-t',
      'rounded-b',
      'hover:bg-gray-50',
      'mt-4',
    ]
  }

  __getCloseSVG (id) {
    return `
      <div class="pt-px pl-4 cursor-pointer remove-input hover:text-whitelabel-400" name=${id}>
        <svg class="fill-current text-gray-700" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.66668 7.99992C4.66668 8.36659 4.96668 8.66659 5.33334 8.66659H10.6667C11.0333 8.66659 11.3333 8.36659 11.3333 7.99992C11.3333 7.63325 11.0333 7.33325 10.6667 7.33325H5.33334C4.96668 7.33325 4.66668 7.63325 4.66668 7.99992ZM8.00001 1.33325C4.32001 1.33325 1.33334 4.31992 1.33334 7.99992C1.33334 11.6799 4.32001 14.6666 8.00001 14.6666C11.68 14.6666 14.6667 11.6799 14.6667 7.99992C14.6667 4.31992 11.68 1.33325 8.00001 1.33325ZM8.00001 13.3333C5.06001 13.3333 2.66668 10.9399 2.66668 7.99992C2.66668 5.05992 5.06001 2.66659 8.00001 2.66659C10.94 2.66659 13.3333 5.05992 13.3333 7.99992C13.3333 10.9399 10.94 13.3333 8.00001 13.3333Z" />
        </svg>
      </div>
    `
  }

  __getAttachmentSVG () {
    return `
      <div class="pr-1">
        <svg class="fill-current text-gray-700 " width="18" height="18" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M16.5 6.7499V17.3299C16.5 19.4199 14.97 21.2799 12.89 21.4799C10.5 21.7099 8.5 19.8399 8.5 17.4999V5.1399C8.5 3.8299 9.44 2.6399 10.74 2.5099C12.24 2.3599 13.5 3.5299 13.5 4.9999V15.4999C13.5 16.0499 13.05 16.4999 12.5 16.4999C11.95 16.4999 11.5 16.0499 11.5 15.4999V6.7499C11.5 6.3399 11.16 5.9999 10.75 5.9999C10.34 5.9999 10 6.3399 10 6.7499V15.3599C10 16.6699 10.94 17.8599 12.24 17.9899C13.74 18.1399 15 16.9699 15 15.4999V5.1699C15 3.0799 13.47 1.2199 11.39 1.0199C9.01 0.789902 7 2.6599 7 4.9999V17.2699C7 20.1399 9.1 22.7099 11.96 22.9799C15.25 23.2799 18 20.7199 18 17.4999V6.7499C18 6.3399 17.66 5.9999 17.25 5.9999C16.84 5.9999 16.5 6.3399 16.5 6.7499Z" />
        </svg>
      </div>
    `
  }

  getRandomInt () {
    return Math.floor(Math.random() * 1_000_000_000)
  }
}

export default FileUpload
