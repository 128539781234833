import { Controller } from '@hotwired/stimulus'

export default class FormChangeController extends Controller<HTMLFormElement> {
  static targets = ['button']

  declare initialState: FormData
  declare modified: Boolean
  declare readonly buttonTargets: HTMLButtonElement[]

  connect() {
    this.initialState = new FormData(this.element)

    this.element.addEventListener('input', this.#checkForm.bind(this))
    this.element.addEventListener('reset', this.#resetForm.bind(this))

    this.#checkForm()
  }

  disconnect() {
    this.element.removeEventListener('input', this.#checkForm.bind(this))
    this.element.removeEventListener('reset', this.#resetForm.bind(this))
  }

  // Private methods

  #checkForm() {
    const currentState = new FormData(this.element)
    this.modified = !this.#formDataEquals(this.initialState, currentState)
    this.#updateButtons()
  }

  #formDataEquals(a: FormData, b: FormData) {
    const initialEntries = Array.from(a.entries())
    const currentEntries = Array.from(b.entries())
    let result = true

    if (initialEntries.length !== currentEntries.length) {
      result = false
    } else {
      initialEntries.forEach(([_key, initialValue], index) => {
        if (currentEntries[index][1] !== initialValue) result = false
      })
    }

    return result
  }

  #resetForm() {
    this.modified = false
    this.#updateButtons()
  }

  #updateButtons() {
    this.buttonTargets.forEach((target) => {
      target.disabled = !this.modified
    })
  }
}