import axios from 'axios'

class IdentityTracker {
  constructor (currentIdentityId) {
    this.$currentIdentityInput = document.getElementById('current_identity_id_selection')
    this.$currentIdentityOptions = this.$currentIdentityInput.options

    this.$allIdentities = document.getElementsByName('user[all_advisor_identities]')[1].checked
    this.$currentIdentityId = currentIdentityId
  }

  init () {
    this.clearCurrentIdentityOptions()

    document.addEventListener('overlay:opened', () => {
      this.findSelectedOption()
    })
  }

  updateIdentityOptions () {
    this.clearCurrentIdentityOptions()
    this.findSelectedOption()
  }

  identitySelection (collection) {
    this.clearCurrentIdentityOptions()

    if (this.$allIdentities) {
      Array.from(collection).forEach(option => {
        this.addOption(option.name, option.id)
      })
    }

    this.findSelectedOption()
  }

  findSelectedOption () {
    let selectedOption = ''

    Array.from(this.$currentIdentityOptions).forEach(option => {
      if (option.hasAttribute('selected')) {
        selectedOption = option
      }
    })

    const allIdentityOptions = document.querySelectorAll('[data-ts-item]')

    allIdentityOptions.forEach(option => {
      var optionText = option.innerText.split('\n')[0]
      var optionId = option.dataset.value
      this.addOption(optionText, optionId)

      if (selectedOption !== '') {
        if (selectedOption.innerText === option.innerText) {
          this.selectOption(optionId)
        }
      }

      const currentIdentityId = this.locateCurrentIdentityId()

      if (currentIdentityId === option.dataset.value) {
        this.selectOption(optionId)
      }
    })
  }

  async fetchIdentities (userId, event = true) {
    return axios.get('/api/identities', { params: { user_id: userId, all_advisor_identities: event } })
      .then(response => response.data)
  }

  clearCurrentIdentityOptions () {
    this.$currentIdentityInput.innerText = null
  }

  addOption (text, id) {
    var newOption = new Option(text, id)
    this.$currentIdentityInput.add(newOption)
  }

  selectOption (id) {
    var option = this.$currentIdentityInput.querySelector(`option[value="${id}"]`)
    if (option) {
      option.setAttribute('selected', 'selected')
    }
  }

  locateCurrentIdentityId () {
    if (this.$currentIdentityId) {
      return this.$currentIdentityId
    }

    return document.getElementById("user-form-contents").dataset.currentIdentityId
  }
}

export default IdentityTracker
