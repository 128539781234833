import axios from 'axios'

class PriorityToggle {
  constructor ($submissionId, $priority) {
    this.submissionId = $submissionId
    this.priority = $priority
  }

  togglePriority () {
    const token = document.querySelector('[name=csrf-token]').content
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token

    return axios.patch(`/submissions/${this.submissionId}/priority_toggle`, { priority: this.priority })
      .then(res => res.data)
  }
}

export default PriorityToggle
