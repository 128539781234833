import axios from 'axios'

// This class is used to check if a user's identity
// matches what the page has stored. If it doesn't,
// the page will reload.
class IdentityCheck {
  constructor () {
    this.$location = window.location.pathname
    this.$submissionsPath = '/submissions/all'
  }

  init () {
    window.addEventListener('focus', this.handleChange.bind(this))
    window.addEventListener('overlay:close', this.handleChange.bind(this))
  }

  handleChange () {
    if (this.$location !== this.$submissionsPath) { return }
    if (this.submissionOpen()) { return }

    this.checkIdentity()
  }

  checkIdentity () {
    const userId = this.getUserId()
    const identityId = this.getIdentityId()

    this.fetchCurrentIdentity(userId).then(response => {
      if (response.data !== identityId) {
        window.location.reload()
      }
    })
  }

  fetchCurrentIdentity (userId) {
    return axios.get(`/api/users/${userId}`)
  }

  getUserId () {
    return document.querySelector('[data-user-id]').id
  }

  getIdentityId () {
    return document.querySelector('[data-identity-id]').id
  }

  submissionOpen () {
    return document.querySelector('#container-form') !== null
  }
}

export default IdentityCheck
