class MultiSelect {
  constructor (el) {
    this.el = el
    this.multiSelectIcons = el.querySelectorAll('.multi-select-icon')
    this.checkboxes = Array.from(el.querySelectorAll('input[type="checkbox"]'))
    this.headerIcons = el.querySelector('.header-icons')

    this.checkedCount = 0
  }

  multiSelectIconHandler (val) {
    this.multiSelectIcons.forEach((multiSelectIcon) => {
      if (!multiSelectIcon) { return }

      if (multiSelectIcon.getAttribute('data-listener')) { return }

      multiSelectIcon.setAttribute('data-listener', true)

      multiSelectIcon.addEventListener('click', (event) => {
        this.selectAllButtonHandler(val, event)
      })
    })
  }

  initialState (element) {
    return element.classList.contains('multi-select-initial')
  }

  classManager (value, element) {
    const classes = ['multi-select-initial', 'multi-select-minus', 'multi-select-checked']

    // Remove all classes on the button
    // this.multiSelectIcon.classList.remove(...classes)
    element.classList.remove(...classes)
    // Add desired class to the button
    element.classList.add(`multi-select-${value}`)
  }

  setAllDataTags (value) {
    this.checkboxes.forEach(checkbox => {
      checkbox.dataset.id = value
    })
  }

  selectAllButtonHandler (val, event) {
    if (this.initialState(event.target)) {
      this.classManager('checked', event.target)
      this.selectAllHelper(true, event.target)
      this.setAllDataTags('checked')
      this.checkedCount = this.checkboxes.length

      if (val === 'attachment') {
        const attachments = Array.from(event.target.closest('.multi-select-attachment-container').querySelectorAll('.file-container'))

        attachments.forEach(attachment => {
          attachment.classList.add('animate-attachment')
        })

        this.headerIcons.classList.remove('hidden')
        this.urlManager(this.checkboxes, 'add', event.target)
      } else {
        this.headerIcons.classList.remove('hidden')
      }
    } else {
      this.classManager('initial', event.target)
      this.selectAllHelper(false, event.target)
      this.setAllDataTags('')
      this.checkedCount = 0

      if (val === 'attachment') {
        const attachments = Array.from(event.target.closest('.multi-select-attachment-container').querySelectorAll('.file-container'))

        attachments.forEach(attachment => {
          attachment.classList.remove('animate-attachment')
        })

        this.headerIcons.classList.add('hidden')
        this.urlManager(this.checkboxes, 'remove', event.target)
      } else {
        this.headerIcons.classList.add('hidden')
      }
    }
  }

  selectAllHelper (value, element) {
    // get last class from list
    const className = this.el.classList[this.el.classList.length - 1]
    const el = element.closest(`.${className}`)

    const checkboxes = Array.from(el.querySelectorAll('input[type="checkbox"]'))

    checkboxes.forEach((checkbox) => {
      if (checkbox.type === 'checkbox') {
        checkbox.checked = value

        if (value) {
          this.handleBorder('add', checkbox)
        } else {
          this.handleBorder('remove', checkbox)
        }
      }
    })
  }

  determineCheckboxStatus (val) {
    // Function to handle the change event for each checkbox
    const handleChange = (checkbox) => {
      var selectIcon = checkbox.closest('.multi-select-attachment-container')?.querySelector('.multi-select-icon')

      if (!selectIcon) {
        selectIcon = checkbox.closest('.multi-select-container')?.querySelector('.multi-select-icon')
      }

      if (checkbox.checked) {
        this.checkedCount++
        checkbox.dataset.id = 'checked'
        this.classManager('minus', selectIcon)

        if (val === 'attachment') {
          this.urlManager([checkbox], 'add')
        }

        this.handleBorder('add', checkbox)
        this.headerIcons.classList.remove('hidden')
      } else {
        this.checkedCount--
        this.classManager('minus', selectIcon)
        checkbox.dataset.id = ''

        if (val === 'attachment') {
          this.urlManager([checkbox], 'remove')
        }

        this.handleBorder('remove', checkbox)
      }

      if (this.checkedCount === this.checkboxes.length) {
        this.classManager('checked', selectIcon)
      } else if (this.checkedCount === 0) {
        this.classManager('initial', selectIcon)

        if (val === 'attachment' || val === 'submission') {
          this.headerIcons.classList.add('hidden')
        }
      }
    }

    // Add event listener to each checkbox
    this.checkboxes.forEach((checkbox) => {
      checkbox.addEventListener('change', () => handleChange(checkbox))
    })
  }

  handleBorder (value, checkbox) {
    const record = checkbox.closest('.new-business-record')

    if (!record) { return }

    if (value === 'add') {
      record.classList.add('border-whitelabel-600')
      record.classList.add('ring')
      record.classList.add('ring-whitelabel-100')
    } else {
      record.classList.remove('border-whitelabel-600')
      record.classList.remove('ring')
      record.classList.remove('ring-whitelabel-100')
    }
  }
}

export default MultiSelect
