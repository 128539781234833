import { Controller } from '@hotwired/stimulus'
import autosize from 'autosize'

// TODO: Remove this controller in favor of the CSS `field-sizing` property
// once it's widely supported. At time of writing, Chrome and Edge support it
// but Firefox and Safari don't (yet).
// See: https://caniuse.com/mdn-css_properties_field-sizing
export default class AutosizeController extends Controller<HTMLElement> {
  connect() {
    autosize(this.element)
  }

  disconnect() {
    autosize.destroy(this.element)
  }
}
