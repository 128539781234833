import { Controller } from '@hotwired/stimulus'

interface EventWithParams extends Event {
  params: {
    value: string
  }
}

export default class ToggleController extends Controller<HTMLElement> {
  class({ params: { value } }: EventWithParams) {
    const classes = value.split(' ')

    classes.forEach(c => this.element.classList.toggle(c))
  }
}
