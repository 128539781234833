/**
 * Determine which element to target based on a Stimulus event's `target` param.
 *
 * Supported values are:
 * - `currentTarget`: the element that the controller is attached to
 * - A query selector string: the first element that matches the selector
 *
 * If no `target` is set, the element that triggered the event will be used.
 */
export function getTarget(
  event: Event & { params: Record<string, any> }
) {
  const target = event.params.target === 'currentTarget'
    ? event.currentTarget
    : event.params.target !== undefined
    ? document.querySelector(event.params.target as string)
    : event.target

  if (!target) throw new Error('Target not found')

  return target as HTMLElement
}

export function formatBytes(bytes: number) {
  const units = ['bytes', 'Kb', 'Mb', 'Gb', 'Tb']
  let unitIndex = 0

  while (bytes >= 1024 && unitIndex < units.length - 1) {
      bytes /= 1024
      unitIndex++
  }

  return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2
  }).format(bytes) + ' ' + units[unitIndex]
}
