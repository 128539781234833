class TooltipUtils {
  constructor (selector) {
    this.selector = selector
  }

  handleModalEvents () {
    document.querySelectorAll(this.selector).forEach(this.create.bind(this))

    document.addEventListener('modal:populated', () => {
      const { $panel } = window.app.modal
      $panel.querySelectorAll(this.selector).forEach(this.create.bind(this))
    })

    document.addEventListener('modal:closed', () => {
      const { $panel } = window.app.modal
      $panel.querySelectorAll(this.selector).forEach(this.destroy)
    })
  }

  destroy ($elem) {
    $elem.tooltip.destroy()
  }
}

export default TooltipUtils
