class BuildSettings {
  run (elem) {
    var settings = this.default()
    var specificSettings = this.specificSettings(elem, settings)

    return specificSettings
  }

  default () {
    return {
      placeholder: 'Select...',
      hidePlaceholder: true,
      closeAfterSelect: true,
      duplicates: true,
      plugins: {
        remove_button: {
          title: 'Remove this item',
        },
      },
    }
  }

  specificSettings (elem, settings) {
    this.userIdentitiesSettings(elem, settings)
    this.newBusinessSettings(elem, settings)
    this.selectAccountSettings(elem, settings)
    this.selectComplianceSettings(elem, settings)
    this.resourceItemSettings(elem, settings)
    this.dolSettings(elem, settings)
    this.accountServiceAdvisor(elem, settings)
    this.complianceSubmissionAdvisor(elem, settings)
    this.caseDesignManagerRepCode(elem, settings)
    this.clientName(elem, settings)
    this.accountServiceAccountNumber(elem, settings)
    this.complianceAccountNumber(elem, settings)
    this.submissionSearch(elem, settings)
    this.submissionViewsSelection(elem, settings)
    this.submissionViewPrimaryType(elem, settings)
    this.resourcePermissionSettings(elem, settings)

    return settings
  }

  caseDesignManagerRepCode (elem, settings) {
    if (elem.name === 'case_design_submission[manager_rep_code]') {
      var caseDesignSubmissionSettings = {
        placeholder: 'Select Advisor...',
        itemClass: 'item pl-1 resource-item text-md background-none',
        plugins: {},
      }

      Object.assign(settings, caseDesignSubmissionSettings)
    }
  }

  accountServiceAdvisor (elem, settings) {
    if (elem.name === 'account_service_submission[manager_rep_code]') {
      var accountServiceSubmissionSettings = {
        placeholder: 'Select Advisor...',
        itemClass: 'item pl-1 resource-item text-md background-none',
        plugins: {},
      }

      Object.assign(settings, accountServiceSubmissionSettings)
    }
  }

  complianceSubmissionAdvisor (elem, settings) {
    if (elem.dataset.complianceAdvisor) {
      var complianceSubmissionSettings = {
        placeholder: 'Select Supervised Person...',
        itemClass: 'item pl-1 resource-item text-md background-none',
        plugins: {},
        onChange (value) {
          window.app.submissionViewManagement.removeOutdatedData(value)
        },
      }

      Object.assign(settings, complianceSubmissionSettings)
    }
  }

  userIdentitiesSettings (elem, settings) {
    if (elem.name === 'user[identity_ids][]') {
      var onChangeSettings = {
        onChange () {
          new window.app.identityTracker().updateIdentityOptions()
        },
      }

      Object.assign(settings, onChangeSettings)
    }
  }

  newBusinessSettings (elem, settings) {
    if (elem.name === 'submission_container[select_account][]') {
      var onChangeSettings = {
        sortField: {
          field: 'text',
          direction: 'desc',
        },
      }

      Object.assign(settings, onChangeSettings)
    }
  }

  selectAccountSettings (elem, settings) {
    if (elem.name === 'select_account') {
      var parentDropdownSettings = {
        onInitialize: function () {
          this.dropdown.classList.add('ts-static')
        },
        itemClass: 'item pl-1 resource-item text-md background-none',
        dropdownParent: 'body',
        placeholder: 'Select Account...',
        plugins: {},
      }

      Object.assign(settings, parentDropdownSettings)
    }
  }

  selectComplianceSettings (elem, settings) {
    if (elem.name === 'select_compliance_form') {
      var parentDropdownSettings = {
        itemClass: 'item pl-1 resource-item text-md background-none',
        placeholder: 'Select Form/Instructions...',
        plugins: {},
        sortField: {
          field: 'text',
          direction: 'asc',
        },
      }

      Object.assign(settings, parentDropdownSettings)
    }
  }

  resourcePermissionSettings (elem, settings) {
    if (elem.dataset.permissionField === 'true') {
      var resourcePermissionSettings = {
        placeholder: 'All',
      }
      Object.assign(settings, resourcePermissionSettings)
    }
  }

  resourceItemSettings (elem, settings) {
    
    const resourceTypes = [
      'resource_subtopic[resource_topic_id]',
      'resource_item[resource_topic_id]', 
      'resource_item[resource_item_format_id]', 
      'resource_item[resource_subtopic_id]',
    ]

    if (resourceTypes.includes(elem.name)) {
      var resourceItemSettings = {
        itemClass: 'item resource-item text-md background-none',
        maxItems: 1,
        hidePlaceholder: false,
        plugins: ['dropdown_input'],
      }

      Object.assign(settings, resourceItemSettings)
    }
  }

  dolSettings (elem, settings) {
    elem = elem.name

    if (typeof elem === 'string') {
      if (elem.includes('[dol]')) {
        if (typeof elem === 'string') {
          elem = elem.substring(elem.length - 5)
        } else {
          elem = elem.name.substring(elem.name.length - 5)
        }

        var dolSettings = {
          itemClass: 'item resource-item text-md',
          controlClass: 'dropdown-type-input',
          maxItems: 1,
          hidePlaceholder: false,
          plugins: ['dropdown_input'],
        }

        Object.assign(settings, dolSettings)
      }
    }
  }

  clientName (elem, settings) {
    if (elem.name === 'compliance_submission[form_fields][client_name]') {
      var clientNameSettings = {
        create: true,
        itemClass: 'item pl-1 resource-item text-md background-none',
        placeholder: 'Select Client...',
        plugins: {},
        onChange (value) {
          if (!value) { return }

          const repCode = this.options[value].repCode

          window.app.submissionViewManagement.removeOutdatedManager(repCode)
        },
      }

      Object.assign(settings, clientNameSettings)
    }
  }

  accountServiceAccountNumber (elem, settings) {
    if (elem.name === 'account_service_submission[form_fields][account_number]') {
      var accountNumberSettings = {
        create: true,
        itemClass: 'item pl-1 resource-item text-md background-none',
        placeholder: 'Account Number...',
        plugins: {},
      }

      Object.assign(settings, accountNumberSettings)
    }
  }

  complianceAccountNumber (elem, settings) {
    if (elem.dataset.singleSelection) {
      var accountNumberSettings = {
        create: true,
        itemClass: 'item pl-1 resource-item text-md background-none',
        placeholder: 'Select Account...',
        plugins: {},
        onChange (value) {
          if (!value) { return }

          const repCode = this.options[value].repCode

          window.app.submissionViewManagement.removeOutdatedManager(repCode)
        },
      }

      Object.assign(settings, accountNumberSettings)
    }
  }

  submissionViewsSelection (elem, settings) {
    if (elem.name === 'submission_view[form_fields][]') {
      var submissionViewSettings = {
        controlClass: 'ts-control submission-field-ts',
        itemClass: 'item pl-1 resource-item text-md background-none',
        placeholder: 'Add New Field',
        closeAfterSelect: false,
        hideSelected: false,
        onDropdownOpen: function (dropdown) {
          window.app.submissionFieldSelection.disableSelections(dropdown)
        },
      }

      Object.assign(settings, submissionViewSettings)
    }
  }

  submissionViewPrimaryType (elem, settings) {
    if (elem.name === 'submission_view[primary_type]') {
      var submissionViewPrimary = {
        onInitialize: function () {
          this.dropdown.classList.add('ts-static')
        },
        itemClass: 'item pl-1 resource-item text-md background-none',
        dropdownParent: 'body',
        placeholder: 'Select Primary Type...',
        plugins: {},
      }

      Object.assign(settings, submissionViewPrimary)
    }
  }

  submissionSearch (elem, settings) {
    if (elem.id === 'submission-attachment-container') {
      var submissionValue = document.querySelector('[name="submission_id"]')

      if (submissionValue) {
        var submissionId = submissionValue.value
      }

      var resourceItemSettings = {
        optionClass: 'p-2',
        valueField: 'id',
        labelField: 'category',
        placeholder: 'Select Submissions...',
        // This corresponds to the serialized data found in SubmissionSerializer
        // if you want to add more fields for the search, you'll have to add them
        // to the serializer as well
        // - app/frontend/build-settings.js
        // - app/models/submission.rb
        // - app/serializers/submission_serializer.rb
        searchField: [
          'id',
          'account_number',
          'category',
          'manager_rep_code',
          'description',
          'created_at',
          'user_name',
          'legacy_manager_code',
          'legacy_account_number',
        ],
        load: function (query, callback) {
          var ids = []
          var queryString = ''
          var submissions = document.getElementsByClassName(
            'submission-selection-container',
          )

          Array.from(submissions).forEach(($submission) => { ids.push($submission.id) })

          if (submissionId) { ids.push(submissionId) }

          if (ids.length > 0) { queryString = encodeURIComponent(JSON.stringify(ids)) }

          var url = `/api/submissions?search=${query}&submissionId=${queryString}`

          fetch(url)
            .then((res) => res.json())
            .then((json) => {
              callback(json)
            })
            .catch(() => { callback() })
        },
        render: {
          option: function (item, escape) {
            return `
                <div class="flex items-center m-2 rounded group-hover">
                  <div class="flex items-center justify-center h-9 w-9 rounded-full bg-white text-gray-200 submission-svg-background">
                    <div class="text-whitelabel-400">
                      <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.25802 15.751C1.01594 15.7555 0.00562535 14.753 0 13.5121V7.87549C0 7.75511 0.0191262 7.63473 0.0562535 7.51997L2.13088 1.29833C2.40765 0.528783 3.1322 0.0112507 3.949 0H14.0521C14.8701 0.0112507 15.5935 0.528783 15.8702 1.29946L17.9437 7.5211C17.9809 7.6336 18 7.75511 18 7.87549V13.5121C17.9944 14.753 16.9841 15.7555 15.742 15.751H2.25802ZM15.7431 13.5008C15.751 13.5008 15.751 9.00056 15.751 9.00056H12.8899C12.0889 10.3923 10.606 11.2507 9.00056 11.2507C7.39509 11.2507 5.91224 10.3923 5.11119 9.00056H2.25014V13.5121C2.25014 13.5008 15.7431 13.5008 15.7431 13.5008ZM13.8159 2.25014H4.18526L2.68667 6.75042H5.81661C5.95387 6.75267 6.08663 6.77967 6.21376 6.82918C6.25202 6.84605 6.29027 6.86518 6.32627 6.88543C6.40728 6.92706 6.48378 6.97881 6.55128 7.03844C6.58504 7.06657 6.61879 7.09807 6.64692 7.1307C6.70879 7.20157 6.76167 7.27921 6.80443 7.36359C6.82468 7.39734 6.84268 7.43109 6.86068 7.46709C6.86068 7.47834 6.87418 7.48959 6.87868 7.50084C7.19595 8.4009 8.0465 9.00056 9.00056 9.00056C9.95462 9.00056 10.8052 8.4009 11.1224 7.50084C11.1224 7.48959 11.1359 7.47834 11.1416 7.46709C11.1584 7.43334 11.1753 7.39734 11.1978 7.36359C11.2395 7.27921 11.2923 7.20157 11.3553 7.1307C11.3835 7.09694 11.4161 7.06657 11.4498 7.03844C11.5173 6.97881 11.5938 6.92593 11.6749 6.88656L11.7874 6.8303C11.9134 6.7808 12.0461 6.7538 12.1834 6.75155H15.3145L13.8159 2.25014Z" fill="currentColor"/>
                      </svg>
                      <div id="submission-id-${
                        item.id
                      }" class="sr-only">${escape(item.submission_id)}</div>
                    </div>
                  </div>
                  <div class="ml-2">
                    <div>
                      ${escape(
                        item.description || item.category,
                      )} <span class="text-xs text-gray-400 italic">by ${escape(item.user_name)}</span>
                    </div>
                    <div>
                      <p class="text-xs">Submitted <b class="text-whitelabel-400">${escape(item.created_at)}</b> in <b class="text-whitelabel-400">${escape(item.category)}</b></p>
                    </div>
                  </div>
                </div>`
          },
        },
      }
    }

    Object.assign(settings, resourceItemSettings)
  }
}

export default BuildSettings
